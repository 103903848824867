<div class="container-fluid bg-white vh-100 p-0 position-relative">
  <ClientLogo />
  <BreadCrumb label="Facial Biometrics Verification" />

  <!-- Initial Form View -->
  <div class="d-flex justify-content-center align-items-center min-vh-50" v-if="!showCamera">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-1">
      <div class="d-flex align-items-center mb-5">
        <span><i class="text-muted bi bi-question-circle me-2"></i></span>
        <span class="text-muted text-xs">A liveness check helps ensure you're physically present during verification by analyzing
          facial movements or biometric data, preventing the use of photos or videos.
        </span> 
      </div> 
      <!-- Initial Form View -->
      <div v-if="!showInstructions">
        <div class="text-danger text-xss fw-bold d-flex justify-content-center min-vh-50" role="alert">
          <i class="fas fa-exclamation-circle me-2"></i>
          <small>Please make sure you have your camera enabled!</small>
        </div>
        
        <div class="form-group justify-content-center">
          <label for="id_number" class="form-label fw-bold text-xxs">
            ID Number <span class="text-danger fw-bold">*</span>
          </label>
          <input
            id="id_number"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm justify-content-center"
            v-model="id_number"
            placeholder="Enter an SA ID Number"
            @input="handle_input"
            maxlength="13"
          >
          <div class="d-flex flex-column col-12 col-md-9 col-lg-8 mx-auto mt-3 text-center">
            <small v-if="error_message" class="text-danger fw-bold text-xs">
              {{ error_message }}
            </small>
            <small class="text-danger fw-bold text-xs d-none id-message">
              Please fill in this field
            </small>
            <small class="text-danger fw-bold text-xs d-none invalid-message">
              Please enter a valid ID number
            </small>
          </div>
        </div>

        <div class="text-center mt-3">
          <button 
            class="btn bg-green shadow-sm rounded-small text-xs fw-bold shadow mt-2 mt-md-0"
            @click="id_check"
            :disabled="!id_number || !validate_id(id_number)"
          >
            Start Facial Recognition
          </button>
        </div>
      </div>

      <!-- Instructions Modal -->
      <div class="modal fade" id="show_correct_image_instruction" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-4">
            <p class="text-center text-xs fw-bold mb-1">Please read the below instructions carefully:</p>
            <div class="text-center">
              <hr class="bg-secondary reduced-opacity">
            </div>
            <div class="modal-body px-4">
              <div class="d-flex align-items-center text-xs mb-3">
                <i class="fa fa-glasses me-3 text-success"></i>
                <span>Remove any hats, sunglasses, or any form of eyewear.</span>
              </div>
              <div class="d-flex align-items-center text-xs mb-3">
                <i class="fa fa-user-circle me-3 text-success"></i>
                <span>Move your face closer to the camera, ensuring your neck is excluded from the circle.</span>
              </div>
              <div class="text-center mt-4">
                <p class="text-success text-xs fw-bold mb-2">
                  <i class="fa fa-info-circle me-2"></i>Follow the animation below for guidance:
                </p>
                <img src="@/assets/images/facial_guide_one.gif" alt="Face Guidance Animation" class="rounded" width="250">
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <button type="button" class="btn bg-green fw-bold rounded-small text-xxs" data-bs-dismiss="modal" @click="startFaceRecognition">
                Let's Go
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Instructions View -->
      <div v-if="showInstructions" class="instructions-overlay" @click.self="closeInstructions">
        <div class="instructions-content">
          <ClientLogo class="mb-5"/>
          <h6 class="mb-4 text-xss text-center">We need to verify your identity to secure your basic information.</h6>
          <h6 class="fw-bold text-center">Please read the below instructions carefully:</h6>
          <hr class="my-4">
          <ul class="instruction-list" style="text-align: left; padding-left: 20px;">
            <li class="d-flex align-items-center">
              <i class="fa fa-camera instruction-icon-color me-2"></i>
              <span>Ensure your camera is enabled</span>
            </li>
            <li class="d-flex align-items-center">
              <i class="fa fa-lightbulb instruction-icon-color me-2"></i>
              <span>Make sure you are in a well-lit area</span>
            </li>
            <li class="d-flex align-items-center">
              <i class="fa fa-glasses instruction-icon-color me-2"></i>
              <span>Remove any glasses, hats, or face coverings</span>
            </li>
            <li class="d-flex align-items-center">
              <i class="fa fa-user-circle instruction-icon-color me-2"></i>
              <span>Ensure you're the only person in frame</span>
            </li>
          </ul>
          <hr class="my-4">
          <div class="d-flex justify-content-center mt-4">  
            <button class="btn bg-green shadow-sm rounded-small" @click="startFaceRecognition">
              Take Photo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Root Container for Camera Interface -->
  <div v-if="showCamera" class="root-container">
    <div class="camera-verification-container">
      <div class="camera-container position-relative">
        <!-- Back Button -->
        <button class="back-button" @click="stop_camera">
          <i class="fas fa-arrow-left text-white"></i>
        </button>

        <!-- Camera Elements -->
        <video ref="video" class="camera-feed" autoplay playsinline></video>
        <canvas ref="canvas" class="camera-canvas"></canvas>

        <div class="mask-overlay"></div>

        <button v-if="needsAudioUnlock" 
          @click="unlockAudio" 
          class="btn btn-primary mb-3">
          Tap to Enable Audio Instructions
        </button>

        <!-- Face Overlay -->
        <div v-show="current_step <= STEPS.LOOK_RIGHT" class="face-overlay-left"></div>
        <div v-show="current_step <= STEPS.LOOK_RIGHT" class="face-overlay-right"></div>
        <div class="merged-oval" :class="{
          'show': current_step >= STEPS.MOVE_CLOSER,
          'completed': progress_percentage >= 100
        }"></div>

        <!-- Capture Animation -->
        <div v-if="showCaptureAnimation" class="capture-overlay">
          <div class="check-container">
            <div class="checkmark-circle">
              <svg width="40" height="40" viewBox="0 0 40 40">
                <path class="checkmark" fill="none" d="M10,20 L17,27 L30,13" />
              </svg>
            </div>
            <div class="success-verification-text">liveness verified</div>
          </div>
        </div>

        <!-- Speech Text -->
        <div v-if="!showInstructions" class="speech-text-container">
          <p class="speech-text">{{ spoken_message }}</p>
        </div>

        <!-- Camera Footer -->
        <div class="camera-footer">
          <div class="company-branding">
            <img :src="clientLogo" alt="Company Logo" class="company-logo"> 
            <span class="text-xxs">| Real Identity</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>