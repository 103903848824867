import { validate_country_code } from "@/utils/validations";
export default {
    name: 'AmlForm',
    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                date_of_birth: '',
                gender: '',
                country_code: ''
            }
        };
    },
    emits: ['service_status_complete'],
    methods: {
        handle_submit() {
            if (!this.form.first_name || !this.form.last_name || !this.form.date_of_birth || !this.form.gender || !this.form.country_code) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'All fields are required.',
                    confirmButtonText: 'OK'
                });
                return;
            }
            const date_regex = /^\d{4}-\d{2}-\d{2}$/;
            if (!date_regex.test(this.form.date_of_birth)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Invalid date format. Please use YYYY-MM-DD.',
                    confirmButtonText: 'OK'
                });
                return;
            }

            const string_fields = ['first_name', 'last_name', 'gender', 'country_code'];
            for (const field of string_fields) {
                if (typeof this.form[field] !== 'string' || !this.form[field].trim()) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `Invalid input for ${field.replace('_', ' ')}. Please enter a valid string.`,
                        confirmButtonText: 'OK'
                    });
                    return;
                }
            }

            if (!validate_country_code(this.form.country_code)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Invalid country code. Please enter valid country code (e.g., ZAF).',
                    confirmButtonText: 'OK'
                });
                return;
            }

            const TOKEN = localStorage.getItem('token');
            const ANTI_MONEY_LAUNDERING_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/anti_money_laundering/`;

            this.error = '';

            var date_yyyymmdd = this.form.date_of_birth.replace(/-/g, '');

            $.ajax({
                url: ANTI_MONEY_LAUNDERING_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    date_of_birth: date_yyyymmdd,
                    gender: this.form.gender,
                    country_code: this.form.country_code
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading(),
                    });
                },
                success: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        text: 'AML Check ran successfully',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    }).then(() => {
                        this.$emit('service_status_complete');
                    });
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';

                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
    }
};