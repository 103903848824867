import GetQuestions from '@/components/GetQuestions/GetQuestions.vue';
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {
    name: 'UserOtp',
    mixins: [MAIN_CONTROLLER],
    components: {
        GetQuestions,
    },

    data() {
        return {
            otp_digits: Array(5).fill(''),
            responses: [],
            follow_up_responses: [],
            client_admin_id: sessionStorage.getItem('client_admin_id'),
            is_loading: false,
            error: '',
            show_resend_message: false,
            resend_timeout: null
        }
    },

    watch: {
        otp_digits: {
            handler(new_val) {
                if (new_val.every(digit => digit !== '') && new_val.length === 5) {
                    this.submit_otp();
                }
            },
            deep: true
        }
    },

    computed: {
        otp() {
            return this.otp_digits.join('');
        },
    },

    mounted() {
        this.initialize_component();
    },

    beforeDestroy() {
        if (this.resend_timeout) {
            clearTimeout(this.resend_timeout);
        }
        sessionStorage.removeItem('otp_sent');
    },

    methods: {
        async initialize_component() {
            try {
                const client_id = sessionStorage.getItem('client_admin_id');
                if (client_id === "Customer") {
                    this.store_responses();
                }
                
                const otp_sent = sessionStorage.getItem('otp_sent');
                if (!otp_sent) {
                    await this.send_otp();
                    sessionStorage.setItem('otp_sent', 'true');
                }
            } catch (error) {
                this.error = 'Failed to initialize component';
            }
        },

        handle_otp_input(event, index) {
            const input = event.target.value;
            this.otp_digits[index] = input.replace(/\D/g, '');
            
            document.querySelectorAll('.otp-input').forEach(input => {
                input.classList.remove('error');
            });
            
            if (input && index < 4) {
                const next_input = document.getElementById(`otp-${index + 1}`);
                if (next_input) next_input.focus();
            }
        },

        handle_key_down(event, index) {
            if (event.key === 'Backspace' && !this.otp_digits[index] && index > 0) {
                const prev_input = document.getElementById(`otp-${index - 1}`);
                if (prev_input) prev_input.focus();
            }
        },

        handle_paste(event) {
            event.preventDefault();
            const pasted_text = event.clipboardData.getData('text');
            const numbers = pasted_text.replace(/\D/g, '').split('');
            
            numbers.forEach((num, index) => {
                if (index < 5) {
                    this.otp_digits[index] = num;
                }
            });
        },

        store_responses(dynamic_fields = [], follow_up_responses = []) {
            this.responses = dynamic_fields;
            this.follow_up_responses = follow_up_responses;
        },

        handle_otp_error() {
            try {
                const otp_inputs = document.querySelectorAll('.otp-input');
                otp_inputs.forEach(input => {
                    input.classList.add('error');
                });

                setTimeout(() => {
                    otp_inputs.forEach(input => {
                        input.classList.remove('error');
                    });
                    this.otp_digits = Array(5).fill('');
                    const first_input = document.getElementById('otp-0');
                    if (first_input) first_input.focus();
                }, 500);
            } catch (error) {
                this.error = 'Error processing OTP';
            }
        },

        send_otp() {
            const token = localStorage.getItem('token');
            if (!token) {
                this.error = 'Authentication token not found';
                return;
            }

            const otp_url = `${process.env.API_BASE_URL}/verify_api/system_management_api/send_otp_email_api/`;
            
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: otp_url,
                    method: 'GET',
                    headers: {
                        Authorization: `Token ${token}`
                    },
                    success: () => {
                        this.error = '';
                        this.$router.push('/user_otp');
                        resolve();
                    },
                    error: () => {
                        this.error = 'Failed to send OTP';
                        this.$router.push('/user_otp');
                        reject();
                    }
                });
            });
        },

        resend_otp() {
            if (this.is_loading) return;
            
            const token = localStorage.getItem('token');
            if (!token) {
                this.error = 'Authentication token not found';
                return;
            }

            const otp_url = `${process.env.API_BASE_URL}/verify_api/system_management_api/send_otp_email_api/`;
            
            $.ajax({
                url: otp_url,
                method: 'GET',
                headers: {
                    Authorization: `Token ${token}`
                },
                beforeSend: () => {
                    this.is_loading = true;
                    this.error = '';
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.close();
                    this.otp_digits = Array(5).fill('');
                    const first_input = document.getElementById('otp-0');
                    if (first_input) first_input.focus();
                    this.show_resend_message = true;
                    this.resend_timeout = setTimeout(() => {
                        this.show_resend_message = false;
                    }, 3000);
                    sessionStorage.setItem('otp_sent', 'true');
                    this.$router.push('/user_otp');
                },
                error: () => {
                    Swal.close();
                    this.error = 'Failed to resend OTP';
                    this.handle_otp_error();
                },
                complete: () => {
                    this.is_loading = false;
                }
            });
        },

        submit_otp() {
            if (this.is_loading) return;

            if (!this.otp_digits.every(digit => /^\d$/.test(digit))) {
                this.error = 'Please enter valid numeric digits';
                return;
            }

            const token = localStorage.getItem('token');
            if (!token) {
                this.error = 'Authentication token not found';
                return;
            }

            if (this.$refs.get_questions && typeof this.$refs.get_questions.validate_fields === 'function') {
                const is_responses_valid = this.$refs.get_questions.validate_fields();
                if (!is_responses_valid) return;
            }

            const otp_url = `${process.env.API_BASE_URL}/verify_api/system_management_api/save_otp_api/`;

            const form_data = {
                client_admin_id: this.client_admin_id,
                otp: this.otp,
                responses: this.process_response()
            };

            $.ajax({
                url: otp_url,
                method: 'POST',
                data: JSON.stringify(form_data),
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                beforeSend: () => {
                    this.is_loading = true;
                    this.error = '';
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.close();
                    localStorage.setItem('user_state', 'otp_verified');
                    localStorage.setItem('otp_entered', 'true');
                    this.check_redirect();
                },
                error: () => {
                    Swal.close();
                    this.error = 'Invalid OTP';
                    this.handle_otp_error();
                },
                complete: () => {
                    this.is_loading = false;
                }
            });
        },

        process_response() {
            let processed = [];
            this.responses.forEach(field => {
                processed.push({
                    service_id: field.service_id,
                    question_id: field.question_id,
                    value: field.value,
                    parent_question_id: null
                });

                const follow_ups = this.follow_up_responses.filter(
                    follow_up => follow_up.parent_id === field.question_id
                );

                processed = [...processed, ...follow_ups.map(follow_up => ({
                    service_id: field.service_id,
                    question_id: follow_up.question_id,
                    value: follow_up.value,
                    parent_question_id: field.question_id
                }))];
            });

            return processed;
        },

        check_redirect() {
            const token = localStorage.getItem('token');
            if (!token) {
                this.error = 'Authentication token not found';
                return;
            }

            const check_process_url = `${process.env.API_BASE_URL}/verify_api/system_management_api/user_redirect_api/`;

            $.ajax({
                url: check_process_url,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                success: (response) => {
                    this.handle_registration_redirect(response);
                },
                error: () => {
                    Swal.close();
                    this.error = 'Error checking redirect';
                    this.handle_otp_error();
                }
            });
        },

        handle_registration_redirect(response) {
            const role = response.role.toUpperCase();
            const status = response.status;

            switch (role) {
                case 'CUSTOMER':
                    this.handle_customer_redirect(response);
                    break;
                case 'CLIENTADMIN':
                    this.handle_client_admin_redirect(status);
                    break;
                case 'SYSTEMADMIN':
                    this.$router.push('system_admin/admin_dashboard');
                    break;
                case 'DIRECTOR':
                    this.handle_director_redirect(status);
                    break;
                case 'CLIENT':
                    this.$router.push('client/project_table');
                    break;
                default:
                    this.$router.push('/company_pending');
            }
        },

        handle_customer_redirect(response) {
            
            let workflow_id = response.workflow_id;
            if (sessionStorage.getItem('workflow_id')){
                workflow_id = sessionStorage.getItem('workflow_id');
            }

            if (!workflow_id) {
                return this.$router.push({ name: 'assigned_workflows' });
            }

            const token = localStorage.getItem('token');
            if (!token) {
                this.error = 'Authentication token not found';
                return;
            }

            const check_terms_url = `${process.env.API_BASE_URL}/verify_api/customer_management_api/check_terms_and_conditions_confirmation/${workflow_id}`;

            $.ajax({
                method: "GET",
                url: check_terms_url,
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                success: (response) => {
                    this.$store.dispatch('update_workflow_id', workflow_id);
                    if (response.status === 'Success' && response.is_confirmed) {
                        this.fetch_current_service();
                    } else {
                        this.$router.push('customer_consent');
                    }
                },
                error: () => {
                    this.error = 'Error checking terms and conditions';
                    this.handle_otp_error();
                }
            });
        },

        handle_client_admin_redirect(status) {
            const redirect_map = {
                'no_profile_info': '/facial_biometrics',
                'not_verified': '/facial_biometrics',
                'no_company_profile': '/company_verification',
                'failed_verification': '/company_pending',
                'pending': '/company_pending',
                'declined': '/company_pending',
                'approved': 'client/project_table'
            };

            this.$router.push(redirect_map[status] || 'client/project_table');
        },

        handle_director_redirect(status) {
            const redirect_map = {
                'no_consent': '/director_consent',
                'not_verified': '/facial_biometrics_verification',
                'no_proof_of_address': '/upload_proof_of_address'
            };

            this.$router.push(redirect_map[status] || '/thank_you_page');
        }
    }
};