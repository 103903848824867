<div class="overview-component">
    <div class="row">
        <!-- Service Information -->
        <div class="col-md-12">
            <div class="card rounded-3 mb-3">
                <div class="card-header bg-white">
                    <h6 class="text-sm pt-2">Basic Information</h6>
                </div>
                <div class="card-body">
                    <div class="mb-2">
                        <label class="form-label text-xs">Service Name</label>
                        <input type="text" class="form-control form-control-sm" v-model="client_data.name" readonly>
                    </div>
                    <div class="mb-2">
                        <label class="form-label text-xs">Base Cost per Run (R)</label>
                        <input type="number" class="form-control form-control-sm" v-model.number="client_data.base_cost" step="0.01" min="0" readonly>
                        <small class="text-xxs text-muted">The actual cost to provide this service</small>
                    </div>
                    <div class="mb-2">
                        <label class="form-label text-xs">Client Price per Run (R)</label>
                        <input type="number" class="form-control form-control-sm" v-model.number="client_data.price_per_run" step="0.01" min="0">
                        <small class="text-xxs text-muted">The price charged to the client</small>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <button class="btn btn-bg-green rounded-small text-xs" @click="save_changes">
                            <i class="fas fa-save me-1"></i> Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Volume Thresholds -->
        <div class="col-12 mt-3">
            <div class="card rounded-3 mb-3">
                <div class="card-header bg-white d-flex justify-content-between align-items-center">
                    <h6 class="text-sm pt-2">Volume Pricing Thresholds</h6>
                    <div class="d-flex">
                        <button class="btn btn-bg-info rounded-small text-xs mx-1" @click="add_threshold">
                            <i class="fas fa-plus me-1"></i> Add Threshold
                        </button>
                        <button class="btn btn-bg-green rounded-small text-xs" @click="save_thresholds">
                            <i class="fas fa-save me-1"></i> Save Thresholds
                        </button>
                    </div>
                </div>
                <transition-group name="list" tag="div" class="card-body p-0">
                    <div v-for="(threshold, index) in paginated_thresholds" :key="threshold.id" class="threshold-item border-bottom p-3">
                        <div class="row align-items-center">
                            <div class="col-md-2">
                                <label class="form-label text-xs mb-1">Volume Threshold</label>
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text icon-transparent">≥</span>
                                    <input type="number" class="form-control" v-model.number="threshold.threshold" min="1">
                                    <span class="input-group-text icon-transparent">runs</span>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label class="form-label text-xs mb-1">Type</label>
                                <select class="form-select form-select-sm" v-model="threshold.type">
                                    <option value="absolute">Absolute</option>
                                    <option value="percentage">Percentage</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label text-xs mb-1">
                                    <span v-if="threshold.type === 'absolute'">Client Price per Run (R)</span>
                                    <span v-else>Discount Percentage (%)</span>
                                </label>
                                <input type="number" class="form-control form-control-sm" v-if="threshold.type === 'absolute'" 
                                    v-model.number="threshold.discount" min="0" step="0.01">
                                <div class="input-group input-group-sm" v-else>
                                    <input type="number" class="form-control" v-model.number="threshold.discount" 
                                        min="0" max="100" step="0.01">
                                    <span class="input-group-text icon-transparent">%</span>
                                </div>
                            </div>
                            <div class="col-md-3" v-if="threshold.type === 'absolute'">
                                <label class="form-label text-xs mb-1">Calculated Discount</label>
                                <div class="input-group input-group-sm">
                                    <input type="text" class="form-control" :value="calculate_discount(threshold) + '%'" readonly>
                                    <span class="input-group-text icon-transparent" :class="get_discount_color_class(threshold)">
                                        <i class="fas fa-percentage"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label class="form-label text-xs mb-1 d-block">&nbsp;</label>
                                <button class="btn btn-sm rounded-3 shadow btn-outline-danger" 
                                    @click="remove_threshold(get_actual_threshold_index(index))">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </transition-group>

                <!-- Thresholds Pagination -->
                <div class="d-flex justify-content-between align-items-center mt-2 p-3" 
                    v-if="client_data.volume_thresholds && client_data.volume_thresholds.length > threshold_items_per_page">
                    <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_threshold_page" 
                        :disabled="threshold_current_page === 1">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                    <span class="text-xs my-2">Page {{ threshold_current_page }} of {{ threshold_total_pages }}</span>
                    <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_threshold_page" 
                        :disabled="threshold_current_page === threshold_total_pages">
                        <i class="fas fa-arrow-right"></i>
                    </button>
                </div>
            </div>

            <!-- Price Structure Preview -->
            <div class="card rounded-3 mb-3">
                <div class="card-header bg-white">
                    <h6 class="text-sm pt-2">Price Structure Preview</h6>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-sm text-xs">
                            <thead>
                                <tr>
                                    <th>Usage Level</th>
                                    <th>Price per Run</th>
                                    <th>Discount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!client_data.volume_thresholds || client_data.volume_thresholds.length === 0">
                                    <td>1 - ∞ runs</td>
                                    <td>R{{ client_data.price_per_run || 0 }}</td>
                                    <td>0%</td>
                                </tr>
                                <tr v-else>
                                    <td>1 - {{ get_first_threshold_volume - 1 || '∞' }} runs</td>
                                    <td>R{{ client_data.price_per_run || 0 }}</td>
                                    <td>0%</td>
                                </tr>
                                <tr v-for="(threshold, index) in paginated_sorted_thresholds" :key="threshold.id">
                                    <td>
                                        {{ threshold.threshold }} - 
                                        {{ get_next_threshold_volume(get_actual_preview_index(index)) !== null ? 
                                            get_next_threshold_volume(get_actual_preview_index(index)) - 1 : '∞' }} runs
                                    </td>
                                    <td v-if="threshold.type === 'absolute'">R{{ threshold.discount }}</td>
                                    <td v-else>R{{ calculate_discounted_price(threshold) }}</td>
                                    <td>{{ calculate_discount(threshold) }}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Preview Pagination -->
                    <div class="d-flex justify-content-between align-items-center mt-2" 
                        v-if="client_data.volume_thresholds && get_sorted_thresholds.length > preview_items_per_page">
                        <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_preview_page" 
                            :disabled="preview_current_page === 1">
                            <i class="fas fa-arrow-left"></i>
                        </button>
                        <span class="text-xs my-2">Page {{ preview_current_page }} of {{ preview_total_pages }}</span>
                        <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_preview_page" 
                            :disabled="preview_current_page === preview_total_pages">
                            <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>