<div class="address-form">
    <form @submit.prevent="submit_id">
        <div class="form-group">
            <label for="id_number" class="fw-bold text-xs">ID Number</label>
            <input type="text" v-model="form.id_number" class="form-control text-xs rounded-small p-3 shadow-sm" id="id_number" @input="allow_num" required>
        </div>
        <div class="d-flex justify-content-end my-2">
            <button type="submit" class="btn btn-primary-color rounded-pill text-xs">
                Run Service
            </button>
        </div>
    </form>
</div>