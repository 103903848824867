import Swal from 'sweetalert2';

export default {
    name: 'OverviewComponent',
    props: {
        client_id: {
            type: String,
            required: true
        },
        service_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            client_data: {
                name: '',
                base_cost: 0,
                price_per_run: 0,
                volume_thresholds: []
            },
            // Pagination
            threshold_current_page: 1,
            threshold_items_per_page: 3,
            preview_current_page: 1,
            preview_items_per_page: 3
        }
    },
    computed: {
        paginated_thresholds() {
            const start = (this.threshold_current_page - 1) * this.threshold_items_per_page;
            const end = start + this.threshold_items_per_page;
            return this.client_data.volume_thresholds.slice(start, end);
        },
        threshold_total_pages() {
            return Math.ceil(this.client_data.volume_thresholds.length / this.threshold_items_per_page);
        },
        get_sorted_thresholds() {
            return [...this.client_data.volume_thresholds].sort((a, b) => a.threshold - b.threshold);
        },
        paginated_sorted_thresholds() {
            const start = (this.preview_current_page - 1) * this.preview_items_per_page;
            const end = start + this.preview_items_per_page;
            return this.get_sorted_thresholds.slice(start, end);
        },
        preview_total_pages() {
            return Math.ceil(this.get_sorted_thresholds.length / this.preview_items_per_page);
        },
        get_first_threshold_volume() {
            return this.client_data.volume_thresholds.length > 0 ? 
                Math.min(...this.client_data.volume_thresholds.map(t => t.threshold)) : 1;
        }
    },
    emits: ['update:client_name'],
    methods: {
        async fetch_client_overview() {
            const TOKEN = localStorage.getItem('token');
            const GET_CLIENT_SERVICE_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/service_management/client_service/?client_id=${this.client_id}&service_id=${this.service_id}`;

            $.ajax({
                url: GET_CLIENT_SERVICE_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    var client_service_data = response.data
                    this.$emit('update:client_name', `${client_service_data.client.name || 'Unkown'} (${client_service_data.client.email})`);
                    this.client_data = {
                        name: client_service_data.service.name || "",
                        base_cost: client_service_data.base_cost || 0,
                        price_per_run: client_service_data.price_per_client.price || 0,
                        volume_thresholds: Array.isArray(client_service_data.volumes) ? client_service_data.volumes.map(
                            (volume) => {
                                return {
                                    id: volume.id,
                                    threshold: volume.volume_threshold,
                                    discount: volume.discount_value,
                                    type: volume.pricing_type
                                }
                            }
                        ) : []
                    }

                    if (client_service_data.volumes.length === 0) {
                        this.add_threshold();
                    }
                },
                error: () => {
                    Swal.close();
                    let error_message = 'Failed to get client service information';

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },

        async save_changes() {
            if (this.client_data.price_per_run <= 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Invalid Price',
                    text: 'Please enter a valid price per run',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                });
                return;
            }

            const TOKEN = localStorage.getItem('token');
            const url = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/client_prices/create/`;
            const postData = {
                service_id: this.service_id,
                client_id: this.client_id,
                price: this.client_data.price_per_run
            };

            $.ajax({
                url: url,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify(postData),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Saving changes...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Changes saved successfully',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    });
                },
                error: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to save changes',
                    });
                }
            });
        },

        async save_thresholds() {
            if (!this.client_data.volume_thresholds.length) {
                Swal.fire({
                    icon: 'warning',
                    title: 'No Thresholds',
                    text: 'Please add at least one volume threshold',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                });
                return;
            }

            try {
                const TOKEN = localStorage.getItem('token');
                const url = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/client_volume_prices/recreate_volume_prices/`;
                const post_data = {
                    volumes: this.client_data.volume_thresholds.map(
                        (threshold) => {
                            return {
                                id: threshold.id,
                                volume_threshold: threshold.threshold,
                                discount_value: threshold.discount,
                                pricing_type: threshold.type
                            }
                        }
                    ),
                    service_id: this.service_id,
                    client_id: this.client_id
                };
                $.ajax({
                    url: url,
                    method: 'POST',
                    contentType: 'application/json',
                    headers: {
                        Authorization: `Token ${TOKEN}`
                    },
                    data: JSON.stringify(post_data),
                    beforeSend: () => {
                        Swal.fire({
                            text: 'Saving thresholds...',
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            }
                        });
                    },
                    success: () => {
                        Swal.close();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Thresholds saved successfully',
                            timer: 3000,
                            timerProgressBar: true,
                            showConfirmButton: false
                        });
                    },
                    error: () => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Failed to save thresholds',
                        });
                    }
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message,
                });
            }
        },

        add_threshold() {
            this.client_data.volume_thresholds.push({
                id: '',
                threshold: 1,
                discount: 0,
                type: 'absolute'
            });
        },
        remove_threshold(index) {
            this.client_data.volume_thresholds.splice(index, 1);
            if (this.paginated_thresholds.length === 0 && this.threshold_current_page > 1) {
                this.threshold_current_page--;
            }
        },
        calculate_discount(threshold) {
            if (threshold.type === 'percentage') {
                return threshold.discount.toFixed(2);
            }
            const base_price = this.client_data.price_per_run;
            return ((base_price - threshold.discount) / base_price * 100).toFixed(2);
        },
        calculate_discounted_price(threshold) {
            if (threshold.type === 'absolute') return threshold.discount;
            const base_price = this.client_data.price_per_run;
            return (base_price * (1 - threshold.discount / 100)).toFixed(2);
        },
        get_discount_color_class(threshold) {
            const discount = this.calculate_discount(threshold);
            if (discount > 50) return 'bg-success';
            if (discount > 20) return 'bg-warning';
            return 'bg-danger';
        },
        get_next_threshold_volume(index) {
            const sorted = this.get_sorted_thresholds;
            return index < sorted.length - 1 ? sorted[index + 1].threshold : null;
        },
        get_actual_threshold_index(displayed_index) {
            return (this.threshold_current_page - 1) * this.threshold_items_per_page + displayed_index;
        },
        get_actual_preview_index(displayed_index) {
            return (this.preview_current_page - 1) * this.preview_items_per_page + displayed_index;
        },
        // Pagination methods
        previous_threshold_page() {
            if (this.threshold_current_page > 1) this.threshold_current_page--;
        },
        next_threshold_page() {
            if (this.threshold_current_page < this.threshold_total_pages) this.threshold_current_page++;
        },
        previous_preview_page() {
            if (this.preview_current_page > 1) this.preview_current_page--;
        },
        next_preview_page() {
            if (this.preview_current_page < this.preview_total_pages) this.preview_current_page++;
        }
    },
    mounted() {
        this.fetch_client_overview();
    }
}