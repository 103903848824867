import IconCard from '@/components/IconCard.vue';

export default {
    name: 'ClientServicesManagement',
    components: {
        IconCard
    },
    data() {
        return {
            revenue_this_month: 'R 0.00',
            cost_this_month: 'R 0.00',
            total_services_ran: 0,
            workflow_count: 0,
            asset_count: 0,
            applications_completed: 0,
            applications_incompleted: 0,
            customer_count: 0,
            services: [],
            services_search_query: "",
            current_page: 1,
            items_per_page: 10
        }
    },
    computed: {
        client_id() {
            return this.$route.params.client_id;
        },
        filtered_services() {
            if (!this.services_search_query) {
                return this.services;
            }
            const query = this.services_search_query.toLowerCase();
            return this.services.filter(service => {
                return service.service_name.toLowerCase().includes(query) ||
                    service.service_cost.toString().includes(query) ||
                    service.client_price.toString().includes(query) ||
                    service.total_runs.toString().includes(query);
            });
        },
        paginated_services() {
            const start = (this.current_page - 1) * this.items_per_page;
            const end = start + this.items_per_page;
            return this.filtered_services.slice(start, end);
        },
        total_pages() {
            return Math.ceil(this.filtered_services.length / this.items_per_page);
        }
    },
    methods: {
        view_service(service_id) {
            this.$router.replace(`/system_admin/client_service_management/${this.client_id}/${service_id}/`);
        },
        next_page() {
            if (this.current_page < this.total_pages) {
                this.current_page++;
            }
        },
        previous_page() {
            if (this.current_page > 1) {
                this.current_page--;
            }
        },
        assign_suffix_to_amount(amount){
            if (amount >= 1000000000) {
                return `${Number(amount / 1000000000).toFixed(2)}B`;
            } else if (amount >= 1000000) {
                return `${Number(amount / 1000000).toFixed(2)}M`;
            } else if (amount >= 1000) {
                return `${Number(amount / 1000).toFixed(2)}k`;
            } else {
                return Number(amount).toFixed(2);
            }
        },
        fetch_general_information() {
            const TOKEN = localStorage.getItem('token');
            const GET_GENERAL_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/service_management/general_information/?client_id=${this.client_id}`;

            $.ajax({
                url: GET_GENERAL_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    var general_data = response.data
                    
                    this.workflow_count = general_data.total_workflows || 0;
                    this.asset_count = general_data.total_assets || 0;
                    this.applications_completed = general_data.applications_completed || 0;
                    this.applications_incompleted = general_data.applications_in_progress || 0;
                    this.customer_count = general_data.total_customers || 0;
                    this.revenue_this_month = `R ${this.assign_suffix_to_amount(general_data.revenue_this_month || 0)}`;
                    this.cost_this_month = `R ${this.assign_suffix_to_amount(general_data.costs_this_month || 0)}`;
                    this.total_services_ran = general_data.total_services_ran || 0;

                },
                error: () => {
                    Swal.close();
                    let error_message = 'Failed to get service information';

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
        fetch_client_services() {
            const TOKEN = localStorage.getItem('token');
            const URL = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/service_management/client_service_information/?client_id=${this.client_id}`;

            $.ajax({
                url: URL,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading services...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    this.services = Array.isArray(response.data) ? response.data : [];
                },
                error: () => {
                    Swal.close();
                }
            });
        }
    },
    mounted() {
        this.fetch_general_information();
        this.fetch_client_services();
    }
}