<div class="service-util">
    <div class="container-fluid mt-3">
        <div class="row g-4">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <icon-card title="Revenue Generation This Month" :count="revenue_this_month" icon_class="fas fa-chart-line" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <icon-card title="Service Cost This Month" :count="cost_this_month" icon_class="fas fa-money-bill-wave" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Service Ran" :count="total_service_ran" icon_class="fas fa-running" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Clients" :count="client_count" icon_class="fas fa-users" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Workflows" :count="workflow_count" icon_class="fas fa-project-diagram" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Assets" :count="asset_count" icon_class="fas fa-boxes" />
            </div>
            <div class="col-sm-12">
                <div class="card shadow-sm rounded-3">
                    <div class="card-body">
                        <div class="title-info p-2">
                            <label class="d-flex align-items-center mb-0">
                                <i class="fas fa-cogs text-primary fa-xl me-2"></i>
                                <span class="fw-bold mr-1">{{ service_name }}</span>
                            </label>
                        </div>

                        <!-- Navigation Tabs -->
                        <ul class="nav nav-tabs my-2" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active text-xs text-dark" id="pills-overview-tab" data-bs-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="true">Overview</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link text-xs text-dark" id="pills-usage-tab" data-bs-toggle="pill" href="#pills-usage" role="tab" aria-controls="pills-usage" aria-selected="false">Usage</a>
                            </li>
                        </ul>
                        <!-- Navigation Tabs End -->
                        <!-- Tabs Content -->
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab">
                                <overview-component @update:service_name="service_name = $event" :service_id="service_id" />
                            </div>
                            <div class="tab-pane fade" id="pills-usage" role="tabpanel" aria-labelledby="pills-usage-tab">
                                <usage-component :service_id="service_id" />
                            </div>
                        </div>
                        <!-- Tabs Content End-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>