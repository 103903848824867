import { validate_invalid_characters } from "@/utils/validations";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import Swal from 'sweetalert2';

var absa = await import('@/assets/images/banks/absa.svg');
var standardbank = await import('@/assets/images/banks/standardbank.svg');
var fnb = await import('@/assets/images/banks/fnb.svg');
var nedbank = await import('@/assets/images/banks/nedbank.svg');
var capitec = await import('@/assets/images/banks/capitec.svg');
var discovery = await import('@/assets/images/banks/discovery.svg');
var tymebank = await import('@/assets/images/banks/tymebank.svg');

export default {
    name: "ManualBankStatement",
    mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
    components: {
        BreadCrumb,
        ClientLogo
    },
    data: () => ({
        first_name: '',
        last_name: '',
        id_number: '',
        email: '',
        selected_bank: null,
        selected_files: [],
        selected_file: null,
        is_dragging: false,
        error: '',
        error_messages: {
            first_name: '',
            last_name: '',
            id_number: '',
            email: '',
            bank: '',
            file: ''
        },
        is_field_disabled: {
            first_name: false,
            last_name: false,
            id_number: false,
            email: false
        },
        is_loading: true,
        current_step: 'personal_info',
        banks: [
            { id: 1, name: 'ABSA', logo: absa.default, value: 'Absa' },
            { id: 2, name: 'Standard Bank', logo: standardbank.default, value: 'Standard Bank' },
            { id: 3, name: 'FNB', logo: fnb.default, value: 'FNB' },
            { id: 4, name: 'Nedbank', logo: nedbank.default, value: 'Nedbank' },
            { id: 5, name: 'Capitec', logo: capitec.default, value: 'Capitec' },
            { id: 6, name: 'Discovery Bank', logo: discovery.default, value: 'Discovery' },
            { id: 7, name: 'TymeBank', logo: tymebank.default, value: 'Tyme Bank' },
        ],
        customer_id: null,
        bank_account_id: null,
        max_files: 6,
    }),

    computed: {
        workflow_id() {
            return this.$store.getters.get_workflow_id;
        },
        all_fields_filled() {
            return this.first_name && this.last_name && this.id_number && this.email;
        }
    },

    methods: {
        validate_first_name() {
            this.first_name = validate_invalid_characters(this.first_name);
            this.error_messages.first_name = this.first_name ? '' : 'This field is required';
        },

        validate_last_name() {
            this.last_name = validate_invalid_characters(this.last_name);
            this.error_messages.last_name = this.last_name ? '' : 'This field is required';
        },

        validate_id_number() {
            this.id_number = this.id_number.replace(/[^0-9]/g, '');
            this.error_messages.id_number = this.id_number ? '' : 'This field is required';
        },

        validate_email() {
            const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!this.email) {
                this.error_messages.email = 'This field is required';
            } else if (!email_regex.test(this.email)) {
                this.error_messages.email = 'Please enter a valid email address';
            } else {
                this.error_messages.email = '';
            }
        },

        validate_all_fields() {
            let valid = true;
            let message = 'This field is required';

            const fields = ['first_name', 'last_name', 'id_number', 'email'];

            fields.forEach((field) => {
                if (!this[field]) {
                    this.error_messages[field] = message;
                    valid = false;
                } else {
                    this.error_messages[field] = '';
                }
            });

            if (this.email && !this.error_messages.email) {
                this.validate_email();
                if (this.error_messages.email) {
                    valid = false;
                }
            }

            return valid;
        },

        select_bank(bank_name) {
            this.selected_bank = bank_name;
        },

        get_bank_logo(bank_name) {
            const bank = this.banks.find(b => b.value === bank_name);
            return bank ? bank.logo : '';
        },

        submit_bank_selection() {
            if (!this.selected_bank) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Selection Required',
                    text: 'Please select a bank to continue',
                    timer: 3000,
                    timerProgressBar: true
                });
                return;
            }

            this.is_loading = true;

            const token = localStorage.getItem('token');
            const bank_account_api = `${process.env.API_BASE_URL}/verify_api/compliance_verification/create_bank_account/`;

            $.ajax({
                url: bank_account_api,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                data: JSON.stringify({
                    customer_id: this.customer_id,
                    bank: this.selected_bank,
                    bank_type: 'statement',
                    workflow_id: this.workflow_id
                }),
                success: (response) => {
                    this.bank_account_id = response.bank_account_id;
                    this.current_step = 'file_upload';
                    this.is_loading = false;
                },
                error: () => {
                    this.is_loading = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Error creating bank account',
                        timer: 3000,
                        timerProgressBar: true
                    });
                }
            });
        },

        validate_upload_fields() {
            let valid = true;

            if (this.selected_files.length === 0) {
                this.error_messages.file = 'Please upload at least one bank statement file';
                valid = false;
            } else {
                for (const file of this.selected_files) {
                    if (file.size > 10 * 1024 * 1024) {
                        this.error_messages.file = 'File size exceeds the maximum limit of 10MB';
                        valid = false;
                        break;
                    }
                }
                if (valid) {
                    this.error_messages.file = '';
                }
            }

            return valid;
        },

        handle_file_change(event) {
            if (event.target.files && event.target.files.length > 0) {
                const new_files = Array.from(event.target.files);

                if (this.selected_files.length + new_files.length > this.max_files) {
                    this.error_messages.file = `You can upload a maximum of ${this.max_files} files`;
                    return;
                }

                const valid_types = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
                const invalid_files = new_files.filter(file => !valid_types.includes(file.type));

                if (invalid_files.length > 0) {
                    this.error_messages.file = 'Some files have invalid types. Please upload only PDF, JPG, or PNG files.';
                    return;
                }

                const oversized_files = new_files.filter(file => file.size > 10 * 1024 * 1024);
                if (oversized_files.length > 0) {
                    this.error_messages.file = 'Some files exceed the maximum size limit of 10MB';
                    return;
                }

                this.selected_files = [...this.selected_files, ...new_files];
                this.selected_file = this.selected_files[0] || null;
                this.error_messages.file = '';
            }
        },

        handle_file_drop(event) {
            this.is_dragging = false;
            const files = event.dataTransfer.files;

            if (files && files.length > 0) {
                const new_files = Array.from(files);

                if (this.selected_files.length + new_files.length > this.max_files) {
                    this.error_messages.file = `You can upload a maximum of ${this.max_files} files`;
                    return;
                }

                const valid_types = ['application/pdf'];
                const valid_files = new_files.filter(file => valid_types.includes(file.type));

                if (valid_files.length === 0) {
                    this.error_messages.file = 'Invalid file type(s). Please upload PDF, JPG, or PNG files.';
                    return;
                }

                if (valid_files.length !== new_files.length) {
                    this.error_messages.file = 'Some files were skipped due to invalid file types.';
                }

                const valid_sized_files = valid_files.filter(file => file.size <= 10 * 1024 * 1024);

                if (valid_sized_files.length === 0) {
                    this.error_messages.file = 'All files exceed the maximum size limit of 10MB';
                    return;
                }

                if (valid_sized_files.length !== valid_files.length) {
                    this.error_messages.file = 'Some files were skipped because they exceed the 10MB size limit.';
                }

                this.selected_files = [...this.selected_files, ...valid_sized_files];
                this.selected_file = this.selected_files[0] || null;

                if (this.selected_files.length > 0) {
                    this.error_messages.file = '';
                }
            }
        },

        remove_file(index) {
            this.selected_files.splice(index, 1);
            this.selected_file = this.selected_files[0] || null;
        },

        format_file_size(bytes) {
            if (bytes < 1024) {
                return bytes + ' bytes';
            } else if (bytes < 1024 * 1024) {
                return (bytes / 1024).toFixed(2) + ' KB';
            } else {
                return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
            }
        },

        async get_customer_data() {
            this.is_loading = true;

            const token = localStorage.getItem('token');
            const customer_data_url = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_customer_data/`;

            try {
                const response = await $.ajax({
                    url: customer_data_url,
                    method: 'GET',
                    contentType: 'application/json',
                    headers: {
                        Authorization: `Token ${token}`,
                    }
                });

                this.first_name = response.first_name || '';
                this.last_name = response.last_name || '';
                this.id_number = response.id_number || '';
                this.email = response.email || '';

                this.is_field_disabled = {
                    first_name: !!response.first_name,
                    last_name: !!response.last_name,
                    id_number: !!response.id_number,
                    email: !!response.email
                };

                if (this.all_fields_filled && this.validate_all_fields()) {
                    this.submit_bank_statement_request();
                }
                else {
                    this.is_loading = false;
                }

            } catch (error) {
                this.is_loading = false;
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to load customer data',
                    timer: 3000,
                    timerProgressBar: true
                });
            }
        },

        submit_bank_statement_request() {
            if (!this.validate_all_fields()) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Validation Error',
                    text: 'Please fill in all required fields correctly',
                    timer: 3000,
                    timerProgressBar: true
                });
                return;
            }

            this.is_loading = true;

            const token = localStorage.getItem('token');
            const bank_statement_api = `${process.env.API_BASE_URL}/verify_api/compliance_verification/create_customer_profile/`;

            this.error = '';

            $.ajax({
                url: bank_statement_api,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                data: JSON.stringify({
                    first_name: this.first_name,
                    last_name: this.last_name,
                    id_number: this.id_number,
                    email: this.email,
                    workflow_id: this.workflow_id
                }),
                success: (response) => {
                    if (response.result) {
                        this.customer_id = response.customer_id;
                        this.current_step = 'bank_selection';
                        this.is_loading = false;
                    }
                },
                error: (error) => {
                    this.is_loading = false;
                    let error_message = 'Something went wrong!';
                    if (error.responseJSON) {
                        error_message = error.responseJSON;
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error_message,
                            timer: 3000,
                            timerProgressBar: true
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error_message,
                            timer: 3000,
                            timerProgressBar: true
                        });
                    }
                }
            });
        },

        upload_bank_statement() {
            if (!this.validate_upload_fields()) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Validation Error',
                    text: 'Please select valid bank statement files',
                    timer: 3000,
                    timerProgressBar: true
                });
                return;
            }

            this.is_loading = true;

            const token = localStorage.getItem('token');
            const upload_api = `${process.env.API_BASE_URL}/verify_api/compliance_verification/upload_bank_statement/`;

            const form_data = new FormData();
            for (const file of this.selected_files) {
                form_data.append('files', file);
            }
            form_data.append('bank_account_id', this.bank_account_id);
            form_data.append('workflow_id', this.workflow_id);

            $.ajax({
                url: upload_api,
                method: 'POST',
                contentType: false,
                processData: false,
                headers: {
                    Authorization: `Token ${token}`
                },
                data: form_data,
                success: (response) => {
                    this.is_loading = false;

                    if (response.result) {
                        this.current_step = 'success';
                        
                        setTimeout(() => {
                            this.fetch_current_service();
                        }, 3000);
                        
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.message || 'Failed to upload bank statement',
                            timer: 3000,
                            timerProgressBar: true
                        });
                    }
                },
                error: (error) => {
                    this.is_loading = false;
                    let error_message = 'Failed to upload bank statement';

                    if (error.responseJSON) {
                        error_message = error.responseJSON.message || error_message;
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        timer: 3000,
                        timerProgressBar: true
                    });
                }
            });
        }
    },

    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_customer_data();
    }
};