<h5 class="text-s text-center"><strong>RSA ID Verification</strong></h5>
<div class="row mt-2 mb-3">
    <div class="col-12 text-start">
        <label class="form-label text-xs fw-bold">ID Number <span class="text-danger fw-bold">*</span></label>
        <input v-model="id_number" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True"
            placeholder="eg. 0111230000082">
        <small class="text-danger fw-bold text-xs d-none id-message">
            Please fill in this field
        </small>
        <small class="text-danger fw-bold text-xs d-none invalid-message">
            Please enter a valid ID number
        </small>
    </div>
</div>
<div class="row mt-2 mb-2">
    <div class="col-6 text-start fw-bold">
        <label class="form-label text-xs">First Names <span class="text-danger fw-bold">*</span></label>
        <input v-model="first_names" type="text" class="form-control text-xs rounded-small p-3 shadow-sm"
            required="True" placeholder="eg. Mary Jane">
        <small class="text-danger fw-bold text-xs d-none first-name-message">
            Please enter a valid first name(s)
        </small>
    </div>
    <div class="col-6 text-start fw-bold">
        <label class="form-label text-xs">Last Name <span class="text-danger fw-bold">*</span></label>
        <input v-model="last_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm"
            placeholder="eg. Doe">
        <small class="text-danger fw-bold text-xs d-none last-name-message">
            Please enter a valid last name
        </small>
    </div>
</div>
<div class="col text-end">
    <button type="submit" class="btn bg-green btn-sm text-xs shadow rounded-small mt-2 fw-bold"
        @click.prevent="submit_form ">Submit</button>
</div>

<div class="modal" tabindex="-1" id="verification_results">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title fw-bold">Verification Result</h5>
            </div>
            <div class="modal-body">
                <div v-if="Object.keys(json_data).length > 0">
                    <!-- Data Table -->
                    <div class="table-responsive">
                        <table class="data-table table">
                            <tbody>
                                <template v-for="(item, index) in preprocess_data(json_data)" :key="index">
                                    <!-- Heading Row -->
                                    <tr v-if="item.type === 'heading'" class="table-secondary">
                                        <th class="py-2 text-s">
                                            {{ format_label(item.label) }}
                                        </th>
                                    </tr>
                                    <!-- Data Row -->
                                    <tr
                                        v-if="(item.type === 'input' || item.type === 'label') && !item.label.includes('FacialImage')">
                                        <td>
                                            <div class="row col-12">
                                                <div class="col-3">
                                                    <span class="fw-bold text-xs me-2">{{
                                                        format_label(item.label)
                                                        }}</span>
                                                </div>
                                                <div class="col-9">
                                                    <span v-if="item.value" class="text-xs">{{ item.value }}</span>
                                                    <span v-else class="text-muted text-xs">None</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else>
                    <ul>
                        <pre>{{ json_data }}</pre>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <router-link :to="{ name: 'services' }">
                    <button class="btn bg-green btn-sm text-white text-xs text-end  rounded-small mt-3"
                        data-bs-dismiss="modal" @click="reload_page">Back</button>
                </router-link>
            </div>
        </div>
    </div>
</div>