import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import { validate_first_name, validate_last_name, validate_email, validate_phone_number, validate_invalid_characters} from '@/utils/validations.js';
import {show_error} from '@/utils/validations.js';
import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";

export default {
  name: 'ClientAdminUsers',
  mixins: [LOGOUT],
  components: {
    ClientSidebar,
    NavBar
  },
  data: () => ({
    currentTab: localStorage.getItem('client_users_tab') || 'ClientAdmins', 
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    role: '',
    client_admins: [],
    clients: [],
    customers:[]

  }),
  methods: {
    switchTab(tab) {
      // Destroy the existing DataTable before switching tabs
      if ($.fn.dataTable.isDataTable('#users_table')) {
        $('#users_table').DataTable().destroy();
      }

      // Update the current tab and reinitialize the DataTable after the content is rendered
      this.currentTab = tab;
      localStorage.setItem('client_users_tab', tab); 
      this.$nextTick(() => {
        this.initDataTable(); // Reinitialize DataTable after rendering
      });
    },
    initDataTable() {
      // Initialize DataTable on the #usersTable element
      $('#users_table').DataTable({
        paging: true,
        searching: true,
        ordering: true,
        // You can add more configurations here based on your needs
      });
    },
    validate_inputs() {
      if (!validate_first_name(this.first_name)) return false;
      if (!validate_last_name(this.last_name)) return false;
      if (!validate_email(this.email)) return false;
      if (!validate_phone_number(this.phone_number)) return false;
      
      return true;
    },
    validate_phone_number() {
      this.phone_number = this.phone_number.replace(/\D/g, '');
    },
    validate_name(field_name) {
      this[field_name] = validate_invalid_characters(this[field_name]);
    },
    add_user() {

      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      if (!BASE_URL) {
        show_error('.error-message', 'Server configuration error. Please try again later.');
        return;
      }
      const ADD_USER_URL = `${BASE_URL}/verify_api/system_management_api/add_user_api/`;
      const FORM_DATA = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone_number: this.phone_number,
        role: this.role
      };
      $.ajax({
        method: "POST",
        url: ADD_USER_URL,
        data: JSON.stringify(FORM_DATA),
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.fire({
            icon: 'success',
            text: response.status,
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false
          }).then(() => {
            window.location.reload();
          });
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON.error,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    get_users() {

        const TOKEN = localStorage.getItem('token');
        const BASE_URL = process.env.API_BASE_URL;
        if (!BASE_URL) {
          show_error('.error-message', 'Server configuration error. Please try again later.');
          return;
        }
        const GET_USERS_URL = `${BASE_URL}/verify_api/workflow_management_api/get_client_admin_users/`;
  
        $.ajax({
          method: "GET",
          url: GET_USERS_URL,
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Loading users...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            if (response.redirect){
            
              this.logout();
              return;
  
            }
            this.client_admins = response.data.ClientAdmin;
            this.clients = response.data.Client;
            this.customers = response.customers;
            this.$nextTick(() => {
              this.initDataTable(); // Initialize DataTable after data is loaded
            });
            Swal.close();
          },
          error: () => {
            Swal.fire({
              icon: 'error',
              text: 'Failed to load users. Please try again.',
              confirmButtonText: 'OK'
            });
          }
        });
      
    },
    view_customer_data(customer_id){
      this.$router.push({
        name: 'customer_data', 
        params: { id: customer_id, workflow_id:0 }
       });
      },

    view_client_admin(user_id) {
      this.$router.push({ 
        name: 'view_client_admin',
        params: { user_id: user_id }
      });
    },
  
    view_client(user_id) {
      this.$router.push({ 
        name: 'view_client',
        params: { user_id: user_id }
      });
    }
  },
  mounted() {
    if (!validateToken(this.$router)) {
        return;
    }
    this.get_users();
  }
};
