<div class="container-fluid bg-white vh-100 p-0">
    <!-- Client Logo Component -->
    <ClientLogo />

    <!-- Breadcrumb Component -->
    <BreadCrumb label="Bank Statement Complete" />

    <!-- Success Message Container -->
    <div class="d-flex justify-content-center align-items-center min-vh-50">
        <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
            <!-- Error Message Display -->
            <div v-if="error" class="alert alert-danger text-center">
                {{ error }}
            </div>

            <!-- Loading Spinner -->
            <div v-if="is_loading" class="bank-spinner-container my-5">
                <div class="bank-spinner"></div>
                <div class="bank-loader">
                    <p>processing</p>
                    <div class="bank-words">
                        <span class="bank-word">statements</span>
                        <span class="bank-word">accounts</span>
                        <span class="bank-word">transactions</span>
                        <span class="bank-word">verification</span>
                    </div>
                </div>
            </div>

            <!-- Success Content -->
            <div v-if="!is_loading" class="bank-success-container text-center">
                <div class="bank-success-icon mb-4">
                    <i class="fas fa-check-circle"></i>
                </div>
                <h5 class="bank-success-title mb-3">Bank Statements Collected Successfully</h5>
                <p class="bank-success-description mb-4 text-xs">
                    Your bank statements have been received and are being processed. 
                    <br>
                    You will be redirected to the next step in a moment.
                </p>
            </div>
        </div>
    </div>
</div>