import SearchSelect from '../search_select/SearchSelect.vue';

export default {
    name: 'QualificationForm',
    components: {
        SearchSelect
    },
    data() {
        return {
            form: {
                first_names: '',
                surname: '',
                id_number: '',
                qualification: '',
                institution_name: '',
                selected_year: ''
            },
            error: '',
            json_data: {},
            years: Array.from({ length: 75 }, (_, i) => new Date().getFullYear() - i)
        };
    },
    emits: ['service_status_complete'],
    methods: {
        handle_submit() {
            if (!this.form.first_names) {
                this.error = 'First names are required';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: this.error,
                    confirmButtonText: 'OK'
                });
                return;
            }
            if (!this.form.surname) {
                this.error = 'Surname is required';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: this.error,
                    confirmButtonText: 'OK'
                });
                return;
            }
            if (!this.form.id_number) {
                this.error = 'ID number is required';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: this.error,
                    confirmButtonText: 'OK'
                });
                return;
            }
            if (!/^\d+$/.test(this.form.id_number)) {
                this.error = 'ID number must be numeric';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: this.error,
                    confirmButtonText: 'OK'
                });
                return;
            }
            if (!this.form.qualification) {
                this.error = 'Qualification is required';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: this.error,
                    confirmButtonText: 'OK'
                });
                return;
            }
            if (!this.form.institution_name) {
                this.error = 'Institution name is required';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: this.error,
                    confirmButtonText: 'OK'
                });
                return;
            }
            if (!this.form.selected_year) {
                this.error = 'Year is required';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: this.error,
                    confirmButtonText: 'OK'
                });
                return;
            }
            const TOKEN = localStorage.getItem('token');
            const QUALIFICATION_CHECK_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/qualification_check/`;

            this.error = '';

            $.ajax({
                url: QUALIFICATION_CHECK_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    first_names: this.form.first_names,
                    surname: this.form.surname,
                    id_number: this.form.id_number,
                    qualification: this.form.qualification,
                    institution_name: this.form.institution_name,
                    qualification_year: this.form.selected_year
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading(),
                    });
                },
                success: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        text: 'Qualification Check ran successfully',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    }).then(() => {
                        this.$emit('service_status_complete');
                    });
                },
                error: (error) => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.responseJSON.detail,
                        confirmButtonText: 'OK'
                    })
                }
            });
        }
    }
};