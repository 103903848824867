<div>
    <h5>Usage Statistics</h5>
    <div class="card rounded-3 mb-3">
        <div class="card-header bg-white">
            <h6 class="text-sm pt-2">Service Usage Analysis</h6>
        </div>
        <div class="card-body">
            <!-- Usage statistics will go here -->
            <p>Here you can view detailed usage statistics, track service interactions, and monitor resource consumption.</p>
            <!-- We'll add more detailed statistics components here in the future -->
        </div>
    </div>
</div>