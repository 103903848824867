import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import { validateToken } from '@/utils/auth';

export default {
    name: 'CustomService',
    mixins: [MAIN_CONTROLLER],
    components: {
        BreadCrumb,
        ClientLogo
    },
    data() {
        return {
            is_loading: false,
            error: null,
            scan_results: null,
            document_status: null,
            document_list: [],
            document_images: [],
            processing_status: {
                optical: null,
                rfid: null,
                overall: null
            }
        };
    },

    computed:{
        workflow_id() {
            return this.$store.getters.get_workflow_id;
          }
    },

    methods: {
        handleDocumentReader(event) {        
            if (event.detail.action === 'PROCESS_FINISHED' && event.detail.data.status === 1) {
                this.processDocumentResponse(event.detail.data.response);
                this.document_list = event.detail.data.response.lowLvlResponse.ContainerList.List
                this.document_verification()

            }
        },

        handleCameraSnapshot(event) {
            if (event.detail.action === 'PROCESS_FINISHED' && event.detail.data.status === 1) {
                this.processDocumentResponse(event.detail.data.response);
                this.document_list = event.detail.data.response.lowLvlResponse.ContainerList.List
                this.document_verification()
            }
        },

        processDocumentResponse(response) {
            // Process status
            this.document_status = response.status;
            this.processing_status = {
                optical: this.getStatusText(response.status.optical),
                rfid: this.getStatusText(response.status.rfid),
                overall: this.getStatusText(response.status.overallStatus)
            };

            // Process images
            if (response.images && response.images.fieldList) {
                this.document_images = response.images.fieldList.map(field => {
                    return {
                        name: field.fieldName,
                        type: field.fieldType,
                        images: field.valueList.map(value => ({
                            source: value.source,
                            pageIndex: value.pageIndex,
                            imageData: value.value // BASE64 image data
                        }))
                    };
                });
            }

            // Store raw response for further processing if needed
            this.scan_results = {
                transactionInfo: response.TransactionInfo,
                detailsOptical: response.status.detailsOptical,
                detailsRFID: response.status.detailsRFID,
                rawResponse: response.rawResponse
            };

            this.$emit('scan-complete', {
                status: this.processing_status,
                images: this.document_images,
                rawData: this.scan_results
            });
        },

        getStatusText(statusCode) {
            const statusMap = {
                0: 'Unknown',
                1: 'Good',
                2: 'Error',
                3: 'Warning'
            };
            return statusMap[statusCode] || 'Unknown';
        },

        getStatusClass(status) {
            const classMap = {
                'Good': 'text-success',
                'Error': 'text-danger',
                'Warning': 'text-warning',
                'Unknown': 'text-muted'
            };
            return classMap[status] || 'text-muted';
        },

        async saveDocumentData() {
            if (!this.scan_results) return;

            try {
                this.is_loading = true;
                // Implement your API call here
                await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate API call
                this.$toast.success('Document data saved successfully');
            } catch (error) {
                this.error = error.message;
                this.$toast.error('Failed to save document data');
            } finally {
                this.is_loading = false;
            }
        },

        resetScan() {
            this.scan_results = null;
            this.document_status = null;
            this.document_images = [];
            this.processing_status = {
                optical: null,
                rfid: null,
                overall: null
            };
            this.error = null;
            
            // Reset the document reader if needed
            if (this.documentReaderComponent) {
                // Add any specific reset logic for your document reader
            }
        },

        document_verification() {
            const TOKEN = localStorage.getItem("token");
            const SAFPS_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/document_reader/`;
            const DATA = {
              document_list: this.document_list,
              workflow_id: this.workflow_id,
            };
            $.ajax({
              url: SAFPS_URL,
              method: "POST",
              contentType: "application/json",
              headers: {
                Authorization: `Token ${TOKEN}`,
              },
              data: JSON.stringify(DATA),
              beforeSend: () => {
                Swal.fire({
                  text: "Loading ...",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
              },
              success: (response) => {
                Swal.close();
                const status_message = response.status;

                if (status_message === "Success") {
                    this.fetch_current_service();
                } else if(status_message === "error") {
                    Swal.fire({
                        icon: "error",
                        text: "Could not read document, please provide a valid document and try again",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        text: "Our service is currently unavailable. Please try again later",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }
              },
              error: (error) => {
                Swal.close();
                let error_message = "Error retrieving data";
            
                if (error.responseJSON) {
                    error_message = error.responseJSON.id_number[0];
                    Swal.fire({
                        icon: "error",
                        text: error_message
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        text: error_message
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }
            },
            });
          },
      
    },

    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }

        this.documentReaderComponent = this.$el.querySelector('document-reader');
        this.cameraSnapshotComponent = this.$el.querySelector('camera-snapshot');
    
        if (this.documentReaderComponent) {
            this.documentReaderComponent.addEventListener('document-reader', this.handleDocumentReader);
        }
        if (this.cameraSnapshotComponent) {
            this.cameraSnapshotComponent.addEventListener('camera-snapshot', this.handleCameraSnapshot);
        }
    },

    beforeDestroy() {
        if (this.documentReaderComponent) {
            this.documentReaderComponent.removeEventListener('document-reader', this.handleDocumentReader);
        }
        if (this.cameraSnapshotComponent) {
            this.cameraSnapshotComponent.removeEventListener('camera-snapshot', this.handleCameraSnapshot);
        }
    },

};