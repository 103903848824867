<div class="container-fluid mt-2 mb-3">
  <div class="card rounded-3  mt-4 p-0 shadow-sm">
    <div class="card-header bg-dark text-white">
      Your Workflow Details
    </div>
    <div class="card-body">
      <div class="row mb-1">
        <div class="col-md-3 text-xs fw-bold">
          Name:
        </div>
        <div class="col-md-9 text-muted small">
          <span v-if="!editing_workflow">{{ workflow_name }}</span>
          <input v-else v-model="edit_name" class="form-control form-control-sm" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-xs fw-bold">
          Description:
        </div>
        <div class="col-md-9 text-muted small">
          <span v-if="!editing_workflow">{{ workflow_description }}</span>
          <textarea v-else v-model="edit_description" class="form-control form-control-sm"></textarea>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-xs fw-bold">
          Status:
        </div>
        <div class="col-md-9 small" :class="workflow_status_class">
          {{ workflow_status }}
        </div>
      </div>
      <div>

      </div>
    <div class="d-flex justify-content-end">
      <button v-if="this.editing_workflow" class="btn btn-sm rounded shadow btn-bg-secondary text-xs mx-1" @click="toggle_edit">Cancel</button>
      <button v-if="this.editing_workflow" class="btn btn-sm rounded shadow btn-bg-success text-xs mx-1" @click="save_edit_workflow">Save</button>
      <button v-if="!this.editing_workflow" class="btn btn-sm btn-bg-green rounded text-xs" @click="toggle_edit">Edit Workflow</button>
    </div>
    </div>
  </div>

  <div class="tabs-container mt-4">
    <div class="d-flex justify-content-center">
      <button 
        class="tab-button rounded-pill btn shadow" 
        :class="{ 'bg-green text-dark': activeTab === 'progress', 'bg-white text-dark': activeTab !== 'progress' }" 
        @click="showTab('progress')"
      >
        Progress
      </button>
      <button 
        class="tab-button rounded-pill btn shadow mx-1" 
        :class="{ 'bg-green text-dark': activeTab === 'template', 'bg-white text-dark': activeTab !== 'template' }" 
        @click="showTab('template')"
      >
        Service Template
      </button>
    </div>
</div>

  <div class="card rounded-3 shadow p-0 mt-4" v-show="activeTab === 'progress'">
    <div class="card-header bg-dark text-white">
      Progress
    </div>
    <div class="card-body">
      <div class="progress-container">
        <div class="progress-bar">
          <div class="progress-bar-bar"></div>
        </div>
        <ul class="multi-steps">
          <li v-for="(step, index) in steps" 
              :key="index" 
              :class="['step', step.status === 'completed' ? 'completed' : 'pending', 'mb-5']">
            <div class="step-indicator"></div>
            <div class="step-label badge bg-dark rounded-small text-white">
              <label class="text-white text-xxs" v-if="step.step_name == 'Create workflow'">
                {{ step.step_name }}
              </label>
              <router-link :to="step.path" class="text-white text-xxs" v-else>
                {{ step.step_name }}
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="card rounded-3 shadow p-0 mt-4" v-show="activeTab === 'template'">
    <div class="card-header bg-dark text-white">
      Service Template
    </div>
    <div class="card-body">
      <table id="workflow-table" class="table-responsive w-100">
        <thead class="bg-white text-dark">
          <tr>
            <th class="bg-white text-center text-uppercase text-xxs font-weight-bolder opacity-7">ID</th>
            <th class="bg-white text-uppercase text-xxs font-weight-bolder opacity-7">Service Name</th>
            <th class="bg-white text-uppercase text-xxs font-weight-bolder opacity-7">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(service, index) in services" :key="service.id">
            <td class="text-center text-xxs">{{ index + 1 }}</td>
            <td class="text-xxs ">{{ service.service_name }}</td>
            <td class="text-xxs ">
              <button class="btn bg-green btn-sm text-xxs fw-bold rounded-small me-3"
                      data-bs-toggle="modal"
                      @click="open_assign_modal(service.id)"
                      data-bs-target="#assign_template_to_workflow_modal">Assign template</button>
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Assign template to workflow Modal -->
<div class="modal fade" id="assign_template_to_workflow_modal" tabindex="-1" aria-labelledby="assign_template_to_workflow_modal_Label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="assign_template_to_workflow_modal_Label"><strong>Assign Template to Workflow</strong></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="assign_to_workflow(selected_template_id)">
          <div class="mb-3">
            <label class="form-label fw-bold"><small>Template</small></label>
              <select v-model="selected_template_id" class="form-select text-xs" required>
              <option value="" disabled hidden>Select template</option>
              <option v-for="template in templates" :key="template.id" :value="template.id">
                {{ template.template_name }}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn bg-green btn-sm text-xs fw-bold rounded-pill">Assign</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>