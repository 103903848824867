import { validate_id, allow_num } from "@/utils/validations";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { validateToken } from "@/utils/auth";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import IdNumberInput from "@/components/IdNumberInput/IdNumberInput.vue";
import Swal from "sweetalert2";


export default {
  name: "AddressMatchVerification",
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
  components: {
    BreadCrumb,
    ClientLogo,
    IdNumberInput,
  },

  data() {
    return {
      id_number: "",
      loading: false,
      error: "",
      address_info: null,
      new_address: {
        street_number: "",
        address: "",
        city: "",
        province: "",
        postal_code: "",
      },
      is_field_disabled: {},
      auto_submitted: false
    };
  },
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
    should_auto_submit() {
      return this.id_number && !this.auto_submitted;
    }
  },
  methods: {
    allow_num(event) {
      this.id_number = allow_num(event);
    },
    handle_id_input(id_number) {
      this.id_number = id_number; // Update the id_number data property with the new value
    },
    retrieve_address_info() {
      const TOKEN = localStorage.getItem("token");
      const ADDRESS_MATCH_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/get_address_match/${this.workflow_id}/`;

      this.loading = true;
      this.error = "";

      $.ajax({
        url: ADDRESS_MATCH_API,
        method: "GET",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          this.loading = false;
          Swal.close();

          if (response.data){
            this.address_info = response.data; // Assuming the response contains the address data in a 'data' field
          }
          else{
            this.address_info = null;
            Swal.fire({
              icon: 'info',
              title: 'No Addresses Found',
              text: 'No address information is available. Please add a new address.',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        },
        error: (error) => {
          this.loading = false;
          let error_message = "Something went wrong!";

          if (error.responseJSON && error.responseJSON.message) {
            error_message = error.responseJSON.message;
          }

          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            timer: 3000,
            showConfirmButton: false,
          });
          
        },
      });
    },
    submit_id() {
      this.auto_submitted = false;

      if (!this.id_number.trim()) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'ID number cannot be empty!',
          timer: 3000,
          showConfirmButton: false,
        });
        return false;
      }

      if (!validate_id(this.id_number)) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Invalid ID number!',
          timer: 3000,
          showConfirmButton: false,
        });
        return false;
      }
      this.address_match();
    },
    add_address() {
      const { street_number, address, city, province, postal_code } = this.new_address;

      if (!street_number.trim() || !address.trim() || !city.trim() || !province.trim() || !postal_code.trim()) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'All address fields must be filled!',
          timer: 3000,
          showConfirmButton: false,
        });
        return false;
      }

      const address_string = `${street_number} ${address}, ${city}, ${province}, ${postal_code}`;
      this.save_address_selection(address_string, false);
    },
    select_address(address) {
      var address_string = address.Address;
      this.save_address_selection(address_string, true);
    },
    save_address_selection(address_string, selected) {
      const TOKEN = localStorage.getItem("token");
      const ADDRESS_MATCH_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/save_address_selection/`;
      
      this.loading = true;
      this.error = "";

      const DATA = {
        address: address_string,
        workflow_id: this.workflow_id,
        selected: selected
      };

      $.ajax({
        url: ADDRESS_MATCH_API,
        method: "POST",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        data: JSON.stringify(DATA),
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: () => {
          Swal.close();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Address saved successfully!',
            timer: 3000,
            showConfirmButton: false,
          }).then(() => {
            this.fetch_current_service();
          });
        },
        error: (error) => {
          this.loading = false;
          Swal.close();
          let error_message = "Could not save address! Please try again later.";
          if (error.responseJSON && error.responseJSON.message) {
            error_message = error.responseJSON.message;
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            timer: 3000,
            showConfirmButton: false,
          });
        }
      });
    },
    address_match() {
      const TOKEN = localStorage.getItem("token");
      const ADDRESS_MATCH_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/set_address_match/`;

      this.auto_submitted = true;
      this.loading = true;
      this.error = "";

      const DATA = {
        id_number: this.id_number,
        workflow_id: this.workflow_id,
      };

      Swal.fire({
        title: 'Verifying Address',
        text: 'Please wait while we retrieve your address information...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading(),
      });

      $.ajax({
        url: ADDRESS_MATCH_API,
        method: "POST",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        data: JSON.stringify(DATA),
        success: () => {
          Swal.close();
          this.retrieve_address_info();
        },
        error: (error) => {
          this.loading = false;
          this.auto_submitted = false;
          let error_message = "Something went wrong!";

          if (error.responseJSON && error.responseJSON.message) {
            error_message = error.responseJSON.message;
          }

          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            timer: 3000,
            showConfirmButton: false,
          });
          
        },
      });
    },
    customer_data() {
      const original_method = this.get_customer_data.bind(this);
      original_method().then(() => {
        if (this.id_number && this.should_auto_submit) {
          this.address_match();
        }
      });
    },
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.customer_data();
    this.sync_form_data();
  },
};
