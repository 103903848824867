import IconCard from '@/components/IconCard.vue';

export default {
    name: "ViewWorkflowCustomers",
    components: {
        IconCard
    },
    data() {
        return {
            total_applications: 0,
            completed_applications: 0,
            incomplete_applications: 0,
            total_customers: 0,
            total_services: 0,
            customers_search_query: "",
            customers_current_page: 1,
            items_per_page: 10,
            customers: []
        };
    },
    computed: {
        workflow_id() {
            return this.$route.params.workflow_id;
        },
        paginated_customers() {
            const start = (this.customers_current_page - 1) * this.items_per_page;
            const end = start + this.items_per_page;
            return this.customers.slice(start, end);
        },
        customers_total_pages() {
            return Math.ceil(this.customers.length / this.items_per_page);
        },
        filtered_customers() {
            if (!this.customers_search_query) {
                return this.customers;
            }
            const query = this.customers_search_query.toLowerCase();
            return this.customers.filter(customer => {
                return customer.first_name.toLowerCase().includes(query) ||
                    customer.last_name.toLowerCase().includes(query) ||
                    customer.id.toLowerCase().includes(query) ||
                    customer.email.toLowerCase().includes(query) ||
                    customer.date_joined.toLowerCase().includes(query) ||
                    customer.last_login.toLowerCase().includes(query);
            });
        },
    },
    methods: {
        view_customer(customer_id) {
            this.$store.commit('set_workflow_id', this.workflow_id);
            this.$router.push({ name: 'view_customer_applications', params: { id: customer_id } });
        },
        fetch_workflow_stats() {
            const TOKEN = localStorage.getItem('token');
            const STATS_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/workflow_stats_api/${this.workflow_id}`;
            
            $.ajax({
                url: STATS_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    const stats = response.data;
                    this.total_applications = stats.total_applications || 0;
                    this.completed_applications = stats.completed_applications || 0;
                    this.incomplete_applications = stats.incomplete_applications || 0;
                    this.total_customers = stats.total_customers || 0;
                    this.total_services = stats.total_services || 0;
                },
                error: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to fetch workflow statistics',
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
        fetch_workflow_customers(){
            const TOKEN = localStorage.getItem('token');
            const CUSTOMERS_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_customers_in_workflow/${this.workflow_id}`;

            $.ajax({
                url: CUSTOMERS_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading customers...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    this.customers = response.data || [];
                },
                error: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to fetch workflow customers',
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
    },
    mounted() {
        this.fetch_workflow_stats();
        this.fetch_workflow_customers();
    },
};