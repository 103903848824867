import { handle_registration_redirect } from "@/utils/redirects";
import { MAIN_CONTROLLER } from "@/utils/main_controller";
import { asset_data_retrieval } from "@/utils/decoding";

export default {
  name: "SuzukiLandingPage",
  mixins: [MAIN_CONTROLLER],
  data() {
    return {
      customer_application: null,
      client_admin_id: null,
      company_name: null,
      workflow_name: null,
      workflow_id: null,
      asset_id: null,
    };
  },

  async mounted() {
    const GET_CLIENT_ID = sessionStorage.getItem("client_admin_id");

    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");

    if (token && user_id) {
      await this.redirect(token, GET_CLIENT_ID);
      return;
    }
    // Fetch the client_id and company_name from the URL query parameters
    const { embedding_data } = this.$route.query;
    if (embedding_data) {
      this.get_client_id_on_session(embedding_data);
    }

    const { asset_data } = this.$route.query;
    if (asset_data) {
      const { workflow_name, workflow_id, asset_id, user_id } = await asset_data_retrieval(asset_data);
      this.client_admin_id = user_id;
      this.asset_id = asset_id;
      this.workflow_id = workflow_id;
      this.workflow_name = workflow_name;
      this.get_asset_workflow_on_session(
        user_id,
        asset_id,
        workflow_id,
        workflow_name
      );
    }

    const { customer_application } = this.$route.query;
    if (customer_application) {
      this.customer_application = customer_application;
      this.get_customer_application_on_session(customer_application);
    }
  },

  methods: {
    redirect(token, GET_CLIENT_ID) {
      const CHECK_PROCESS_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/user_redirect_api/`;

      $.ajax({
        url: CHECK_PROCESS_URL,
        method: "GET",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${token}`,
        },
        success: (response) => {
          handle_registration_redirect(
            response,
            this.$router,
            this.$store,
            this.fetch_current_service,
            GET_CLIENT_ID
          );
        },
        error: () => {
          Swal.close();
          let error_message = "There was an error while redirecting";
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error_message,
            confirmButtonText: "OK",
          });
        },
      });
    },

    showError(selector, message) {
      $(selector).text(message).removeClass("d-none");
      setTimeout(() => {
        $(selector).addClass("d-none");
      }, 3000);
    },

    get_client_id_on_session(embedding_data) {
      const BASE_URL = process.env.API_BASE_URL;
      const GET_CLIENT_ID_URL = `${BASE_URL}/verify_api/${embedding_data}/`;

      $.ajax({
        method: "GET",
        url: GET_CLIENT_ID_URL,
        contentType: "application/json",
        success: (response) => {
          if (response.client_admin_id) {
            sessionStorage.clear();
            localStorage.clear();

            this.client_admin_id = response.client_admin_id;
            this.company_name = response.company_name;

            sessionStorage.setItem("client_admin_id", this.client_admin_id);
            sessionStorage.setItem("company_name", this.company_name);

            this.$store.commit("client_admin_id", this.client_admin_id);
            this.$store.commit("company_name", this.company_name);

            this.$router.push({ name: "landing_page" });
          } else {
            this.$router.push({ name: "invalid_link" });
          }
        },
        error: () => {
          this.$router.push({ name: "invalid_link" });
        },
      });
    },

    get_asset_workflow_on_session(
      client_admin_id,
      asset_id,
      workflow_id,
      workflow_name
    ) {
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSET_WORFLOW_URL = `${BASE_URL}/verify_api/${workflow_name}/${client_admin_id}/${workflow_id}/${asset_id}/`;

      $.ajax({
        method: "GET",
        url: GET_ASSET_WORFLOW_URL,
        contentType: "application/json",
        success: (response) => {
          const properties = ["client_admin_id", "asset_id", "workflow_id"];
          let has_navigated = false;

          properties.forEach((prop) => {
            if (response[prop]) {
              sessionStorage.setItem(prop, response[prop]);
              this.$store.commit(prop, response[prop]);
              this.$router.push({ name: "landing_page" });
              has_navigated = true;
            }
          });

          if (has_navigated) {
            this.$router.push({ name: "landing_page" });
          }
        },
        error: (error) => {
          let error_message = "Getting client id failed";
          if (error.responseJSON && error.responseJSON.error) {
            error_message = error.responseJSON.error;
          }
          this.showError(".error-message", error_message);
        },
      });
    },

    get_customer_application_on_session(customer_application) {
      const BASE_URL = process.env.API_BASE_URL;
      const GET_CUSTOMER_APPLICATION_URL = `${BASE_URL}/verify_api/${customer_application}/director_verification/`;

      $.ajax({
        method: "GET",
        url: GET_CUSTOMER_APPLICATION_URL,
        contentType: "application/json",
        success: (response) => {
          if (response.customer_application) {
            sessionStorage.setItem(
              "customer_application",
              response.customer_application
            );
            this.$store.commit(
              "customer_application",
              response.customer_application
            );
            this.$router.push({ name: "landing_page" });
          }
        },
        error: (error) => {
          let error_message = "Getting customer application failed";
          if (error.responseJSON && error.responseJSON.error) {
            error_message = error.responseJSON.error;
          }
          this.showError(".error-message", error_message);
        },
      });
    },

    next_to_register() {
      this.$router.push({ name: "user_registration" });
    },
  },
};
