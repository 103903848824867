import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import Swal from 'sweetalert2';

export default {
    name: "CompleteOnlineBankStatement",
    mixins: [MAIN_CONTROLLER],
    components: {
        BreadCrumb,
        ClientLogo
    },
    data: () => ({
        error: '',
        is_loading: true,
        bank_account_id: null
    }),

    computed: {
        workflow_id() {
            return this.$store.getters.get_workflow_id;
        }
    },

    methods: {
        get_bank_statement() {
            const token = localStorage.getItem('token');
            const bank_statement_api = `${process.env.API_BASE_URL}/verify_api/compliance_verification/get_bank_statements/`;
            
            $.ajax({
                url: bank_statement_api,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                beforeSend: () => {
                    this.is_loading = true;
                    this.error = '';
                },
                data: JSON.stringify({
                    bank_account_id: this.bank_account_id,
                    workflow_id: this.workflow_id
                }),
                success: (response) => {
                    if (response.result) {
                        // After a short delay to show success state, fetch next service
                        setTimeout(() => {
                            this.fetch_current_service();
                        }, 1500);
                    } else {
                        // Handle case where result is false
                        this.handle_error('Failed to process bank statement');
                    }
                },
                error: (error) => {
                    this.handle_error(error);
                }
            });
        },

        handle_error(error) {
            this.is_loading = false;
            
            let error_message = 'Something went wrong while processing your bank statement!';
            if (error.responseJSON) {
                error_message = error.responseJSON;
            }
            
            this.error = error_message;
            
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error_message,
                timer: 5000,
                timerProgressBar: true
            });
        },

        init_page() {
            // Get bank_account_id from route query data
            this.bank_account_id = this.$route.query.context;
            
            if (!this.bank_account_id) {
                this.handle_error('No bank account information found');
                return;
            }
            
            // Show success screen after initial loading
            setTimeout(() => {
                this.is_loading = false;
                
                // Start API call to get bank statements after showing success message
                setTimeout(() => {
                    this.get_bank_statement();
                }, 2000);
            }, 3000);
        }
    },

    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.init_page();
    }
};