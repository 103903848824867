import { validateToken } from '@/utils/auth';
import { validate_id, allow_num } from "@/utils/validations";

export default {
    name: 'AddressForm',
    data() {
        return {
            form: {
                id_number: '',
                error: ''
            }
        };
    },
    emits: ['service_status_complete'],
    methods: {
        allow_num(event) {
            this.id_number = allow_num(event);
        },
        submit_id() {
            if (!this.form.id_number.trim()) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'ID Number is required',
                    timer: 3000,
                    showConfirmButton: false
                });
                return false;
            }

            if (!validate_id(this.form.id_number)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Invalid ID Number',
                    timer: 3000,
                    showConfirmButton: false
                });
                return false;
            }
            this.address_match_api();
        },
        address_match_api() {
            const TOKEN = localStorage.getItem('token');
            const ADDRESS_MATCH_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/address_match/`;

            this.form.error = '';

            $.ajax({
                url: ADDRESS_MATCH_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    id_number: this.form.id_number
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading(),
                    });
                },
                success: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        text: 'Address Matched Successfully',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    }).then(() => {
                        this.$emit('service_status_complete');
                    });
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';

                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        timer: 3000,
                        showConfirmButton: false
                    });
                }
            });
        }
    },
    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
    }
};