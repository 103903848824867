export const validate_id = (id_input) => {
    var month = parseInt(id_input.substring(2, 4), 10) - 1;
    var day = parseInt(id_input.substring(4, 6), 10);
    var id_year = parseInt(id_input.substring(0, 2), 10);

    if (month < 0 || month > 11) {
    return false;
    }

    if (day < 1 || day > 31) {
    return false;
    }

    if (id_year < new Date().getFullYear() - 100) {
    id_year += 2000;
    } else {
    id_year += 1900;
    }

    var temp_date = new Date(id_year, month, day);
    var id_date = temp_date.getDate();
    var id_month = temp_date.getMonth();

    if (id_month !== month || id_date !== day) {
    return false;
    }

    var check_sum = 0;
    var multiplier = 1;

    for (var i = 0; i < 13; i++) {
    var digit = parseInt(id_input.charAt(i), 10);
    var temp_total = digit * multiplier;

    if (temp_total > 9) {
        temp_total = parseInt(temp_total.toString().charAt(0), 10) + parseInt(temp_total.toString().charAt(1), 10);
    }

    check_sum += temp_total;
    multiplier = (multiplier % 2 === 0) ? 1 : 2;
    }

    if ((check_sum % 10) !== 0) {
    return false;
    }

    return true;
};

export const allow_num = (event) => {
  
  let numeric_value = event.target.value.replace(/\D/g, '');
  event.target.value = numeric_value;
  return numeric_value;
};

export function validate_first_name(first_name) {
    if (!first_name.trim()) {
      $('.first-name-message').removeClass('d-none');
      setTimeout(() => {
        $('.first-name-message').addClass('d-none');
      }, 3000);
      return false;
    }
    return true;
  }

// Last name validation
export function validate_last_name(last_name) {
  if (!last_name.trim()) {
    $('.last-name-message').removeClass('d-none');
    setTimeout(() => {
      $('.last-name-message').addClass('d-none');
    }, 3000);
    return false;
  }
  return true;
}

// Email validation
export function validate_email(email) {
  if (!email.trim()) {
    $('.email-message').removeClass('d-none');
    setTimeout(() => {
      $('.email-message').addClass('d-none');
    }, 3000);
    return false;
  }
  return true;
}

// Phone number validation
export function validate_phone_number(phone_number) {
  const PHONE_PATTERN = /^\d{10}$/;
  phone_number = phone_number.replace(/\D/g, '');

  if (phone_number.length === 10 && PHONE_PATTERN.test(phone_number)) {
    return true;
  } else {
    $('.phone-number-message').removeClass('d-none');
    setTimeout(() => {
      $('.phone-number-message').addClass('d-none');
    }, 3000);
    return false;
  }
}

// Name validation (for removing invalid characters)
export function validate_name(input_value) {
  return input_value.replace(/[^a-zA-Z\s]/g, '');
}

//password match validation
export function password_match(password,confirm_password) {
  if (password !== confirm_password) {
    $('.match-password-message').removeClass('d-none');
  } else {
      $('.match-password-message').addClass('d-none');
  }
}

//email match validation
export function email_match(email,confirm_email){
  if (email !== confirm_email) {
    $('.email-match-message').removeClass('d-none');
  } else {
      $('.email-match-message').addClass('d-none');
  }
}

// checks if password field is empty
export function password_check(password){
  if (!password.trim()) {
    $('.password-message').removeClass('d-none');
    setTimeout(() => {
        $('.password-message').addClass('d-none');
    }, 3000);
  }
}

// check if confirm_password field is empty
export function confirm_password_check(confirm_password){
  if (!confirm_password.trim()) {
    $('.confirm-password-message').removeClass('d-none');
    setTimeout(() => {
        $('.confirm-password-message').addClass('d-none');
    }, 3000);
  }
}

export function validate_dynamic_inputs(dynamic_fields){
  for (const field of dynamic_fields) {
    if (!field.value.trim()) {
      this.show_error(`.${field.name}-message`, `Please fill in your ${field.placeholder}`);
    }
  }

}

export function show_error(selector, message) {
  $(selector).text(message).removeClass('d-none');
  setTimeout(() => {
    $(selector).addClass('d-none');
  }, 3000);
}


export function format_date(date) {
  if (!date) return '';
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
}

export function validate_invalid_characters(field_value) {
  const name_pattern = /^[A-Za-z\s]*$/;
  if (field_value && !name_pattern.test(field_value)) {
    return field_value.replace(/[^A-Za-z\s]/g, '');
  }
  return field_value;
}

export function validate_gender(gender) {

   if (/\d/.test(gender)) {

    return '';
  }


  const valid_gender = ['M', 'F'];
  if (gender && !valid_gender.includes(gender.toUpperCase())) {

    return 'M';
  }

  return gender.toUpperCase();
}


export function validate_date_of_birth(date_of_birth, error_selector) {
  const error_element = document.querySelector(error_selector);


  if (!/^\d{8}$/.test(date_of_birth)) {
    error_element.classList.remove('d-none');
    return false;
  }

 
  const year = parseInt(date_of_birth.substring(0, 4), 10);
  const month = parseInt(date_of_birth.substring(4, 6), 10) - 1;
  const day = parseInt(date_of_birth.substring(6, 8), 10);


  const date = new Date(year, month, day);
  if (
    date.getFullYear() !== year ||
    date.getMonth() !== month ||
    date.getDate() !== day
  ) {
    error_element.classList.remove('d-none');
    return false;
  }

  error_element.classList.add('d-none');
  return true;
}

export function validate_all_fields(fields, data, error_messages, default_message = 'This field is required') {
  let valid = true;

  fields.forEach((field) => {
    if (!data[field]) {
      error_messages[field] = default_message;
      valid = false;
    } else {
      error_messages[field] = '';
    }
  });

  return valid;
}

export function validate_registration_number(input, position) {
  const clean_input = input.replace(/\D/g, '');
  const reg1 = clean_input.slice(0, 4);
  const reg2 = clean_input.slice(4, 10);
  const reg3 = clean_input.slice(10);

  const formatted_registration = [reg1, reg2, reg3].filter(Boolean).join('/').slice(0, 14);

  return {
    formatted_registration,
      new_cursor_pos: position + (formatted_registration.length - input.length),
      reg1,
      reg2,
      reg3,
  };
}

export const validate_country_code = (country_code) => {
  const country_code_regex = /^[A-Z]{3}$/;
  if (!country_code_regex.test(country_code)) {
      return false;
  }
  return true;
};