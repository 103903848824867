<div class="container-fluid bg-white vh-100 p-0">
    <ClientLogo />
    <BreadCrumb label="OCR Verification" />

    <div class="d-flex justify-content-center align-items-center min-vh-50">
        <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
            <div class="d-flex align-items-center md-3">
                <span><i class="text-muted bi bi-question-circle me-2"></i></span>
                <span class="text-muted text-xs">OCR verification is a thorough validation process that ensures the accuracy, authenticity,
                  and compliance of submitted information.
                  </span> 
              </div> 
            <document-reader start-screen internal-scenario="Locate" copyright="false" mirrorButton="true" cameraFrameLineCap="butt"></document-reader>
        </div>
    </div>
  </div>