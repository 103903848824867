<div class="sub-workflow-container">
    <div class="container mt-3">
        <div class="card shadow-md rounded-3">
            <div class="card-body ">
                <div class="d-flex justify-content-between align-items-center mb-3 ">
                    <div class="customer-info p-2">
                        <label class="d-flex align-items-center mb-0">
                            <i class="fas fa-project-diagram text-primary fa-xl me-2"></i>
                            <span class="fw-bold mr-1">{{ workflow_name }}</span>
                            <span class="mx-1">-</span>
                            <span class="text-muted fst-italic text-xs">Workflow Router</span>
                        </label>
                    </div>
                    <div class="d-flex align-items-center">
                        
                        <button class="btn btn-bg-dark text-xs rounded-small btn-sm me-2" @click="next">
                            <span class="ms-1">Continue</span>
                        </button>
                        <button class="btn btn-bg-green text-xs rounded-small btn-sm" @click="show_add_sub_workflow_modal">
                            <span class="ms-1">Add Workflow Router</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card shadow-md rounded-3 mt-3" v-if="workflows.length > 0">
            <transition name="fade" mode="out-in">
                <div class="card-body p-3">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover table-sm text-xs">
                            <thead>
                                <tr>
                                    <th class="text-xs fw-bold">No.</th>
                                    <th class="text-xs fw-bold">Name of Router</th>
                                    <th class="text-xs fw-bold">Workflow Router Type</th>
                                    <th class="text-xs fw-bold">Date Created</th>
                                    <th class="text-xs fw-bold text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(sub_workflow, index) in paginator_sub_workflows" :key="index">
                                    <td class="py-2 text-center">{{ (current_page - 1) * items_per_page + index + 1}}</td>
                                    <td class="py-2">
                                        {{ sub_workflow.service_name }}
                                    </td>

                                    <td class="py-2">{{ sub_workflow.sub_workflow_type }}</td>
                                    <td class="py-2">{{ format_date(sub_workflow.date_created)}}</td>
                                    <td class="py-2 text-center">
                                        <div class="col-sm-12">
                                            <button class="btn btn-bg-green btn-sm rounded-small"
                                                @click="view_sub_workflow(sub_workflow)">
                                                <i class="fa fa-eye text-xs p-0 m-0 me-1"></i>
                                                <span class="mb-2 text-s-xs">
                                                    View
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Pagination -->
                    <div class="d-flex justify-content-between align-items-center mt-3" v-if="workflows.length > 0">
                        <div class="text-xs text-muted">
                            Showing {{ (current_page - 1) * items_per_page + 1 }} to
                            {{ Math.min(current_page * items_per_page, workflows.length) }}
                            of {{ workflows.length }} entries
                        </div>

                        <div class="d-flex align-items-center">
                            <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_page"
                                :disabled="current_page === 1">
                                <i class="fas fa-arrow-left"></i>
                            </button>
                            <span class="text-xs mx-2">Page {{ current_page }} of {{ total_pages }}</span>
                            <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_page"
                                :disabled="current_page === total_pages">
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="card shadow-md rounded-3 mt-3" v-else>
            <div class="card-body p-3">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="text-center">
                        <i class="fas fa-info-circle text-info fa-2x"></i>
                        <p class="text-muted mt-2">No workflow routers found</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sub Workflow Detail View -->
        <div class="card shadow-md rounded-3 mt-3" v-if="selected_sub_workflow">
            <div class="card-body p-3">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-sm btn-transparent me-2" @click="close_sub_workflow_detail">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                        <label class="text-center mb-0">
                            <i class="fas fa-project-diagram text-primary fa-xl py-2 me-2"></i>
                            <span class="fw-bold">{{ selected_sub_workflow.service_name }}</span>
                            -
                            <span class="text-muted fst-italic text-xs">
                                {{ selected_sub_workflow.sub_workflow_type }}
                            </span>
                        </label>
                    </div>
                    <div>
                        <button class="btn btn-bg-green rounded-small text-xs" @click="save_sub_workflow_changes">
                            <i class="fas fa-save me-1"></i> Save Changes
                        </button>
                    </div>
                </div>

                <!-- External Sub Workflow type form -->
                <div v-if="selected_sub_workflow.sub_workflow_type === 'external'">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="card rounded-3 mb-3">
                                <div class="card-header bg-white">
                                    <h6 class="text-sm pt-2">Workflow Router Information</h6>
                                </div>
                                <div class="card-body">
                                    <div class="mb-2">
                                        <label class="form-label text-xs">Name of Router</label>
                                        <input type="text" class="form-control form-control-sm"
                                            v-model="edited_sub_workflow.service_name">
                                    </div>
                                    <div class="mb-2">
                                        <label class="form-label text-xs">External URL</label>
                                        <input type="url" class="form-control form-control-sm"
                                            v-model="edited_sub_workflow.external_url">
                                        <small class="text-xxs text-muted">The URL to the external service</small>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label text-xs">Active Status</label>
                                        <select class="form-select form-select-sm" v-model="edited_sub_workflow.is_active">
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </select>
                                        <small class="text-xxs text-muted">Set whether this workflow router is currently active</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Internal Sub Workflow type form - choose another workflow -->
                <div v-else>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="card rounded-3 mb-3">
                                <div class="card-header bg-white">
                                    <h6 class="text-sm pt-2">Workflow Router Information</h6>
                                </div>
                                <div class="card-body">
                                    <div class="mb-3">
                                        <label class="form-label text-xs">Name of Router</label>
                                        <input type="text" class="form-control form-control-sm"
                                            v-model="edited_sub_workflow.service_name">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label text-xs">Assign Workflow</label>
                                        <select class="form-select form-select-sm" v-model="edited_sub_workflow.workflow_id">
                                            <option value="" disabled>Select a workflow</option>
                                            <option v-for="workflow in available_workflows" :key="workflow.workflow_id" :value="workflow.workflow_id">
                                                {{ workflow.workflow_name }}
                                            </option>
                                        </select>
                                        <small class="text-xxs text-muted">Select the workflow to use as a sub-workflow</small>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label text-xs">Active Status</label>
                                        <select class="form-select form-select-sm" v-model="edited_sub_workflow.is_active">
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </select>
                                        <small class="text-xxs text-muted">Set whether this workflow router is currently active</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Sub Workflow Modal -->
    <div class="modal fade" id="add_sub_workflow_modal" tabindex="-1" aria-labelledby="add_sub_workflow_modal_Label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="add_sub_workflow_modal_Label"><strong>Add Workflow Router</strong></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="add_sub_workflow">
                        <div class="mb-3">
                            <label class="form-label text-xs">Name of Router</label>
                            <input type="text" class="form-control form-control-sm" v-model="new_sub_workflow.service_name" required>
                        </div>
                        <div class="mb-3">
                            <label class="form-label text-xs">Workflow Router Type</label>
                            <select class="form-select form-select-sm" v-model="new_sub_workflow.sub_workflow_type" required>
                                <option value="internal">Internal Workflow</option>
                                <option value="external">External Service</option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="new_sub_workflow.sub_workflow_type === 'external'">
                            <label class="form-label text-xs">External URL</label>
                            <div class="custom-input-wrapper">
                                <span class="custom-prefilled-text">https://</span>
                                <input type="text" class="custom-form-control" placeholder="www.example.com" v-model="new_sub_workflow.external_url" @blur="prepend_https" required>
                            </div>
                            <small class="text-xxs text-muted">The URL to the external service</small>
                        </div>
                        <div class="mb-3" v-if="new_sub_workflow.sub_workflow_type === 'internal'">
                            <label class="form-label text-xs">Select Workflow</label>
                            <select class="form-select form-select-sm" v-model="new_sub_workflow.workflow_id">
                                <option value="" disabled selected>Select a workflow</option>
                                <option v-for="workflow in available_workflows" :key="workflow.workflow_id" :value="workflow.workflow_id">
                                    {{ workflow.workflow_name }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="btn btn-bg-green btn-sm">Add Workflow Router</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>