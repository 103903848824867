
<div class="text-center">
    <h5 class="text-s text-center"><strong>Company Verification</strong></h5>
  </div>
  
  <div class="row mt-2 mb-2">
    <div class="col text-start">
      <label class="form-label text-xs">Company Registration <span class="text-danger fw-bold">*</span></label>
      <input 
          type="text" 
          class="form-control text-xs rounded-small p-3 shadow-sm" 
          v-model="reg_number" 
          placeholder="Company Registration"
          @input="validate_reg_number"
      />
      <small class="text-danger p-2 text-xxs fw-bold d-none reg-message">Please fill in this field</small>
    </div>
  </div>
  
  <div class="text-danger p-2 text-xxs fw-bold error-messages"></div>
  
  <div class="text-end mb-3 mt-3">
    <button 
        type="submit" 
        class="btn btn-sm bg-green shadow-lg rounded-small text-xs fw-bold"
        @click="cipc_api"
    >
        Submit
    </button>
  </div>
  
 <!-- Modal for displaying PDF -->
 <div class="modal fade" id="pdf_verification_results" tabindex="-1" aria-labelledby="pdf_verification_results" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cvModallabel">Verification Results</h5>
          <button type="button" class="btn-close" @click="close_modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="pdf_url" class="mt-4">
            <iframe :src="pdf_url" frameborder="0" width="100%" height="700"></iframe>
          </div>
          <div v-else>
            <p>Preview not available for this file type.</p>
          </div>
        </div>
        <div class="modal-footer">
            <router-link :to="{ name: 'services' }">
                <button class="btn bg-green btn-sm text-xs text-end fw-bold rounded-small mt-3" data-bs-dismiss="modal">Back</button>
            </router-link>
        </div>
      </div>
    </div>
</div>
  
  