import NavBar from '@/components/NavBar/NavBar.vue';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";


export default {
  name: 'FormManagement',
  mixins: [LOGOUT],
  pageTitle: 'Form Management',
  components: {
    NavBar
  },
  data: () => ({
    services: [],
    service: "",
    service_name: '',
    service_types: [],
    service_type: '',
    selected_service_id: null,
    loading: false
  }),
  methods: {
    clean_form_modal() {
      if (this.selected_service_id !== null) {
        this.service_name = '';
        this.service_type = '';
      }
    },
    validate_input() {
      if (!this.service_name.trim()) {
        $('.name-error-message').removeClass('d-none');
        setTimeout(() => {
          $('.name-error-message').addClass('d-none');
        }, 3000);
        return false;
      }
      if (!this.service_type) {
        $('.servicetype-error-message').removeClass('d-none');
        setTimeout(() => {
          $('.servicetype-error-message').addClass('d-none');
        }, 3000);
        return false;
      }
      return true;
    },
    fetch_service_types() {
      const TOKEN = localStorage.getItem('token');
      const GET_SERVICE_TYPES_API = `${process.env.API_BASE_URL}/verify_api/form_management_api/get_service_types/`;

      $.ajax({
        url: GET_SERVICE_TYPES_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          Swal.close();
          this.service_types = response.service_types || [];
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          Swal.close();
        }
      });
    },
    get_custom_service() {
      const TOKEN = localStorage.getItem('token');
      const GET_CUSTOM_SERVICE_API = `${process.env.API_BASE_URL}/verify_api/form_management_api/get_custom_service_api/`;
      $.ajax({
        url: GET_CUSTOM_SERVICE_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          Swal.close();
          if (response.redirect) {

            this.logout();
            return;

          }
          this.services = response.data || [];
          this.loading = false;
          this.$nextTick(() => {
            this.initDataTable(); // Initialize DataTable after data is loaded
          });
        },
        error: () => {
          this.loading = false;
          Swal.close();
        }
      });
    },

    create_custom_service() {
      if (!this.validate_input()) {
        return;
      }
      const TOKEN = localStorage.getItem('token');

      const FORM_DATA = {
        service_name: this.service_name,
        service_type: this.service_type
      };
      const CREATE_CUSTOM_SERVICE_URL = `${process.env.API_BASE_URL}/verify_api/form_management_api/create_custom_service_api/`;

      $.ajax({
        url: CREATE_CUSTOM_SERVICE_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          const service_id = response.data.service_id;
          this.$store.commit('set_custom_service_id', service_id);
          Swal.fire({
            icon: 'success',
            text: response.message,
            confirmButtonColor: '#59FF74',
            showConfirmButton: true
          })
            .then(() => {
              window.location.reload();
              $('#add_service').modal('hide');
            });
        },
        error: (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.responseJSON.message,
            confirmButtonColor: '#59FF74',
            showConfirmButton: true
          });
        },
      });
    },
    edit_form(service_id) {
      const service = this.services.find(serv => serv.id === service_id);
      if (service) {
        this.selected_service_id = service_id;
        this.service_name = service.service_name;
        this.service_type = service.system_generated_type;
        this.$nextTick(() => {
          $('#edit_form_modal').modal('show');
        });
      }
    },
    update_form() {
      if (!this.validate_input()) {
        return;
      }

      const TOKEN = localStorage.getItem('token');
      const FORM_DATA = {
        service_name: this.service_name,
        service_type: this.service_type,
        service_id: this.selected_service_id
      };
      const EDIT_FORM_API_URL = `${process.env.API_BASE_URL}/verify_api/form_management_api/edit_custom_service_api/`;

      $.ajax({
        url: EDIT_FORM_API_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Updating custom service...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          $('#edit_form_modal').modal('hide');
          this.get_custom_service();
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Error updating custom service';

          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;

            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;

              $('.error-messages').text(error_message).removeClass('d-none');

              setTimeout(() => {
                $('.error-messages').text('').addClass('d-none');
              }, 3000);
            }
          }
        }
      });
    },

    manage_service(service_id) {
      this.$store.dispatch('update_service_id', service_id);
      this.$router.push({ name: 'manage_form' });
    },
    initDataTable() {
      this.$nextTick(() => {
        if ($.fn.dataTable.isDataTable('#form_table')) {
          $('#form_table').DataTable().destroy();
        }
        $('#form_table').DataTable({
          responsive: true
        });
      });
    },

  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_custom_service();
    this.fetch_service_types();
  }
}
