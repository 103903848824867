import { validateToken } from '@/utils/auth';
import { validate_id, allow_num } from '@/utils/validations';

export default {
  name: 'IDForm',
  data() {
    return {
      id_number: '',
      first_names: '',
      last_name: '',
      error: '',
      safps: false // Default as the SAFPS check cost additional.
    };
  },
  emits: ['service_status_complete'],
  methods: {
    safps_change() {
      if(this.safps){
        Swal.fire({
          text: "Please note that the SAFPS will cost additional R2.50 to the service, do you still want to add it?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, add it.",
          cancelButtonText: "No",
          allowOutsideClick: false,
        }).then((result) => {
          this.safps = result.isConfirmed;
        });
      }
      else{
        this.safps = false;
      }
    },
    allow_num(event) {
      this.id_number = allow_num(event);
    },
    handle_id_input(id_number) {
      this.id_number = id_number;
    },
    submit_form() {
      if (!this.id_number.trim()) {
        $('.id-message').removeClass('d-none');
        setTimeout(() => {
          $('.id-message').addClass('d-none');
        }, 3000);
        return false;
      }
      if (!this.first_names.trim()) {
        $('.first-name-message').removeClass('d-none');
        setTimeout(() => {
          $('.first-name-message').addClass('d-none');
        }, 3000);
        return false;
      }
      if (!this.last_name.trim()) {
        $('.last-name-message').removeClass('d-none');
        setTimeout(() => {
          $('.last-name-message').addClass('d-none');
        }, 3000);
        return false;
      }
      if (!validate_id(this.id_number)) {
        $('.invalid-message').removeClass('d-none');
        setTimeout(() => {
          $('.invalid-message').addClass('d-none');
        }, 3000);
        return false;
      }
      this.rsa_id_verify_api();
    },
    rsa_id_verify_api() {
      const TOKEN = localStorage.getItem('token');
      const RSA_ID_VERIFY_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/rsa_id_verify/`;
      this.error = '';
      $.ajax({
        url: RSA_ID_VERIFY_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          id_number: this.id_number,
          firstnames: this.first_names,
          lastname: this.last_name,
          safps: this.safps
        }),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading()
          });
        },
        success: () => {
          Swal.close();
          Swal.fire({
            icon: 'success',
            title: 'Successful',
            text: 'RSA ID Verification ran successfully',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false
          }).then(() => {
              this.$emit('service_status_complete');
          });
        },
        error: (error) => {
          Swal.close();
            let error_message = 'Something went wrong!';
            if (error.responseJSON && error.responseJSON.message) {
              error_message = error.responseJSON.message;
            }
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
        }
      });
    }
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
  }
};