import axios from 'axios';

export async function asset_data_retrieval(embedded_data) {
    const BASE_URL = process.env.API_BASE_URL;
    const ASSET_API_URL = `${BASE_URL}/verify_api/system_management_api/asset_api/`;

    const response = await axios.post(ASSET_API_URL, { embedded_data });
    const { workflow_name, workflow_id, asset_id, user_id } = response.data.data;
    
    return { workflow_name, workflow_id, asset_id, user_id };
}