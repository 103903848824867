import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import { validateToken } from '@/utils/auth';

export default {
    name: 'ClientAdminUsers',
    components: {
        ClientSidebar,
        NavBar,
    },
    data: () => ({
        workflow_customer_applications: [],
        customer_id: "",
        customer_name: "",
        search_query: "",
        current_page: 1,
        items_per_page: 10
    }),
    computed: {
        workflow_id() {
            return this.$store.getters.get_workflow_id;
        },

        filtered_applications() {
            if (!this.search_query) {
            return this.workflow_customer_applications;
            }

            const query = this.search_query.toLowerCase();
            return this.workflow_customer_applications.filter(app => {
            return (app.workflow_name || '').toLowerCase().includes(query) ||
                (app.asset_name || '').toLowerCase().includes(query) ||
                (app.application_id || '').toString().toLowerCase().includes(query) ||
                (app.date_created || '').toLowerCase().includes(query);
            });
        },

        paginated_applications() {
            const start = (this.current_page - 1) * this.items_per_page;
            const end = start + this.items_per_page;
            return this.filtered_applications.slice(start, end);
        },

        total_pages() {
            return Math.ceil(this.filtered_applications.length / this.items_per_page);
        }
    },

    methods: {
        get_workflow_id() {
            try {
                this.workflow_id = this.store_workflow_id || 0;
            } catch (error) {
                console.error('Error retrieving workflow ID:', error);
                this.workflow_id = 0;
            }
        },

        get_customer_applications() {
            const TOKEN = localStorage.getItem('token');
            const BASE_URL = process.env.API_BASE_URL;

            const customer_id = this.$route.params.id;
            const GET_CUSTOMER_APPLICATION_URL = `${BASE_URL}/verify_api/workflow_management_api/get_customer_applications/${customer_id}/${this.workflow_id}/`;
            $.ajax({
                method: 'GET',
                url: GET_CUSTOMER_APPLICATION_URL,
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    })
                },
                success: (response) => {
                    Swal.close();
                    
                    let customer_name;
                    var applications = response['customer_applications'].map(application => {
                        customer_name = application.customer.first_name + " " + application.customer.last_name;
                        return {
                            workflow_name: application.workflow.workflow_name,
                            asset_name: application.asset_name,
                            application_id: application.id,
                            date_created: this.format_date(application.date_created),
                            completed: application.completed
                        };
                    })
                    this.workflow_customer_applications = applications;

                    this.customer_id = response['user_id'];
                    this.customer_name = customer_name;
                },
                error: (error) => {
                    Swal.fire({
                        icon: 'error',
                        text: error.responseJSON.error,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },

        view_customer_data(customer_id, customer_application) {
            this.$router.push({ name: 'customer_data', params: { id: customer_id, application: customer_application } });
        },

        format_date(date_string) {
            if (!date_string) return 'N/A';

            // Handle different date formats
            let date;
            if (date_string instanceof Date) {
                date = date_string;
            } else {
                date = new Date(date_string);
            }

            // Check if date is valid
            if (isNaN(date.getTime())) {
                return 'N/A';
            }

            // Format: DD MMM YYYY, HH:MM
            return date.toLocaleString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        },

        next_page() {
            if (this.current_page < this.total_pages) {
                this.current_page++;
            }
        },

        previous_page() {
            if (this.current_page > 1) {
                this.current_page--;
            }
        }
    },

    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_customer_applications()
    }
};