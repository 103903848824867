import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validate_id } from "@/utils/validations";



export default {
  name: 'BankAccountVerification',
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
  components: {
    BreadCrumb,
    ClientLogo
  },

  data() {
    return {
      banks: [],
      account_types: [],
      id_types: [],
      id_type: '',
      bank: '',
      last_name: '',
      id_number: '',
      account_type: '',
      account_number: '',
      error_messages: {
        id_type: '',
        bank: '',
        last_name: '',
        id_number: '',
        account_type: '',
        account_number: '',
      },
      is_field_disabled: {}
    };
  },
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },
  watch: {
    id_number: function(val) {
      if (val && val.length == 13) {
        var id_test = validate_id(val);
        if (id_test) {
          this.id_type = "SID";
        }
        else {
          if (this.id_type == "SID") {
            this.id_type = "";
          }
        }
      }
      else {
        if (this.id_type == "SID") {
          this.id_type = "";
        }
      }
    },
  },
  methods: {
    get_banks() {
      const TOKEN = localStorage.getItem('token');
      const GET_BANKS_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_banks/`;

      $.ajax({
        url: GET_BANKS_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          this.banks = response;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          let error_message = 'Error Getting banks';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    get_account_types() {
      const TOKEN = localStorage.getItem('token');
      const GET_ACCOUNT_TYPES_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_account_types/`;

      $.ajax({
        url: GET_ACCOUNT_TYPES_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          this.account_types = response;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          let error_message = 'Error account types';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    get_id_types() {
      const TOKEN = localStorage.getItem('token');
      const GET_ID_TYPES_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_id_types/`;

      $.ajax({
        url: GET_ID_TYPES_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          this.id_types = response;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          let error_message = 'Error account types';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    validate_fields() {
      this.error_messages = {};

      if (!this.last_name) {
        this.error_messages.last_name = "Please enter your surname";
      }
      if (!this.id_number) {
        this.error_messages.id_number = "Please enter your id number";
      }
      if (!this.id_type) {
        this.error_messages.id_type = "Please select id type";
      }
      if (!this.bank) {
        this.error_messages.bank = "Please select a bank.";
      }
      if (!this.account_type) {
        this.error_messages.account_type = "Please select account type.";
      }
      if (!this.account_number) {
        this.error_messages.account_number = "Account number is required.";
      }
      return Object.keys(this.error_messages).length === 0;
    },

    bank_account_individual_verification() {
      if (!this.validate_fields()) {
        return;
      }

      const TOKEN = localStorage.getItem('token');
      const BANK_ACCOUNT_INDIVIDUAL_VERIFY_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/bank_account_individual_verify/`;

      const FORM_DATA = {
        workflow_id: this.workflow_id,
        bank_name: this.bank,
        bank_account: this.account_number,
        account_type: this.account_type,
        id_type: this.id_type,
        surname: this.last_name,
        id_number: this.id_number
      };

      $.ajax({
        url: BANK_ACCOUNT_INDIVIDUAL_VERIFY_URL,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify(FORM_DATA),
        beforeSend: () => {
          this.clear_error_messages();
          Swal.fire({
            text: 'Verifying bank details...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();

          if (response.status === "error") {
            let error_message = "Our service is currently unavailable. Please try again later.";
            let should_redirect = true;

            if (response.error_message && response.error_message.NoResult) {
              const error = response.error_message.NoResult.Error;
              should_redirect = false;

              if (error.includes('Invalid account number')) {
                this.error_messages.account_number = "The account number provided is invalid. Please check and try again.";
                error_message = "Invalid account number detected";
              } else if (error.includes('Identity Number is not valid')) {
                this.error_messages.id_number = "The ID number provided is invalid. Please check and try again.";
                error_message = "Invalid ID number detected";
              }
            }

            Swal.fire({
              icon: 'error',
              text: error_message,
            }).then(() => {
              if (should_redirect) {
                this.$router.push({ name: 'thank_you_page' });
              }
            });
          } else {
            this.error_messages = {};
            this.fetch_current_service();
          }
        },
        error: (xhr) => {
          Swal.close();
          let error_message = "Bank details not submitted, please try again";

          if (xhr.status === 400) {
            try {
              const response = JSON.parse(xhr.responseText);
              if (response.message) {
                error_message = response.message;
              }
            } catch (e) {
              console.error('Error parsing response:', e);
            }
          }

          Swal.fire({
            icon: 'error',
            text: error_message,
          });
        }
      });
    },

    clear_error_messages() {
      this.error_messages = {
        id_type: '',
        bank: '',
        last_name: '',
        id_number: '',
        account_type: '',
        account_number: ''
      };
    },

    show_field_error(field, message) {
      this.error_messages[field] = message;
      const element = document.getElementById(field);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_banks();
    this.get_account_types();
    this.get_id_types();
    this.get_customer_data();
    this.sync_form_data();
  }
};