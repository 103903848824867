<div class="container-fluid bg-white vh-100 p-0 manual-bank-statement-container">
    <!-- Client Logo Component -->
    <ClientLogo />

    <!-- Breadcrumb Component -->
    <BreadCrumb label="Upload Bank Statement" />

    <!-- Form Container -->
    <div>
        <div class="d-flex justify-content-center align-items-center min-vh-50">
            <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
                <!-- Error Message Display -->
                <div v-if="error" class="alert alert-danger text-center">
                    {{ error }}
                </div>

                <!-- Loading Spinner -->
                <div v-if="is_loading" class="bank-spinner-container my-5">
                    <div class="bank-spinner"></div>
                    <div class="bank-loader">
                        <p>loading</p>
                        <div class="bank-words">
                            <span class="bank-word">information</span>
                            <span class="bank-word">processing</span>
                            <span class="bank-word">statements</span>
                            <span class="bank-word">documents</span>
                        </div>
                    </div>
                </div>

                <!-- Bank Statement Form -->
                <div v-if="!is_loading && current_step === 'personal_info'">
                    <h4 class="mb-4 text-center">Enter Required Information</h4>
                    <!-- Only show fields that are not prepopulated -->
                    <div v-if="!is_field_disabled.first_name" class="form-group mt-3">
                        <label for="firstName" class="fw-bold text-xs text-start d-block">
                            First Name <span class="text-danger">*</span>
                        </label>
                        <input v-model="first_name" type="text"
                            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                            id="firstName" placeholder="e.g., Joe" @input="validate_first_name" required />
                        <small v-if="error_messages.first_name" class="form-text text-danger">
                            {{ error_messages.first_name }}
                        </small>
                    </div>

                    <div v-if="!is_field_disabled.last_name" class="form-group mt-3">
                        <label for="lastName" class="fw-bold text-xs text-start d-block">
                            Last Name <span class="text-danger">*</span>
                        </label>
                        <input v-model="last_name" type="text"
                            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                            id="lastName" placeholder="e.g., Doe" @input="validate_last_name" required />
                        <small v-if="error_messages.last_name" class="form-text text-danger">
                            {{ error_messages.last_name }}
                        </small>
                    </div>

                    <div v-if="!is_field_disabled.id_number" class="form-group mt-3">
                        <label for="idNumber" class="fw-bold text-xs text-start d-block">
                            ID Number <span class="text-danger">*</span>
                        </label>
                        <input v-model="id_number" type="text"
                            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                            id="idNumber" placeholder="Enter your ID number" @input="validate_id_number" required />
                        <small v-if="error_messages.id_number" class="form-text text-danger">
                            {{ error_messages.id_number }}
                        </small>
                    </div>

                    <div v-if="!is_field_disabled.email" class="form-group mt-3">
                        <label for="email" class="fw-bold text-xs text-start d-block">
                            Email <span class="text-danger">*</span>
                        </label>
                        <input v-model="email" type="email"
                            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                            id="email" placeholder="Enter your email" @input="validate_email" required />
                        <small v-if="error_messages.email" class="form-text text-danger">
                            {{ error_messages.email }}
                        </small>
                    </div>

                    <!-- Submit Button -->
                    <div class="text-center mb-3 mt-4">
                        <button type="button"
                            class="btn btn-bg-green btn-sm px-4 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
                            @click="submit_bank_statement_request">
                            Continue
                        </button>
                    </div>
                </div>

                <!-- Bank Selection Step -->
                <div v-if="!is_loading && current_step === 'bank_selection'" class="mt-3">
                    <h4 class="mb-4 text-center">Select Your Bank</h4>
                    <div class="bank-selection-container">
                        <div class="row justify-content-center">
                            <!-- Banks displayed in a grid -->
                            <div v-for="(bank, index) in banks" :key="index" class="col-6 col-sm-4 col-lg-3 mb-3">
                                <div class="card bank-card p-3 text-center h-100"
                                    :class="{ 'selected': selected_bank === bank.value }"
                                    @click="select_bank(bank.value)">
                                    <div class="bank-logo-container mb-2">
                                        <img :src="bank.logo" :alt="bank.name" class="img-fluid bank-logo"
                                            v-if="bank.logo">
                                        <div class="bank-placeholder" v-else>{{ bank.name.charAt(0) }}</div>
                                    </div>
                                    <h6 class="bank-name mb-0">{{ bank.name }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Bank Selection Submit Button -->
                    <div class="text-center mb-3 mt-4">
                        <button type="button"
                            class="btn btn-bg-green btn-sm px-4 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
                            @click="submit_bank_selection" :disabled="!selected_bank">
                            Continue with Selected Bank
                        </button>
                    </div>
                </div>

                <!-- File Upload Step -->
                <div v-if="!is_loading && current_step === 'file_upload'" class="mt-3">
                    <h4 class="mb-4 text-center">Upload Bank Statement</h4>

                    <!-- Upload Instructions -->
                    <div class="upload-instructions mb-4 mx-auto w-100 px-4 py-2 rounded-small shadow-small">
                        <h6 class="mb-3 d-flex align-items-center">
                            <i class="fas fa-info-circle text-primary me-2"></i>
                            <span class="fw-bold">Upload Requirements:</span>
                        </h6>
                        <ul class="text-muted small mb-4 ps-3">
                            <li class="mb-2 text-xxs">Please upload your most recent bank statement (last 6 months preferred)
                            </li>
                            <li class="mb-2 text-xxs">File must be in <b>PDF</b> format</li>
                            <li class="mb-2 text-xxs">Maximum file size: 10MB</li>
                            <li class="mb-2 text-xxs">Ensure that your account number and transactions are clearly visible
                            </li>
                            <li class="mb-2 text-xxs">Statement must include the bank logo or identifiable information</li>
                        </ul>

                        <!-- File Upload Section now inside the card after requirements -->
                        <div class="form-group mt-2">
                            <label for="bankStatementFile" class="fw-bold text-xs text-start d-block mb-2">
                                Upload Bank Statement(s) <span class="text-danger">*</span>
                            </label>
                            <div class="file-upload-container w-100 mx-auto">
                                <div class="custom-file-upload shadow-small">
                                    <div class="upload-header"
                                        :class="{ 'has-file': selected_files.length > 0, 'drag-over': is_dragging }"
                                        @dragover.prevent="is_dragging = true"
                                        @dragleave.prevent="is_dragging = false" @drop.prevent="handle_file_drop">
                                        <div v-if="selected_files.length === 0" class="upload-placeholder">
                                            <i class="fas fa-cloud-upload-alt text-primary upload-icon"></i>
                                            <p class="mb-2 mt-3 fw-bold text-xs">Drag and drop your bank statement
                                                files here</p>
                                            <p class="text-muted small text-xxs mb-3">or</p>
                                            <button type="button"
                                                class="btn btn-bg-green px-4 py-2 rounded-small fw-bold text-xxs"
                                                @click="$refs.file_input.click()">
                                                Browse Files
                                            </button>
                                            <p class="text-muted mt-3 small text-xxs">Supported formats: PDF</p>
                                            <p class="text-muted small text-xxs">You can upload multiple files (Max
                                                6)</p>
                                        </div>
                                        <div v-else class="selected-files-container">
                                            <div class="my-1 text-center">
                                                <p class="fw-bold text-xs">
                                                    <i class="fas fa-check-circle text-success me-2"></i>
                                                    {{ selected_files.length }} {{ selected_files.length === 1 ? 'file' : 'files' }} successfully uploaded
                                                </p>
                                            </div>
                                            <div class="selected-files-list">
                                                <div v-for="(file, index) in selected_files" :key="index"
                                                    class="selected-file-item mb-2 d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fas fa-file-alt me-2 text-primary"></i>
                                                        <span class="text-truncate file-name" :title="file.name">{{
                                                            file.name }}</span>
                                                    </div>
                                                    <div class="file-actions">
                                                        <span class="file-size me-2 text-muted text-xxs">{{
                                                            format_file_size(file.size) }}</span>
                                                        <button type="button" class="btn btn-sm text-danger p-0"
                                                            @click="remove_file(index)">
                                                            <i class="fas fa-times-circle"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center mt-3">
                                                <button type="button"
                                                    class="btn btn-bg-green btn-sm px-3 py-1 rounded-small fw-bold text-xxs"
                                                    @click="$refs.file_input.click()">
                                                    Add More Files
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <input ref="file_input" type="file" id="file_input" class="d-none"
                                        @change="handle_file_change" accept=".pdf" multiple>
                                </div>
                                <small v-if="error_messages.file" class="form-text text-danger mt-2 d-block">
                                    {{ error_messages.file }}
                                </small>
                            </div>
                        </div>
                    </div>

                    <!-- Submit Button -->
                    <div class="text-center mb-3 mt-4">
                        <button type="button"
                            class="btn btn-bg-green btn-sm px-4 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
                            @click="upload_bank_statement" :disabled="selected_files.length === 0">
                            Upload Bank Statement{{ selected_files.length > 1 ? 's' : '' }}
                        </button>
                    </div>
                </div>

                <!-- Success Message -->
                <div v-if="!is_loading && current_step === 'success'" class="mt-3 text-center">
                    <div class="success-icon mb-3">
                        <i class="fas fa-check-circle fa-4x text-success"></i>
                    </div>
                    <h4 class="mb-3">Bank Statement Uploaded Successfully!</h4>
                    <p class="mb-4">Your bank statement has been uploaded and is being processed.</p>
                </div>
            </div>
        </div>
    </div>
</div>