<div class="qualification-check">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="first_names" class="fw-bold text-xs">First Names</label>
                <input type="text" v-model="form.first_names" id="first_names"
                    class="form-control text-xs rounded-small p-3 shadow-sm" required
                    placeholder="Enter first names" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="surname" class="fw-bold text-xs">Surname</label>
                <input type="text" v-model="form.surname" id="surname"
                    class="form-control text-xs rounded-small p-3 shadow-sm" required placeholder="Enter surname" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="id_number" class="fw-bold text-xs">ID Number</label>
                <input type="text" v-model="form.id_number" id="id_number"
                    class="form-control text-xs rounded-small p-3 shadow-sm" required
                    placeholder="Enter ID number" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="qualification" class="fw-bold text-xs">Qualification</label>
                <input type="text" v-model="form.qualification" id="qualification"
                    class="form-control text-xs rounded-small p-3 shadow-sm" required
                    placeholder="Enter qualification" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="institution_name" class="fw-bold text-xs">Institution Name</label>
                <input type="text" v-model="form.institution_name" id="institution_name"
                    class="form-control text-xs rounded-small p-3 shadow-sm" required
                    placeholder="Enter institution name" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="selected_year" class="fw-bold text-xs">Year</label>
                <SearchSelect v-model="form.selected_year"
                    :options="years.map(year => ({ value: year, text: year }))" />
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end my-2">
        <button @click="handle_submit" class="btn btn-primary-color rounded-pill text-xs">
            Run Service
        </button>
    </div>
</div>