import GetQuestions from '@/components/GetQuestions/GetQuestions.vue';
import { handle_registration_redirect } from '@/utils/redirects';
import { MAIN_CONTROLLER } from '@/utils/main_controller';

export default {
  name: 'UserLogin',
  mixins: [MAIN_CONTROLLER],
  components: {
    GetQuestions,
  },
  data: () => ({
    username: '',
    password: '',
    responses: [],
    show_password: false,
    client_admin_id: sessionStorage.getItem('client_admin_id'),
    workflow_id: sessionStorage.getItem('workflow_id'),
    asset_id: sessionStorage.getItem('asset_id'),
    reset_url: `${process.env.API_BASE_URL}/verify_api/password_reset/`
  }),

  created() {
    // Add client detection logic
    const domain = window.location.hostname;
    
    if (domain.includes("verify")) {
      this.client = "verify";
    } else if (domain.includes("wizzit")) {
      this.client = "wizzit";
    } else if (domain.includes("mobiafrica")) {
      this.client = "mobiafrica";
    } else if (domain.includes("vodacom")) {
      this.client = "vodacom";
    } else if (domain.includes("weelee")) {
      this.client = "weelee";
    } else if (domain.includes("nutun")) {
      this.client = "nutun";
    } else if (domain.includes("nurturelink")) {
      this.client = "nurturelink";
    } else if (domain.includes("suzuki")) {
      this.client = "suzuki";
    }

    // Set client-specific data attribute
    if (this.client) {
      document.documentElement.setAttribute("data-client", this.client);
    }
  },
  
  mounted() {
    const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');

    if (token && user_id) {
      this.redirect(token, GET_CLIENT_ID);
    }

    if (GET_CLIENT_ID) {
      this.store_responses();
    }
  },
  methods: {
    toggle_password_view() {
      this.show_password = !this.show_password;
    },

    convert_email_to_lowercase() {
      if (this.username) {
          this.username = this.username.toLowerCase();
      }
    },

    redirect(token, GET_CLIENT_ID) {
      const CHECK_PROCESS_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/user_redirect_api/`;

      $.ajax({
        url: CHECK_PROCESS_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${token}`
        },
        success: (response) => {
          handle_registration_redirect(
            response,
            this.$router,
            this.$store,
            this.fetch_current_service,
            GET_CLIENT_ID
          );
        },
        error: () => {
          return
        }
      })
    },

    store_responses(dynamic_fields = [], follow_up_responses = []) {
      this.responses = dynamic_fields;
      this.follow_up_responses = follow_up_responses;
    },

    login() {
      if (!this.username.trim()) {
        $('.username-message').removeClass('d-none');
        setTimeout(() => {
          $('.username-message').addClass('d-none');
        }, 3000);
        return false;
      }
      if (!this.password.trim()) {
        $('.password-message').removeClass('d-none');
        setTimeout(() => {
          $('.password-message').addClass('d-none');
        }, 3000);
        return false;
      }

      const is_form_valid = this.$refs.get_questions.validate_form();

      if (!is_form_valid) {
        return;
      }

      const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');      
      const LOGIN_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/login_api/`;

      const process_responses = () => {
        let processed_responses = [];

        this.responses.forEach(field => {
          processed_responses.push({
            service_id: field.service_id,
            question_id: field.question_id,
            value: field.value,
            parent_question_id: null
          });

          const follow_ups = this.follow_up_responses.filter(
            follow_up => follow_up.parent_id === field.question_id
          );

          if (follow_ups.length > 0) {
            const follow_up_data = follow_ups.map(follow_up => ({
              service_id: field.service_id,
              question_id: follow_up.question_id,
              value: follow_up.value,
              parent_question_id: field.question_id
            }));
            processed_responses = [...processed_responses, ...follow_up_data];
          }
        });
        return processed_responses;
      };

      const all_responses = process_responses();

      const FORM_DATA = {
        client_id: GET_CLIENT_ID,
        username: this.username,
        password: this.password,
        responses: all_responses
      };

      const has_file = this.responses.some(field => field.has_file);

      let data_to_send;
      if (has_file) {
        const form_data = new FormData();
        form_data.append('client_admin_id', GET_CLIENT_ID);
        form_data.append('username', this.username);
        form_data.append('password', this.password);

        all_responses.forEach((response, index) => {
          const field = [...this.responses, ...this.follow_up_responses].find(
            f => f.id === response.question_id
          );

          form_data.append(`responses[${index}][service_id]`, field.service_id);
          form_data.append(`responses[${index}][question_id]`, field.question_id);

          if (field && field.has_file && field.value) {
            form_data.append(`responses[${index}][uploaded_file]`, field.value);
          } if (field.parent_question_id) {
            form_data.append(`responses[${index}][parent_question_id]`, field.parent_question_id);
          } else {
            form_data.append(`responses[${index}][value]`, response.value);
          }
        });

        data_to_send = form_data;
      } else {
        data_to_send = JSON.stringify(FORM_DATA);
      }

      const content_type = has_file ? false : 'application/json';

      $.ajax({
        url: LOGIN_URL,
        method: 'POST',
        data: data_to_send,
        processData: !has_file,
        contentType: content_type,
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          sessionStorage.clear();
          localStorage.clear();
          localStorage.setItem('user_id', response.user_id);
          this.$store.commit('set_user_role', response.user_role)
          
          localStorage.setItem('token', response.token);
          localStorage.setItem('user_state', 'logged_in');
          
          sessionStorage.setItem('client_admin_id', response.client_admin_id);
          
          if (this.workflow_id){
            sessionStorage.setItem('workflow_id', this.workflow_id);
          }

          if (this.asset_id){
            sessionStorage.setItem('asset_id', this.asset_id);
          }
          this.handleLogin();
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Error Logging in'
          if (error.responseText) {
            const ERROR_RESPONSE = JSON.parse(error.responseText);
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
              $('.login-message').text(error_message).removeClass('d-none');
              setTimeout(() => {
                $('.login-message').text('').addClass('d-none');
              }, 3000);
            }
          }
        }
      });
    },
    handleLogin() {
      this.$router.push({ name: 'user_otp' });
    }
  }
};