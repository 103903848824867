import { validate_invalid_characters } from "@/utils/validations";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import Swal from 'sweetalert2';

var absa = await import('@/assets/images/banks/absa.svg');
var standardbank = await import('@/assets/images/banks/standardbank.svg');
var fnb = await import('@/assets/images/banks/fnb.svg');
var nedbank = await import('@/assets/images/banks/nedbank.svg');
var capitec = await import('@/assets/images/banks/capitec.svg');
var discovery = await import('@/assets/images/banks/discovery.svg');
var tymebank = await import('@/assets/images/banks/tymebank.svg');

export default {
    name: "OnlineBankStatement",
    mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
    components: {
        BreadCrumb,
        ClientLogo
    },
    data: () => ({
        first_name: '',
        last_name: '',
        id_number: '',
        email: '',
        selected_bank: null,
        error: '',
        error_messages: {
            first_name: '',
            last_name: '',
            id_number: '',
            email: ''
        },
        is_field_disabled: {
            first_name: false,
            last_name: false,
            id_number: false,
            email: false
        },
        is_loading: true,
        current_step: 'personal_info',
        banks: [
            { id: 1, name: 'ABSA', logo: absa.default, value: 'Absa' },
            { id: 2, name: 'Standard Bank', logo: standardbank.default, value: 'Standard Bank' },
            { id: 3, name: 'FNB', logo: fnb.default, value: 'FNB' },
            { id: 4, name: 'Nedbank', logo: nedbank.default, value: 'Nedbank' },
            { id: 5, name: 'Capitec', logo: capitec.default, value: 'Capitec' },
            { id: 6, name: 'Discovery Bank', logo: discovery.default, value: 'Discovery' },
            { id: 7, name: 'TymeBank', logo: tymebank.default, value: 'Tyme Bank' },
        ],
        customer_id: null,
        bank_account_id: null,
        external_link: null,
    }),

    computed: {
        workflow_id() {
            return this.$store.getters.get_workflow_id;
        },
        all_fields_filled() {
            return this.first_name && this.last_name && this.id_number && this.email;
        }
    },

    methods: {
        validate_first_name() {
            this.first_name = validate_invalid_characters(this.first_name);
        },

        validate_last_name() {
            this.last_name = validate_invalid_characters(this.last_name);
        },

        validate_id_number() {
            this.id_number = this.id_number.replace(/[^0-9]/g, '');
        },

        validate_email() {
            // Basic email validation
            const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!email_regex.test(this.email)) {
                this.error_messages.email = 'Please enter a valid email address';
            } else {
                this.error_messages.email = '';
            }
        },

        validate_all_fields() {
            let valid = true;
            let message = 'This field is required';

            const fields = ['first_name', 'last_name', 'id_number', 'email'];

            fields.forEach((field) => {
                if (!this[field]) {
                    this.error_messages[field] = message;
                    valid = false;
                } else {
                    this.error_messages[field] = '';
                }
            });

            if (this.email && !this.error_messages.email) {
                this.validate_email();
                if (this.error_messages.email) {
                    valid = false;
                }
            }

            return valid;
        },

        select_bank(bank_name) {
            this.selected_bank = bank_name;
        },

        submit_bank_selection() {
            this.is_loading = true;
            
            const token = localStorage.getItem('token');
            const bank_account_api = `${process.env.API_BASE_URL}/verify_api/compliance_verification/create_bank_account/`;
            
            $.ajax({
                url: bank_account_api,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                data: JSON.stringify({
                    customer_id: this.customer_id,
                    bank: this.selected_bank,
                    bank_type: 'online',
                    workflow_id: this.workflow_id
                }),
                success: (response) => {                    
                    // Capture bank account id if available
                    this.bank_account_id = response.bank_account_id;
                    this.get_online_login();
                },
                error: () => {
                    this.is_loading = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Error creating bank account',
                        timer: 3000,
                        timerProgressBar: true
                    });
                }
            });
        },
        
        get_online_login(){
            const token = localStorage.getItem('token');
            const online_login_api = `${process.env.API_BASE_URL}/verify_api/compliance_verification/online_login/`;
            
            $.ajax({
                url: online_login_api,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                data: JSON.stringify({
                    bank_account_id: this.bank_account_id,
                    workflow_id: this.workflow_id
                }),
                success: (response) => {
                    // Extract the external_link from the result attribute in response
                    this.external_link = response.result.data.portalUrl;
                    // Switch to the external portal step
                    this.current_step = 'external_portal';
                    this.is_loading = false;
                },
                error: () => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Error during online login',
                        timer: 3000,
                        timerProgressBar: true
                    });
                    this.is_loading = false;
                }
            });
        },
        
        async get_customer_data() {
            this.is_loading = true;
            
            const token = localStorage.getItem('token');
            const customer_data_url = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_customer_data/`;
        
            try {
                const response = await $.ajax({
                    url: customer_data_url,
                    method: 'GET',
                    contentType: 'application/json',
                    headers: {
                        Authorization: `Token ${token}`,
                    }
                });
                
                // Populate fields and set disabled state based on whether data exists
                this.first_name = response.first_name || '';
                this.last_name = response.last_name || '';
                this.id_number = response.id_number || '';
                this.email = response.email || '';
                
                // Only disable fields that have prepopulated data
                this.is_field_disabled = {
                    first_name: !!response.first_name,
                    last_name: !!response.last_name,
                    id_number: !!response.id_number,
                    email: !!response.email
                };
                                
                // Auto-continue if all fields are already filled
                if (this.all_fields_filled && this.validate_all_fields()) {
                    this.submit_bank_statement_request()
                }
                else{
                    this.is_loading = false;
                }
                
            } catch (error) {
                this.is_loading = false;
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to load customer data',
                    timer: 3000,
                    timerProgressBar: true
                });
            }
        },

        submit_bank_statement_request() {
            if (!this.validate_all_fields()) {
                // Show validation error notification
                Swal.fire({
                    icon: 'warning',
                    title: 'Validation Error',
                    text: 'Please fill in all required fields correctly',
                    timer: 3000,
                    timerProgressBar: true
                });
                return;
            }

            this.is_loading = true;
            
            const token = localStorage.getItem('token');
            const bank_statement_api = `${process.env.API_BASE_URL}/verify_api/compliance_verification/create_customer_profile/`;

            this.error = '';

            $.ajax({
                url: bank_statement_api,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                data: JSON.stringify({
                    first_name: this.first_name,
                    last_name: this.last_name,
                    id_number: this.id_number,
                    email: this.email,
                    workflow_id: this.workflow_id
                }),
                success: (response) => {

                    if (response.result){
                        this.customer_id = response.customer_id;
                        
                        // Move to bank selection step
                        this.current_step = 'bank_selection';
                        this.is_loading = false;
                    }
                },
                error: (error) => {
                    
                    this.is_loading = false;
                    let error_message = 'Something went wrong!';
                    if (error.responseJSON) {
                        error_message = error.responseJSON;
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error_message,
                            timer: 3000,
                            timerProgressBar: true
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error_message,
                            timer: 3000,
                            timerProgressBar: true
                        });
                    }
                }
            });
        },
    },

    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_customer_data();
    }
};