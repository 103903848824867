import Swal from 'sweetalert2';
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from '@/components/BreadCrumb/BreadCrumb.vue';
import ClientLogo from '@/components/ClientLogo/ClientLogo.vue';

export default {
    name: 'ExperianCreditReport',
    mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
    components: { BreadCrumb, ClientLogo },
    data: () => ({
        id_number: '',
        first_name: '',
        surname: '',
        gender: '',
        date_of_birth: '',
        loading: false,
        errors: {},
        error: '',
        is_field_disabled: {}
    }),
    computed: {
        workflow_id() {
            return this.$store.getters.get_workflow_id;
        }
    },
    methods: {
        async get_customer_data() {
            const TOKEN = localStorage.getItem('token');
            const CUSTOMER_DATA_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_customer_data/`;

            try {
                const response = await $.ajax({
                    url: CUSTOMER_DATA_URL,
                    method: 'GET',
                    contentType: 'application/json',
                    headers: {
                        Authorization: `Token ${TOKEN}`,
                    },
                    beforeSend: () => {
                        Swal.fire({
                            text: 'Loading ...',
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            didOpen: () => Swal.showLoading(),
                        });
                    }
                });

                Swal.close();

                // Populate fields and set disabled state based on whether data exists
                this.id_number = response.id_number || '';
                this.first_name = response.first_name || '';
                this.surname = response.last_name || '';
                var date_of_birth = response.date_of_birth || '';
                if (date_of_birth != '') {
                    // Convert date_of_birth from yyyymmdd to yyyy-mm-dd
                    this.date_of_birth = `${date_of_birth.substring(0, 4)}-${date_of_birth.substring(4, 6)}-${date_of_birth.substring(6, 8)}`;
                }
                this.gender = response.gender || '';

                this.is_field_disabled = {
                    first_name: !!response.first_name,
                    surname: !!response.surname,
                    id_number: !!response.id_number,
                    date_of_birth: !!response.date_of_birth
                };

            } catch (error) {
                Swal.close();
            }
        },
        allow_number(event) {
            // Allow only numeric input for id_number field
            let value = event.target.value.replace(/[^0-9]/g, '');
            this.id_number = value;
        },
        validate_fields() {
            this.errors = {};
            let is_valid = true;
            if (!this.id_number.trim()) {
                this.errors.id_number = 'ID Number is required';
                is_valid = false;
            } else if (this.id_number.length !== 13) {
                this.errors.id_number = 'ID Number must be 13 digits';
                is_valid = false;
            }
            if (!this.first_name.trim()) {
                this.errors.first_name = 'First Name is required';
                is_valid = false;
            }
            if (!this.surname.trim()) {
                this.errors.surname = 'Surname is required';
                is_valid = false;
            }
            if (!this.gender) {
                this.errors.gender = 'Gender is required';
                is_valid = false;
            }
            if (!this.date_of_birth) {
                this.errors.date_of_birth = 'Date of Birth is required';
                is_valid = false;
            }
            return is_valid;
        },
        submit_experian() {
            if (!this.validate_fields()) {
                return;
            }
            if (!this.workflow_id) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Workflow ID is missing.'
                });
                return;
            }
            const TOKEN = localStorage.getItem('token');
            const API_URL = `${process.env.API_BASE_URL}/verify_api/credit_verification/experian_credit_report/`;

            this.loading = true;
            this.error = '';

            $.ajax({
                url: API_URL,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    id_number: this.id_number,
                    first_name: this.first_name,
                    surname: this.surname,
                    gender: this.gender,
                    date_of_birth: this.date_of_birth,
                    workflow_id: this.workflow_id
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading()
                    });
                },
                success: () => {
                    Swal.close();
                    this.loading = false;
                    this.fetch_current_service();
                },
                error: (xhr) => {
                    this.loading = false;
                    let response = xhr.responseJSON;
                    if (response && response.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.error
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'An error occurred while retrieving Experian credit report.'
                        });
                    }
                }
            });
        }
    },
    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_customer_data();
        this.sync_form_data();
    }
};