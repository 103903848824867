<div class="container-fluid bg-white vh-100 p-0">
    <ClientLogo />
    <BreadCrumb label="Experian Credit Report" />
    <div class="d-flex justify-content-center align-items-center min-vh-50">
        <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
            <form @submit.prevent="submit_experian">
                <div class="form-group mt-3">
                    <label for="id_number" class="form-label fw-bold text-xxs">ID Number <span
                            class="text-danger fw-bold">*</span></label>
                    <input 
                        id="id_number"
                        type="text"
                        v-model="id_number"
                        class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                        placeholder="Enter ID Number"
                        maxlength="13"
                        @input="allow_number"
                        :disabled="is_field_disabled.id_number">
                    <small v-if="errors.id_number" class="text-danger fw-bold text-xs">{{ errors.id_number
                        }}</small>
                </div>

                <div class="form-group mt-3">
                    <label for="first_name" class="form-label fw-bold text-xxs">First Name <span
                            class="text-danger fw-bold">*</span></label>
                    <input id="first_name" type="text"
                        class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                        v-model="first_name" placeholder="Enter First Name">
                    <small v-if="errors.first_name" class="text-danger fw-bold text-xs">{{ errors.first_name
                        }}</small>
                </div>

                <div class="form-group mt-3">
                    <label for="surname" class="form-label fw-bold text-xxs">Surname <span
                            class="text-danger fw-bold">*</span></label>
                    <input id="surname" type="text"
                        class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                        v-model="surname" placeholder="Enter Surname">
                    <small v-if="errors.surname" class="text-danger fw-bold text-xs">{{ errors.surname }}</small>
                </div>

                <div class="form-group mt-3">
                    <label for="gender" class="form-label fw-bold text-xxs">Gender <span
                            class="text-danger fw-bold">*</span></label>
                    <select id="gender"
                        class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                        v-model="gender">
                        <option disabled value="">Select Gender</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </select>
                    <small v-if="errors.gender" class="text-danger fw-bold text-xs">{{ errors.gender }}</small>
                </div>

                <div class="form-group mt-3">
                    <label for="date_of_birth" class="form-label fw-bold text-xxs">Date of Birth <span
                            class="text-danger fw-bold">*</span></label>
                    <input id="date_of_birth" type="date"
                        class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                        v-model="date_of_birth" placeholder="Enter Date of Birth">
                    <small v-if="errors.date_of_birth" class="text-danger fw-bold text-xs">{{ errors.date_of_birth
                        }}</small>
                </div>
                <div class="text-center mt-4">
                    <button type="submit"
                        class="btn bg-green btn-sm mt-1 rounded-small shadow-sm fw-bold secondary-text-dark text-xs">
                        Continue
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>