  export default {
      name: 'TermsModal',
      props: {
        client: {
          type: String,
          required: true
        }
      },
      data() {
        return {
          hasReadTerms: false,
          showModal: false,
          termsContent: {
            suzuki: `
              <p>I agree to abide by the code of conduct the Suzuki Auto Club, herein after referred to as "the Club".</p>
              
              <p>I acknowledge the authority of the person appointed by the Club to organize or control any event (known as the Event Leader). I agree that failure to do so, by me or any member of my party, may result in my suspension from Club activities.</p>
              
              <p>I acknowledge that off-roading is an inherently dangerous activity, and confirm that I am fully aware of the potential dangers thereof. I acknowledge that I will make all members of my party who accompany me aware of these dangers prior to participating in any event.</p>
              
              <p>I agree that under no circumstances shall Suzuki Auto Club, or any Club Officer, be liable for any loss or damage of any kind whatsoever (including consequential loss) which I or any member of my party may suffer, howsoever said loss or damage may have been caused or sustained, and whether or not this loss or damage is as a result of the negligence or breach of contract (fundamental or otherwise) or other wrongful act of the Club, or any Club Officer, or any Club member.</p>
              
              <p>Suzuki Auto Club accepts no responsibility for any deaths, injury or illness sustained or suffered by any person, or theft, loss or damage to any property occurring within or arising from a visit or visits to a Suzuki Auto Club fun days.</p>
              
              <p>This indemnity is irrevocable and shall apply to me and the members of my party for any events which I may attend or participate in, that have been arranged and organized, even informally, by the Club.</p>
              
              <p>All the information as indicated by myself is correct.</p>
              
              <p>I also accept the rules and regulations as stipulated.</p>
              
              <p>The expression "members of my party" is defined as all persons accompanying me or attending an event at my specific invitation, request or suggestion, and includes, without limitation, members of my family and any guests that accompany me.</p>
              
              <p>I clearly understand the contents of this indemnity and I am familiar with the language in which it is presented.</p>

              
            `,
              verify: `
                <h6 class="fw-bold">1. Introduction</h6>
                <p>1.1 Welcome to our website and thank you for visiting us! These terms and conditions, together with our privacy policy, govern our relationship with you in relation to this website. Please read both documents carefully.</p>
                <p>1.2 These terms and conditions apply to all websites hosted by The Good People Data Company Group Limited (registration number 2018/405198/07) and its subsidiaries (collectively "us" or "The Good People Data Company"), including the following websites; www.tgpdc.com ,and https://verify.tgpdc.com/. The Good People Data Company.</p>
                <p>1.3 By using or accessing this website, you ("you" or "the User") agree and accept (and will be deemed to have agreed and accepted) these terms and conditions with effect from the first time you access this website and each time thereafter. If you do not accept these terms and conditions, please discontinue using this website. If you continue to use the website or our services following notification of a change to the terms, the changed terms will apply to you and you will be deemed to have accepted those updated terms.</p>
                <p>1.4 We may change these terms and conditions or our privacy policy at any time by updating these terms and conditions and/or the privacy policy on our websites. You should check this page from time to time to review these terms and conditions to ensure you are satisfied with any changes.</p>
                <p>1.5 This website is currently intended for those who access it from within the Republic of South Africa. Because of this, we cannot guarantee that the site or the information thereon complies with or is appropriate for use in other places, and accordingly it should only be used by persons who access it from within the Republic of South Africa.</p>

                <h6 class="fw-bold">2. Use of the website</h6>
                <p>2.1 We have made this website available to you for your non-commercial use. It is your responsibility to acquire, maintain and licence all computer hardware, software, telecommunications facilities and internet access that are compatible with this website in order to access this website. You are solely responsible for ensuring that your computer hardware, software and data is adequately protected and backed-up.</p>
                <p>2.2 You acknowledge and warrant that –</p>
                <p>2.2.1 any instructions that you submit to The Good People Data Company through this website are valid instructions (notwithstanding that we do not have personal interaction with you) and you authorise The Good People Data Company to rely on and perform all such instructions submitted by you through this website; and</p>
                <p>2.2.2 you are of full legal age and failing which, you are emancipated or have your guardian's consent and assistance and have the required legal capacity to enter into and be bound by this agreement regarding your use of this website.</p>
                <p>2.3 We may modify, withdraw or deny access to this website at any time and shall be entitled to remove or modify any material that you send or post to or from this website.</p>
                <p>2.4 The Good People Data Company does not guarantee uninterrupted or error-free information and/or the security of this website or that of any stored visitor data.</p>
                <p>2.5 You may not copy, redistribute, republish (whether online or offline) or otherwise make the materials on this website available to anyone else without The Good People Data Company's written consent. You may, however, print, cache or download materials from this website for your personal, non-commercial use provided that -</p>
                <p>2.5.1 no materials are modified in any way;</p>
                <p>2.5.2 no graphics are used separately from accompanying text;</p>
                <p>2.5.3 our copyright and trade mark notices appear in all copies and you acknowledge this website as the source of the material;</p>
                <p>2.5.4 if you have our permission to provide these materials to another person, you ensure they are made aware of these restrictions; and</p>
                <p>2.5.5 you will remove any cached content upon request therefor by The Good People Data Company.</p>
                <p>2.6 The Good People Data Company will be entitled, without prejudice to any other rights which it may have, to lay criminal charges, suspend your access to this website and/or claim all damages, losses, liabilities and expenses incurred by The Good People Data Company from you resulting from or attributable to your use of this website and/or a breach by you of these website terms and conditions and/or applicable laws.</p>

                <h6 class="fw-bold">3. Content</h6>
                <p>3.1 This website is intended to provide general information regarding us, our products and services, and other financial information which may be of interest to you. It is not intended to provide exhaustive and complete information in relation to any subject that may be dealt with on the website.</p>
                <p>3.2 The information on this website including all research, opinions or other content is not intended to and does not constitute financial, accounting, tax, legal, investment, consulting or other professional advice or services. If the website contains statements or information which relate to projections, plans or objectives or future economic performance you should be aware that these projections are only predictions and that actual events or results may differ materially. Before making any decision or taking any action, which might affect your personal finances or business, you should take appropriate advice from a suitably qualified person.</p>
                <p>3.3 The website may include technical, typographical or other inaccuracies and you are urged to contact us to confirm all information contained on this website before placing any reliance on it. Changes are periodically made to the information on this website and these changes will be incorporated in new editions of this website. We reserve the right to alter or amend any criteria or information set out in this website without notice.</p>
                <p>3.4 We do not make representation that information and materials on this website are appropriate for use in all jurisdictions available on the web, or that transactions, securities, products, instruments or services offered on this website are available or indeed appropriate for sale or use in all jurisdictions, or by all investors or other potential clients. Those who access this website do so on their own initiative, and are therefore responsible for compliance with applicable local laws and regulations. By accessing each site, the User has agreed that he/she has reviewed the website in its entirety including any legal or regulatory terms.</p>
                <p>3.5 Any use of the information made available by means of the website is entirely at the User's own risk and it is the User's sole responsibility to evaluate the currency, accuracy, completeness and usefulness of all such information prior to relying on the same.</p>
                <p>3.6 The website may contain links to other websites, which are not under our control. The links to other sites are for your convenience and we do not accept any responsibility or liability for enabling you to link to any other website, for the contents of any other website, for the security of any other website, or for any consequence of your acting upon the contents of such website. The Good People Data Company does not endorse the views, opinions, products, services and/or recommendations included in any website linked to this website.</p>
                <p>3.7 The information in our emails may be confidential and/or legally privileged. It is intended solely for the addressee. Access to our emails by anyone else is unauthorised. If you are not the intended recipient, any disclosure, copying, distribution or any action taken or omitted in reliance on it, is prohibited and may be unlawful. Whilst all reasonable steps are taken to ensure the accuracy and integrity of information and data transmitted electronically and to preserve the confidentiality thereof, no liability or responsibility whatsoever is accepted if information or data is, for whatever reason, corrupted or does not reach its intended destination.</p>

                <h6 class="fw-bold">4. No offer</h6>
                <p>4.1 Unless you conclude a transaction with The Good People Data Company through this website, nothing on this website is intended to be nor should be construed as an offer to enter into a contractual relationship with you or anyone else, except for these terms and conditions which govern the relationship between us in relation to your use of the website. No information or content contained on this website should be taken as an offer by us.</p>
                <p>4.2 The Good People Data Company acts solely as a facilitator and/or broker in relation to the provision of the applicable financial products referred to on any of its websites. The provision of the financial products referred to on this website is subject to the registration, procedures and approvals criteria stipulated by the relevant third party product provider(s). All services offered by us are accordingly subject to completion (in the manner required) of the requisite application forms and other documentation and are governed by our relevant terms and conditions.</p>
                <p>4.3 All calculations done through the use of software or calculators supplied on these pages, including but not limited to bond repayment calculations, are guidelines only and are subject to final confirmation by the third party product provider, if and when, a transaction between the User and the third party product provider is concluded.</p>
                <p>4.4 We reserve the right to reject at our absolute discretion any application submitted for services contained on this website. If you make a contract with a third party who is named or referred to on this website, it is your responsibility to ensure that you are comfortable with the terms of that contract and to take legal advice if necessary.</p>

                <h6 class="fw-bold">5. Personal and confidential information</h6>
                <p>5.1 If you transmit, post or register any of your personal information (as that term is defined in the Protection of Personal Information Act 4 of 2013) on or through this website, you consent to the use of your personal information in accordance with our Privacy Policy available on this website.</p>
                <p>5.2 Please be aware that any unsolicited personal, confidential or proprietary information sent to us via the Internet cannot be guaranteed to remain confidential. If you need to send such information to us and are concerned about the security of this information please contact us and we will advise you of the most appropriate method of transmission.</p>
                <p>5.3 Notwithstanding any obligations that we may have in terms of applicable laws to protect or keep your personal information confidential, The Good People Data Company will be entitled to disclose your personal information to any law enforcement authorities or pursuant to a court order requiring it to so disclose your personal information for any reason, including in relation to any criminal investigation and/or in respect of any breach of these terms and conditions and/or applicable laws.</p>
                <p>5.4 Personal information that may be collected and processed utilising this website is shared transborder for purposes of safe record keeping.</p>

                <h6 class="fw-bold">6. Transmission of information</h6>
                <p>6.1 Your attention is drawn to the fact that information transmitted via the Internet is susceptible to monitoring and interception. You will bear all risk of transmitting information in this manner. Whilst The Good People Data Company endeavours to protect the User's information from scrutiny by any third party, use of the website is entirely at the User's risk. The Good People Data Company gives no warranties and will not be liable for any damage or costs the User might incur as a result of transmitting information to us through the website, use of the website and/or any unauthorised viewing of the User's information on the website by third parties.</p>
                <p>6.2 With the exception of personal and private information, any material you send or post to this website shall be considered non-proprietary and not confidential and The Good People Data Company shall not be liable for any such material that you send or post. The Good People Data Company shall be entitled to use, publish and distribute that information in any manner it sees fit.</p>
                <p>6.3 You shall not post or send to or from this website any material or software -</p>
                <p>6.3.1 in respect of which you do not have authority and/or all necessary consents, permits and/or licences;</p>
                <p>6.3.2 that is harmful including, without limitation, damaging code, trojan horses, viruses, corrupted or incorrect data and/or other potentially harmful software;</p>
                <p>6.3.3 that is unlawful, discriminatory, threatening, libellous, obscene, pornographic, defamatory, encourages conduct which incites violence, criminal acts or other unlawful conduct that would attract delictual or contractual liability, or is otherwise in contravention of the laws of the Republic of South Africa; and</p>
                <p>6.3.4 that constitutes or performs 'deep-linking', 'embedding', 'scraping' or other similar actions.</p>
                <p>6.4 We reserve the right to request independent verification of any information transmitted via e-mail.</p>

                <h6 class="fw-bold">7. Cookie and other tracking technologies</h6>
                <p>7.1 This website may use cookie and tracking technology to gather information such as browser type and operating system, tracking the number of visitors to this website, and understanding how visitors use this website in order to assist The Good People Data Company with customising this website for visitors. You can set your browser to notify you if "cookies" are to be transferred or to reject "cookies". You may also choose to not consent to us making use of "cookies" when the consent pop-up is presented to you.</p>
                <p>7.2 Cookies cannot access data on your hard drive and only store information from your browser but cookies may be tied to personal information that you submit on this website. Only aggregate cookie and tracking information may be shared with third parties.</p>
                <p>7.3 You expressly give your consent for us to monitor your internet and e-mail traffic on our website for the following purposes –</p>
                <p>7.3.1 to enhance and customise our websites for visitors;</p>
                <p>7.3.2 to ensure that users and visitors are not acting illegally, unlawfully or in breach of these terms and conditions; and</p>
                <p>7.3.3 to detect and investigate unauthorised use of this website and to maintain the integrity and security of our website and information technology systems.</p>

                <h6 class="fw-bold">8. Limitation of liability and indemnity</h6>
                <p>8.1 Whilst we try and ensure the information contained on the website is accurate and up to date, we cannot be responsible for any inaccuracies in the information. As a result, you should not rely on this information, and we recommend that you take further advice or seek further guidance before taking any action based on the information contained on this website. Our liability to you as explained below remains unaffected by this.</p>
                <p>8.2 We do not accept any liability for –</p>
                <p>8.2.1 any loss, whether direct or indirect, consequential or arising from information made available on (or by means of) the website (or any of the pages therein contained) and/or transactions or actions resulting therefrom;</p>
                <p>8.2.2 any acts or omissions resulting from your decision or opinion formed on the basis of your use of the website, it being recorded that use of this website is at your sole risk;</p>
                <p>8.2.3 any loss or damage whatsoever and howsoever arising as a result of your use of, transmission of information utilising or reliance on the information contained on the website;</p>
                <p>8.2.4 any physical loss or damage to your computer as a result of your use of this website, including any damage arising as a result of a virus; and/or</p>
                <p>8.2.5 business losses that you may incur, including but not limited to lost data, lost profits or business interruption.</p>
                <p>8.3 These terms and conditions do not exclude our liability (if any) to you for fraud and/or any matter which it would be illegal for us to exclude or to attempt to exclude our liability.</p>
                <p>8.4 You indemnify (or promise to protect) The Good People Data Company against any claim, demand, loss, damage, cost, or liability (including reasonable attorneys' fees) related to your access to or use of this website.</p>

                <h6 class="fw-bold">9. The Good People Data Company Service Waiver</h6>
                <p>9.1 The services provided by the divisions of BetterLife Origination Services Proprietary Limited, namely BetterBond and MortgageMax, are not intended to be financial, tax or legal advice and should not be construed as such. BetterBond and MortgageMax and their service partners are not financial planners, financial brokers or tax advisers. Your personal financial situation is unique, and it is your responsibility, given your financial and other individual circumstances, to use any information and advice obtained through these services appropriately and responsibly when implementing your decisions. Before making any financial decisions or implementing any financial strategy, you should obtain advice from your accountant or other financial advisor who are fully aware of your individual circumstances and never take up a home loan that will stretch your repayment capacity to its maximum.</p>
                <p>9.2 As part of services provided by The Good People Data Company, it is noted that this information collected shall, within reason be shared, for the purpose of which it was collected, with companies within or affiliated with the The Good People Data Company, financial institutions, registered credit providers as well as service providers to accomplish the objective regarding the respective intended purpose including but not limited to credit checks, fraud checks, checks with and reporting to the South African Fraud Prevention Service and for research purposes.</p>
                <p>9.3 All information regarding the financial products referred to on this website, including information in respect of the terms and conditions applicable to such financial products and services, interest rates or any other matter is intended as a guideline only and is subject to change without notice.</p>
                <p>9.4 The terms and conditions which will apply to any financial product purchased by you from any third party product provider will be the terms and conditions set out in the written document relating to such financial product finally issued by the third party product provider at the time of purchase.</p>
                <p>9.5 If the User's application for any financial product is a joint application or involves another party such as a surety, these terms and conditions will also apply to such other applicant or another party, and the User warrants that the User will inform such other applicant or another party of these terms and conditions. Services provided by The Good People Data Company make provision for more than one applicant per application. It is noted that each application is treated as confidential, however each application with co-applicants are submitted as required and it can not be guaranteed that the respective co-applicants personal information will not be disclosed to the other co-applicant during the course of the bond application process up to registration thereof.</p>

                <h6 class="fw-bold">10. Intellectual Property</h6>
                <p>10.1 The Good People Data Company is the sole and exclusive owner of all right, title and interest in and to the intellectual property rights inherent to, or contained in, the website (including but not limited to trademarks, copyrights, patents, trade names and trade secrets), as well as all proprietary information and services listed on (and/or accessed via) the website, including information and services relating to property listings, home valuations, financial wizards and underlying databases.</p>
                <p>10.2 Unauthorised usage of The Good People Data Company's intellectual property rights shall constitute an offence and shall render the offender liable to prosecution. The Good People Data Company will be entitled, without prejudice to any other rights which it may have, to claim all losses, damages and/or expenses incurred by The Good People Data Company as a result of any unauthorised use of The Good People Data Company's intellectual property rights.</p>
                <p>10.3 The User may not sub-license, resell, republish (including internet posting, electronic mailing, faxing, archiving in a public database, redistributing via a computer network, or in a printed form to third parties), reproduce, retransmit, disseminate, sell, broadcast, commercially exploit, adapt or circulate any information on (or accessed via) the website without the express written consent of The Good People Data Company (which consent may be refused at The Good People Data Company's sole and absolute discretion).</p>

                <h6 class="fw-bold">11. Abuse of website</h6>
                <p>11.1 The User may not use the website in any manner that could damage, disable, overburden, or impair any The Good People Data Company server, or the network(s) connected to any The Good People Data Company server, or interfere with any other party's use of the website.</p>
                <p>11.2 The User may not attempt to gain unauthorised access to any products, services, other accounts, computer systems or networks connected to any The Good People Data Company server or to any of the products or services through hacking, password mining or any other means.</p>
                <p>11.3 The User may not, unless authorised by The Good People Data Company in writing, use the website in any manner for the purposes of conducting any business that is in competition with the business of The Good People Data Company, including any bond origination or insurance broking business, whether by spamming, forwarding the User's contact details and other business related information to any estate agent that has listed any property on this website, or otherwise.</p>
                <p>11.4 Without limiting the rights and remedies of The Good People Data Company, The Good People Data Company may deny the User access to all or any part of this website(s) without notice if the User engages in any conduct or activities that The Good People Data Company believes in its sole and absolute discretion are contrary the import and intent of this restriction.</p>
                
                <h6 class="fw-bold">12. Queries and complaints</h6>
                <p>12.1 If you have any queries or comments in relation to the website and/or these terms and conditions or should you no longer wish to receive communications from The Good People Data Company, please contact us at this address:</p>
                <p>The Good People Data Company info@tgpdc.com</p>

                <h6 class="fw-bold">13. Disclosures required under section 43 of the Electronic and Communications Transactions Act 25 of 2002 ("ECT Act")</h6>
                <p>13.1 To the extent that section 43 of the ECT Act is or may in future become applicable, the following disclosures are made –</p>

                <div class="terms-table">
                    <table>
                        <tr>
                            <td>Name and Status</td>
                            <td>The Good People Data Company Pty (Ltd)</td>
                        </tr>
                        <tr>
                            <td>Physical address & domicilium</td>
                            <td>First Floor, 28 Fricker Road, Illovo, 2196</td>
                        </tr>
                        <tr>
                            <td>Telephone Number</td>
                            <td>011 084 1100</td>
                        </tr>
                        <tr>
                            <td>Website address</td>
                            <td>www.tgpdc.com</td>
                        </tr>
                        <tr>
                            <td>Email address</td>
                            <td>info@tgpdc.com</td>
                        </tr>
                        <tr>
                            <td>Regulatory registrations</td>
                            <td>NCRB63</td>
                        </tr>
                        <tr>
                            <td>Membership of Industry Bodies</td>
                            <td>Information Regulator</td>
                        </tr>
                    </table>
                </div>

                <p>13.2 Disclosure in terms of section 44 of the ECT Act: to the extent that section 44 of the ECT Act is applicable, you are entitled to exercise the cooling off rights set out in section 44 (read with section 42) of the ECT Act.</p>

                <h6 class="fw-bold">14. General</h6>
                <p>14.1 These terms and your use of this website are governed by and construed in accordance with laws of the Republic of South Africa and any disputes will be decided only by the courts of the Republic of South Africa. The User hereby agrees, consents and submits to the jurisdiction of the South African courts in regard to all proceedings, actions, applications or the like instituted by either party against the other, and in any way arising from these stated terms and conditions.</p>
                <p>14.2 These terms and conditions constitute the entire agreement between The Good People Data Company and the User and shall take precedence over any disclaimers and/or legal notices attached to any communications and/or postings received by The Good People Data Company from the User. Save as provided in clause 1.4, no variation of these terms and conditions shall be of any force or effect unless reduced to writing and signed by The Good People Data Company and the User or their duly authorised representatives.</p>
                <p>14.3 If any part of these terms and conditions is found by any court or other competent authority to be invalid, unlawful or unenforceable then such part will be severed from the rest of the terms and conditions which will continue to be valid and enforceable to the fullest extent permitted by law.</p>
                <p>14.4 Legal notices and other legal documentation addressed to The Good People Data Company must be sent by registered post or delivered by hand to the address nominated below, which we choose as our domicilium citandi et executandi. The User may not send any notice or legal document to The Good People Data Company by fax or SMS or email.</p>
                <p>14.5 Any failure by The Good People Data Company to exercise or enforce any right or provision shall in no way constitute a waiver of such right or provision.</p>
                <p>14.6 A certificate issued by an administrator of this website shall constitute prima facie proof of any fact related to this website, including but not limited to which version of the terms and conditions govern a particular dispute and what content was published or functionality was available on the website at a specific point in time.</p>
                `,
            wizzit: `
              <p>[Wizzit's Terms and Conditions go here]</p>
            `,
            mobiafrica: `
              <p>[Mobi Africa's Terms and Conditions go here]</p>
            `,
            vodacom: `
              <p>[Vodacom's Terms and Conditions go here]</p>
            `,
            weelee: `
              <p>[Weelee's Terms and Conditions go here]</p>
            `,
            nutun: `
              <p>[Nutun's Terms and Conditions go here]</p>
            `,
            nurturelink: `
              <p>[Nurture Link's Terms and Conditions go here]</p>
            `,
            veersgroup: `
              <p>[Veers Group's Terms and Conditions go here]</p>
            `,
            eaglecorner: `
              <p>[Eagle Corner's Terms and Conditions go here]</p>
            `
          },
        
        }

          
      },
      
      computed: {
        clientTerms() {
          return this.termsContent[this.client] || '<p>Terms and conditions not found.</p>';
        },

        formattedTerms() {
          const terms = this.termsContent[this.client] || '<p>Terms and conditions not found.</p>';
          // Split the terms into paragraphs and clean up
          return terms
            .split('</p>')
            .map(p => p.replace('<p>', '').trim())
            .filter(p => p.length > 0);
        }
      },
      methods: {

        handleScroll() {
          const element = this.$refs.termsContent;
          if (!element) return;
    
          const { scrollTop, scrollHeight, clientHeight } = element;
          const scrolledToBottom = (scrollTop + clientHeight) >= (scrollHeight * 0.9);
          
    
          if (scrolledToBottom) {
            this.hasReadTerms = true;
          }
        },

          accept() {
              this.hide();
              this.$emit('accept');
          },
          
          decline() {
              this.hide();
              this.$emit('decline');
          },
          
          show() {
              this.showModal = true;
              this.hasReadTerms = false;
              if (this.$refs.termsContent) {
                  this.$refs.termsContent.scrollTop = 0;
              }
          },
          
          hide() {
              this.showModal = false;
          },

    getIcon(text) {
      // Only return an icon if the text is wrapped in a <p> tag
      if (!text.includes('<h6')) {
        // Array of FontAwesome icons to cycle through
        const icons = [
          'fas fa-shield-alt',      // Security/Protection
          'fas fa-user-shield',     // Authority
          'fas fa-exclamation-triangle', // Warning/Danger
          'fas fa-hand-paper',      // Liability
          'fas fa-exclamation-circle', // Responsibility
          'fas fa-file-contract',   // Contract/Agreement
          'fas fa-check-circle',    // Confirmation
          'fas fa-book',            // Rules
          'fas fa-users',           // Party members
          'fas fa-check-double'     // Understanding
        ];
        
        // Generate a consistent index based on the text content
        const index = Math.abs(text.split('').reduce((acc, char) => {
          return acc + char.charCodeAt(0);
        }, 0)) % icons.length;
        
        return icons[index];
      }
      return null;
    },
  }
}