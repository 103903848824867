import AddressMatch from '@/layouts/services/address_match/AddressMatch.vue';
import AfisCriminalCheck from '@/layouts/services/afis_criminal_check/AfisCriminalCheck.vue';
import AntiMoneyLaundering from '@/layouts/services/anti_money_laundering/AntiMoneyLaundering.vue';
import CompanyVerificationPdf from '@/layouts/services/company_verification_pdf/CompanyVerificationPdf.vue';
import QualificationCheck from '@/layouts/services/qualification_check/QualificationCheck.vue';
import RefugeeVerification from '@/layouts/services/refugee_verification/RefugeeVerification.vue';
import RsaIdVerify from '@/layouts/services/rsa_id_verify/RsaIdVerify.vue';
import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";

export default {
  name: 'YourServices',
  mixins: [LOGOUT],

  data: () => ({
    currentTab: 'Approved',
    approved_requests: [],
    pending_requests: [],
    declined_requests: [],
    selected_service: null,
    service_map: {
      anti_money_laundering: AntiMoneyLaundering,
      refugee_verification: RefugeeVerification,
      company_verification_pdf: CompanyVerificationPdf,
      qualification_check: QualificationCheck,
      afis_criminal_check: AfisCriminalCheck,
      rsa_id_verify: RsaIdVerify,
      address_match: AddressMatch,
      // Add more service-component mappings here
    }

  }),
  components: {
    AntiMoneyLaundering,
    RefugeeVerification,
    CompanyVerificationPdf,
    QualificationCheck,
    AfisCriminalCheck,
    RsaIdVerify,
    AddressMatch
  },
  methods: {
    utilize_service(service) {
      this.$router.push({ name: 'utilize_service', params: { service_id : service } });
    },
    close_modal() {
      $('#service_modal').find('input, textarea, select').val('').prop('checked', false);
    },
    switchTab(tab) {
      // Destroy the existing DataTable before switching tabs
      if ($.fn.dataTable.isDataTable('#serivcesTable')) {
        $('#serivcesTable').DataTable().destroy();
      }

      // Update the current tab and reinitialize the DataTable after the content is rendered
      this.currentTab = tab;
      this.$nextTick(() => {
        this.initDataTable();
      });
    },
    initDataTable() {
      this.$nextTick(() => {
        if ($.fn.dataTable.isDataTable('#serivcesTable')) {
          $('#serivcesTable').DataTable().destroy();
        }
        $('#serivcesTable').DataTable({
          responsive: true,
        });
      });
    },
    get_services_requested() {
      const TOKEN = localStorage.getItem('token');
      const GET_SELECTED_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_requested_services/`;

      $.ajax({
        url: GET_SELECTED_SERVICES_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          if (response.redirect) {

            this.logout();
            return;
          }
          this.approved_requests = response.data.approved;
          this.declined_requests = response.data.declined;
          this.pending_requests = response.data.pending;
          this.$nextTick(() => {
            this.initDataTable();
          });
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Failed to get selected services';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },
    view_service(name) {
      if (this.service_map[name]) {
        this.selected_service = name; // Set the service name     
        $('#service_modal').modal('show');                    // Show the modal
      } else {
        console.error("Service not found");
      }
    },
    view_logs(service_id) {
      this.$router.push({ name: 'verify_logs' });
      this.$store.commit('set_service_id', service_id);
    }
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_services_requested();
  }
};