<div class="container-fluid">
  <div v-if="loading" class="text-center mt-4">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-else>
      <div class="row mt-0">
        <div class="col-md-12">
          <div class="card rounded-3 p-0 mt-5 shadow">
            <div class="card-header bg-white border-0 d-flex justify-content-between align-items-center">
              <span>Your Projects</span>
              <div>
                <button v-if="role == 'ClientAdmin'" type="button" class="btn rounded-small fw-bold btn-dark text-xxs shadow me-2" @click="clean_create_project_modal" data-bs-toggle="modal" data-bs-target="#create_project">
                  <i class="fas fa-plus"></i> New Project
                </button>
                <button type="button" class="btn rounded-small fw-bold btn-dark text-xxs shadow" data-bs-toggle="modal" data-bs-target="#view_link">
                  <i class="fa fa-arrows"></i> View Link
                </button>
              </div>
            </div>
            <div class="card-body">
                <table id="project-table" class="table-responsive w-100">
                  <thead class="text-white">
                    <tr class="bg-dark">
                      <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
                      <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7">Project Name</th>
                      <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Number of Workflows</th>
                      <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Date Created</th>
                      <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(project, index) in projects" :key="project.id">
                      <td class="text-xxs text-start">{{ index + 1 }}</td>
                      <td class="text-xxs ">{{ project.project_name }}</td>
                      <td class="text-xxs text-start">{{ project.workflow_count }}</td>
                      <td class="text-xxs">{{ formatDate(project.date_created) }}</td>
                      <td>
                        <button class="btn bg-green rounded-small secondary-text-dark fw-bold text-white text-xxs shadow btn-sm me-2" @click="viewProject(project.id)">View</button>
                        <button v-if="role == 'ClientAdmin'" class="btn btn-dark rounded-small text-white fw-bold btn-sm text-xxs shadow" @click="edit_project(project.id)">Edit</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>



<!-- Create Project Modal -->
<div class="modal fade" id="create_project" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="create_project_label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-white rounded-3">
      <div class="modal-header border-bottom-0">
        <h5 class="modal-title text-s fw-bold" id="create_project_label">Project Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group mb-3">
          <input type="text" v-model="project_name" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="Project Name">
          <small class="text-danger p-2 text-xxs fw-bold d-none project-name-messages">Please enter a project name</small>
          <small class="text-danger p-2 text-xxs fw-bold d-none error-messages"></small>
        </div>
        <div class="form-group mb-3">
          <textarea v-model="project_description" class="form-control h-100 text-xs rounded-small shadow-sm" placeholder="Project Description" rows="5" ></textarea>
          <small class="text-danger p-2 text-xxs fw-bold d-none project-description-messages">Please enter a project description</small>
        </div>
        <button type="button" class="btn bg-green float-end shadow secondary-text-dark text-xxs fw-bold" @click="create_project">Submit</button>
      </div>
    </div>
  </div>
</div>

<!-- Copy Link Modal -->
<div class="modal fade" id="view_link" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="create_link_label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-white rounded-3">
      <div class="modal-header border-bottom-0">
        <h5 class="modal-title text-s fw-bold" id="create_project_label">Onboarding Link</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group mb-3">
          <input type="text" v-model="onboarding_link" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="Link" disabled>
          <small class="text-danger p-2 text-xxs fw-bold d-none project-name-messages"></small>
          <small class="text-danger p-2 text-xxs fw-bold" v-if="error_messages">{{ error_messages }}</small>
          <small class="text-succes p-2 text-xxs fw-bold" v-if="success_message">{{ success_message }}</small>
        </div>
        <button type="button" class="btn bg-green float-end shadow text-xxs fw-bold" @click="copy_onboarding_link">Copy link</button>
      </div>
    </div>
  </div>
</div>

<!-- Edit Project Modal -->
<div class="modal fade" id="edit_project_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="edit_project_modal_label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-white rounded-3">
      <div class="modal-header border-bottom-0">
        <h5 class="modal-title text-s fw-bold" id="edit_project_modal_label">Edit Project Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group mb-3">
          <input type="text" v-model="project_name" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="Project Name">
          <small class="text-danger p-2 text-xxs fw-bold d-none project-name-messages">Please enter a project name</small>
          <small class="text-danger p-2 text-xxs fw-bold d-none error-messages"></small>
        </div>
        <div class="form-group mb-3">
          <textarea v-model="project_description" class="form-control text-xs rounded-small shadow-sm" placeholder="Project Description"></textarea>
          <small class="text-danger p-2 text-xxs fw-bold d-none project-description-messages">Please enter a project description</small>
        </div>
        <button type="button" class="btn bg-green float-end shadow secondary-text-dark text-xxs fw-bold" @click="update_project">Save Changes</button>
      </div>
    </div>
  </div>
</div>
