export default {
  name: 'MarketingConsentModal',
  props: {
    client: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hasReadConsent: false,
      showModal: false,
      marketingContent: {  
        suzuki: `
          <p>I hereby voluntarily provide my consent for Suzuki Bassonia to contact me directly for marketing and sales purposes. I acknowledge that this may include communication via phone calls, emails, SMS, and other methods of electronic communication as per defined by POPIA.
          I understand that my personal information will be handled in accordance with applicable data protection laws and that I have the right to withdraw my consent at any time by notifying Suzuki Bassonia in writing.
          </p>`,
        verify: `
          <p>[verify's Marketing consent go here]</p>
        `,
        wizzit: `
          <p>[Wizzit's Marketing consent go here]</p>
        `,
        mobiafrica: `
          <p>[Mobi Africa's Marketing consent go here]</p>
        `,
        vodacom: `
          <p>[Vodacom's Marketing consent go here]</p>
        `,
        weelee: `
          <p>[Weelee's Marketing consent go here]</p>
        `,
        nutun: `
          <p>[Nutun's Marketing consent go here]</p>
        `,
        nurturelink: `
          <p>[Nurture Link's Marketing consent go here]</p>
        `,
        veersgroup: `
          <p>[Veers Groups's Marketing consent go here]</p>
        `,
        eaglecorner: `
          <p>[Eagle Corner's Marketing consent go here]</p>
        `
      }
    }
  },
  computed: {
    formattedConsent() {
      const content = this.marketingContent[this.client] || '<p>Marketing consent not found.</p>';
      return content
        .split('</p>')
        .map(p => p.replace('<p>', '').trim())
        .filter(p => p.length > 0);
    }
  },

methods: {
    handleScroll() {
        const element = this.$refs.consentContent; 
        if (!element) return;

        const { scrollTop, scrollHeight, clientHeight } = element;
        const scrolledToBottom = (scrollTop + clientHeight) >= (scrollHeight * 0.9);

        if (scrolledToBottom) {
            this.hasReadConsent = true; 
        }
    },
    
    show() {
        this.showModal = true;
        this.hasReadConsent = false; 
        if (this.$refs.consentContent) { 
            this.$refs.consentContent.scrollTop = 0;
        }
    },
    
    hide() {
        this.showModal = false;
    },
    
    accept(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.hide();
        this.$emit('accept');
        return false;
    },
    
    decline(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.hide();
        this.$emit('decline');
        return false;
    }
}
}