<div class="view-workflow-customers p-0 px-4 py-2">
    <div class="container-fluid">
        <div class="col-sm-12 p-2 mt-3">
            <div class="row g-4">
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <icon-card title="Total Applications" :count="total_applications" icon_class="fas fa-file-alt" />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <icon-card title="Applications Completed" :count="completed_applications" icon_class="fas fa-check-circle" />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <icon-card title="Applications Incomplete" :count="incomplete_applications" icon_class="fas fa-exclamation-circle" />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <icon-card title="Total Customers" :count="total_customers" icon_class="fas fa-users" />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <icon-card title="Total Services" :count="total_services" icon_class="fas fa-cogs" />
                </div>
            </div>
        </div>
    </div>

    <div class="card rounded-3 mt-4 p-0 shadow-md">
        <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="title-info p-2">
            <label class="d-flex align-items-center mb-0">
                <i class="fas fa-users text-primary fa-xl me-2"></i>
                <span class="fw-bold mr-1">Workflow Customers</span>
            </label>
            </div>
            <div class="search-container">
            <div class="input-group">
                <span class="input-group-text bg-transparent border-0">
                <i class="fas fa-search"></i>
                </span>
                <input type="text" class="form-control form-control-sm" v-model="customers_search_query"
                placeholder="Search Customers...">
            </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-hover table-sm text-xs">
            <thead>
                <tr>
                <th class="text-xs fw-bold">ID</th>
                <th class="text-xs fw-bold">Customer Name</th>
                <th class="text-xs fw-bold">Customer Email</th>
                <th class="text-xs fw-bold">Date Joined</th>
                <th class="text-xs fw-bold">Last Login</th>
                <th class="text-xs fw-bold text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(customer, index) in paginated_customers" :key="index">
                <td class="py-2">{{ customer.id }}</td>
                <td class="py-2">{{ customer.first_name }} {{ customer.last_name }}</td>
                <td class="py-2">{{ customer.email }}</td>
                <td class="py-2">{{ customer.date_joined }}</td>
                <td class="py-2">{{ customer.last_login }}</td>
                <td class="py-2 text-center">
                    <button class="btn btn-bg-green btn-sm rounded-small" @click="view_customer(customer.id)">
                        <i class="fa fa-eye text-xs p-0 m-0 me-1"></i>
                        <span class="mb-2 text-s-xs">View</span>
                    </button>
                </td>
                </tr>
            </tbody>
            </table>
        </div>
        <!-- Customers Empty State -->
        <div v-if="customers.length === 0" class="text-center py-5 my-4 text-muted">
            <i class="fas fa-users fa-3x mb-3 text-secondary"></i>
            <h6>No customers found</h6>
            <p class="text-xs text-muted">There are no workflow customers available at this time.</p>
        </div>
        <!-- Customers Pagination -->
        <div class="d-flex justify-content-between align-items-center mt-3" v-if="customers.length > 0">
            <div class="text-xs text-muted">
            Showing {{ (customers_current_page - 1) * items_per_page + 1 }} to
            {{ Math.min(customers_current_page * items_per_page, customers.length) }}
            of {{ customers.length }} entries
            </div>
            <div class="d-flex align-items-center">
            <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_customers_page"
                :disabled="customers_current_page === 1">
                <i class="fas fa-arrow-left"></i>
            </button>
            <span class="text-xs mx-2">Page {{ customers_current_page }} of {{ customers_total_pages }}</span>
            <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_customers_page"
                :disabled="customers_current_page === customers_total_pages">
                <i class="fas fa-arrow-right"></i>
            </button>
            </div>
        </div>
        </div>
    </div>
</div>