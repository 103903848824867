<header>
  <nav class="navbar-landing">
    <img src="@/assets/images/Suzuki_logo.png" alt="Logo" class="logo sidebar-logo img-fluid" />
  </nav>
</header>

<section class="hero-section-landing">
  <img src="@/assets/images/Suzuki_dashboard.png" alt="Banner" class="hero-image" style="height: auto;" />
  <div class="hero-content">
    <button class="hero-button fw-bold" @click="next_to_register">Get Started</button>
  </div>
</section>