import { capitalize, createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dotenv from "dotenv";
import {
  defineComponents,
  DocumentReaderService,
} from "@regulaforensics/vp-frontend-document-components";

dotenv.config();

const app = createApp(App);
app.use(router);
app.use(store);

app.mount("#app");

const domain = window.location.hostname;
let client;

if (domain.includes("verify")) {
  client = "verify";
} else if (domain.includes("wizzit")) {
  client = "wizzit";
} else if (domain.includes("mobiafrica")) {
  client = "mobiafrica";
} else if (domain.includes("vodacom")) {
  client = "vodacom";
} else if (domain.includes("weelee")) {
  client = "weelee";
} else if (domain.includes("nutun")) {
  client = "nutun";
} else if (domain.includes("nurturelink")) {
  client = "nurturelink";
} else if (domain.includes("suzuki")) {
  client = "suzuki";
} else if (domain.includes("veersgroup")) {
  client = "veersgroup";
} else if (domain.includes("eaglecorner")) {
  client = "eaglecorner";
}

// Set client-specific data attribute
if (client) {
  document.documentElement.setAttribute("data-client", client);
}

const logos = {
    verify: require("@/assets/images/new_black_logo2.svg"),
    wizzit: require("@/assets/images/Wizzit-Logo-Red.png"),
    mobiafrica: require("@/assets/images/mobi_logo.png"),
    vodacom: require("@/assets/images/Vodacom_logo.jpg"),
    weelee: require("@/assets/images/Weelee_logo.png"),
    nutun: require("@/assets/images/Nutun_logo.png"),
    nurturelink: require("@/assets/images/Nurturelink_logo.png"),
    suzuki: require("@/assets/images/Suzuki_logo.png"),
    veersgroup: require("@/assets/images/VeersGroup_logo.jpg"),
    eaglecorner: require("@/assets/images/EagleCorner_logo.png"),
};

app.config.globalProperties.$client = capitalize(client);

app.config.globalProperties.$clientLogo =
  logos[client] || require("@/assets/images/new_black_logo2.png");

app.config.globalProperties.$clientSideBarIcon =
  client === "verify" || client === "mobiafrica"
    ? {
        Dashboard: require("@/assets/images/green_layers.png"),
        Services: require("@/assets/images/green_services-icon.png"),
        Assets: require("@/assets/images/green_form_management.png"),
        Questions: require("@/assets/images/green_questions.png"),
        Forms: require("@/assets/images/green_form_management.png"),
        Templates: require("@/assets/images/green_template_management.png"),
        InstantVerify: require("@/assets/images/green_verify-icon.png"),
        // Add all other green icons here
      }
    : client === "wizzit" ||
      client === "vodacom" ||
      client === "weelee" ||
      client === "nutun" ||
      client === "nurturelink" ||
      client === "suzuki" ||
      client === "veersgroup" ||
      client === "eaglecorner"
    ? {
        Dashboard: require("@/assets/images/layers.png"),
        Services: require("@/assets/images/services-icon.png"),
        Assets: require("@/assets/images/form_management.png"),
        Questions: require("@/assets/images/questions.png"),
        Forms: require("@/assets/images/form_management.png"),
        Templates: require("@/assets/images/template_management.png"),
        InstantVerify: require("@/assets/images/verify-icon.png"),
        // Add all other red icons here
      }
    : {};

app.config.globalProperties.$clientServiceIcon = {
  AvailableServices: require("@/assets/side_bar_icons/services.svg"),
};

app.config.globalProperties.$clientImage =
  client === "verify" || client === "mobiafrica"
    ? {
        ThankYou: require("@/assets/images/workflow_done.png"),
        // Add all other green icons here
      }
    : client === "wizzit" ||
      client === "vodacom" ||
      client === "weelee" ||
      client === "nutun" ||
      client === "nurturelink" ||
      client === "suzuki" ||
      client === "veersgroup" ||
      client === "eaglecorner"
    ? {
        ThankYou: require("@/assets/images/red_workflow.png"),
        // Add all other red icons here
      }
    : {};

window.RegulaDocumentSDK = new DocumentReaderService();

window.RegulaDocumentSDK.recognizerProcessParam = {
  processParam: {
    scenario: "MrzAndLocate",
  },
};
window.RegulaDocumentSDK.imageProcessParam = {
  processParam: {
    scenario: "MrzAndLocate",
  },
};

defineComponents().then(() =>
  window.RegulaDocumentSDK.initialize({ license: process.env.BASE64_KEY })
);

defineComponents();

// production: Domain name licensing is used, so don't add the license to the project source code:
// https://docs.regulaforensics.com/develop/doc-reader-sdk/overview/licensing/#domain-name-licensing
