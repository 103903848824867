import { validateToken } from '@/utils/auth';
import { validate_id, allow_num } from "@/utils/validations";

export default {
    name: 'RsaIdVerify',
    data: () => ({
        id_number: '',
        first_names: '',
        last_name: '',
        error: '',
        json_data: {},
        dataTable: null
    }),
    methods: {
        format_label(key) {
            if (typeof key !== 'string' || !key.trim()) return '';

            return key
                .replace(/[_-]/g, ' ')
                .replace(/[^a-zA-Z0-9]/g, ' ') // Replace non-alphanumeric characters with a space
                .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters following a lowercase letter
                .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Add space between consecutive uppercase letters followed by a lowercase
                .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                .trim() // Trim leading/trailing spaces
                .split(' ') // Split the string into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
                .join(' '); // Join the words back into a single string
        },
        preprocess_data(data, parent_key = '') {
            let result = [];

            // Helper function to format keys for uniqueness and clarity
            let format_key = (key, parent) => (parent ? `${parent}.${key}` : key);

            // Process each key/value pair
            Object.entries(data).forEach(([key, value]) => {
                let current_key = format_key(key, parent_key);

                if (Array.isArray(value)) {
                    // For arrays
                    result.push({
                        type: 'label',
                        key: current_key,
                        label: key,
                        value: null, // No direct value, just a label for the array
                    });

                    value.forEach((item, index) => {
                        if (typeof item === 'object' && item !== null) {
                            // Recursively process nested objects or arrays within arrays
                            result.push(...this.preprocess_data(item, `${current_key}[${index}]`));
                        } else {
                            result.push({
                                type: 'input',
                                key: `${current_key}[${index}]`,
                                label: `${key} [${index}]`,
                                value: item,
                            });
                        }
                    });
                } else if (typeof value === 'object' && value !== null) {
                    result.push({
                        type: 'heading',
                        key: current_key,
                        label: key,
                    });
                    // Recursively process nested objects
                    result.push(...this.preprocess_data(value, current_key));
                } else {
                    result.push({
                        type: 'input',
                        key: current_key,
                        label: key,
                        value: value,
                    });
                }
            });
            return result;
        },

        allow_num(event) {
            this.id_number = allow_num(event);
        },
        handle_id_input(id_number) {
            this.id_number = id_number;
        },
        submit_form() {
            if (!this.id_number.trim()) {
                $('.id-message').removeClass('d-none');
                setTimeout(() => {
                    $('.id-message').addClass('d-none');
                }, 3000);
                return false;
            }
            if (!this.first_names.trim()) {
                $('.first-name-message').removeClass('d-none');
                setTimeout(() => {
                    $('.first-name-message').addClass('d-none');
                }, 3000);
                return false;
            }
            if (!this.last_name.trim()) {
                $('.last-name-message').removeClass('d-none');
                setTimeout(() => {
                    $('.last-name-message').addClass('d-none');
                }, 3000);
                return false;
            }
            if (!validate_id(this.id_number)) {
                $('.invalid-message').removeClass('d-none');
                setTimeout(() => {
                    $('.invalid-message').addClass('d-none');
                }, 3000);
                return false;
            }

            this.rsa_id_verify_api();
        },
        rsa_id_verify_api() {
            const TOKEN = localStorage.getItem('token');
            const RSA_ID_VERIFY_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/rsa_id_verify/`;

            this.error = '';

            $.ajax({
                url: RSA_ID_VERIFY_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    id_number: this.id_number,
                    first_names: this.first_names,
                    last_name: this.last_name
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading(),
                    });
                },
                success: (response) => {
                    Swal.close();
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        confirmButtonText: 'OK'
                    })
                    this.json_data = response.result || {};
                    $('#verification_results').modal('show');
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';

                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                        $('.error-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.error-message').text('').addClass('d-none');
                        }, 3000);
                    } else {
                        $('.error-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.error-message').text('').addClass('d-none');
                        }, 3000);
                    }
                }
            });
        },
    },
    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
    },
};