
        <h5 class="text-s text-center"><strong>Refugee Verification</strong></h5>
            <div class="row mt-2 mb-3">
                <div class="col text-start">
                <label class="form-label text-xs">Refugee Number <span class="text-danger fw-bold">*</span></label>
                <input v-model="refugee_number" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg., EUFSOM010112818">
                </div>
            </div>
            <div class="col text-end">
                <button type="submit" class="btn bg-green btn-sm text-xs shadow rounded-small mt-2 fw-bold" @click.prevent="refugee_verification_api">Submit</button>
            </div>

            <div class="modal" tabindex="-1" id="verification_results">
                <div class="modal-dialog modal-fullscreen">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Verification Result</h5>
                    </div>
                    <div class="modal-body">
                        <div v-if="Object.keys(json_data).length > 0">
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <template v-for="(item, index) in preprocess_data(json_data)" :key="index">
                                            <tr v-if="item.type === 'heading'" class="table-secondary">
                                                <th class="py-2 text-s">
                                                    {{ format_label(item.label) }}
                                                </th>
                                            </tr>
                                            <tr v-if="(item.type === 'input' || item.type === 'label')">
                                                <td>
                                                    <div class="row col-12">
                                                        <div class="col-3">
                                                            <span class="fw-bold text-xs me-2">{{ format_label(item.label) }}</span>
                                                        </div>
                                                        <div class="col-9">
                                                            <span v-if="item.value" class="text-xs">{{ item.value }}</span>
                                                            <span v-else class="text-muted text-xs">None</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <router-link :to="{ name: 'services' }">
                            <button class="btn bg-green btn-sm text-white text-xs text-end  rounded-small mt-3" data-bs-dismiss="modal">Back</button>
                        </router-link>
                    </div>
                  </div>
                </div>
              </div>
