export default {
    name: 'AfisForm',
    data() {
        return {
            form: {
                first_names: '',
                surname: '',
                id_number: '',
                phone_number: '',
                email_address: ''
            }
        };
    },
    emits: ['service_status_complete'],
    methods: {
        handle_submit() {
            if (!this.form.first_names || !this.form.surname || !this.form.id_number || !this.form.phone_number || !this.form.email_address) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'All fields are required.',
                    confirmButtonText: 'OK'
                });
                return;
            }
            const TOKEN = localStorage.getItem('token');
            const AFIS_CRIMINAL_CHECK_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/afis_criminal_check/`;

            this.error = '';

            $.ajax({
                url: AFIS_CRIMINAL_CHECK_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    first_names: this.form.first_names,
                    surname: this.form.surname,
                    id_number: this.form.id_number,
                    phone_number: this.form.phone_number,
                    email_address: this.form.email_address
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading(),
                    });
                },
                success: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        text: 'AFIS Check ran successfully',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    }).then(() => {
                        this.$emit('service_status_complete');
                    });
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';

                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        }
    }
};