<div class="container-fluid bg-white vh-100 p-0">
  <ClientLogo />
  <BreadCrumb :label="service_name" />

  <div v-if="is_form_data_ready" class="d-flex flex-column justify-content-center align-items-center min-vh-50">
    <div v-for="(field, index) in form_data" :key="index" class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-2">
      <label :for="field.question" class="fw-bold text-xs text-start d-block mb-2">
        {{ field.question }} <span v-if="field.is_required" class="text-danger">*</span>
      </label>
      <div class="form-group mt-2">
        <div v-if="field.has_text || field.has_number">
          <input :type="field.question_type"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            v-model="responses[index].value" :placeholder="field.question" :required="field.is_required" />
          <div>
            <small :id="`error-${field.id}`" class=" text-danger fw-bold text-xs d-none">
              Please fill in this field
            </small>
          </div>
        </div>

        <div v-if="field.has_multi_selection">
          <div v-for="option in field.options" :key="option.option" class="form-check">
            <input type="checkbox"
              class="form-check-input mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
              v-model="responses[index].value" :value="option.option" :id="`checkbox_${index}_${option.option}`"
              @change="handle_multiple_selection($event, field.id, option.id)" />
            <label :for="`checkbox_${index}_${option.option}`" class="form-check-label text-xs ms-1">
              {{ option.option }}
            </label>
          </div>
          <div>
            <small :id="`error-${field.id}`" class=" text-danger fw-bold text-xs d-none">
              Please fill in this field
            </small>
          </div>
        </div>

        <div v-if="field.has_checkbox">
          <div v-for="option in field.options" :key="option.option" class="form-check">
            <input type="radio"
              class="form-check-input mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
              v-model="responses[index].value" :value="option.option" :id="`checkbox_${index}_${option.option}`"
              @change="get_follow_up_question(field.id, option.id)" />
            <label :for="`checkbox_${index}_${option.option}`" class="form-check-label text-xs ms-1">
              {{ option.option }}
            </label>
          </div>
          <div>
            <small :id="`error-${field.id}`" class=" text-danger fw-bold text-xs d-none">
              Please fill in this field
            </small>
          </div>
        </div>

        <div v-if="field.has_selection">
          <SearchSelect
            v-if="field.options.length > 5"
            v-model="responses[index].value"
            @change="get_follow_up_question(field.id, $event.target.value)"
            :id="`${field_index}`"
            :options="field.options.map(option => ({ value: option.option, text: option.option }))"
            :placeholder="field.question"
          />
          <select
            v-else
            class="form-select form-select-sm mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            v-model="responses[index].value" @change="get_follow_up_question(field.id, $event.target.value)">
            <option value="" disabled hidden>{{ field.question }}</option>
            <option v-for="option in field.options" :key="option.id" :value="option.option">
              {{ option.option }}
            </option>
          </select>
          <div>
            <small :id="`error-${field.id}`" class=" text-danger fw-bold text-xs d-none">
              Please fill in this field
            </small>
          </div>
        </div>

        <div v-show="field.has_file">
          <input 
            type="file"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            accept=".pdf"
            @change="on_file_change($event, field.id, false)"
            ref="file_input"
            :name="`file_${field.id}`"/>
          <div>
            <small :id="`error-${field.id}`" class=" text-danger fw-bold text-xs d-none">
              Please fill in this field
            </small>
          </div>
        </div>

        <div v-if="field.has_date">
          <input type="date" class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            v-model="responses[index].value" :required="field.is_required" />
          <div>
            <small :id="`error-${field.id}`" class=" text-danger fw-bold text-xs d-none">
              Please fill in this field
            </small>
          </div>
        </div>
      </div>

      <div v-for="(fields, group_index) in duplicate_fields" :key="group_index"
        class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
        <hr class="w-75 mx-auto mb-4" />

        <div v-for="(field, field_index) in fields" :key="`dup_${group_index}_${field_index}`" class="form-group mt-3">
          <label :for="field.question" class="fw-bold text-xs text-start d-block">
            {{ field.question }} <span v-if="field.is_required" class="text-danger">*</span>
          </label>
          <input v-if="field.has_text || field.has_number" :type="field.question_type"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            v-model="field.value" :placeholder="field.question" :required="field.is_required" />
          <div>
            <small :id="`#error-duplicate-${groupIndex}-${field.id}`" class=" text-danger fw-bold text-xs d-none">
              Please fill in this field
            </small>
          </div>

          <div v-if="field.has_multi_selection">
            <div v-for="option in field.options" :key="option.option" class="form-check">
              <input type="checkbox" class="form-check-input rounded-small shadow-sm" v-model="responses[index].value"
                :value="option.option" :id="`checkbox_${index}_${option.option}`" />
              <label :for="`checkbox_${index}_${option.option}`" class="fw-bold text-xs text-start d-block">
                {{ option.option }}
              </label>
            </div>
            <div>
              <small :id="`#error-duplicate-${groupIndex}-${field.id}`" class=" text-danger fw-bold text-xs d-none">
                Please fill in this field
              </small>
            </div>
          </div>

          <div v-if="field.has_checkbox">
            <div v-for="option in field.options" :key="option.option" class="form-check">
              <input type="radio"
                class="form-check-input mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                v-model="field.value" :value="option.option" :id="`checkbox_${field_index}_${option.option}`" />
              <label :for="`checkbox_${field_index}_${option.option}`" class="form-check-label text-xs">
                {{ option.option }}
              </label>
            </div>
            <div>
              <small :id="`#error-duplicate-${groupIndex}-${field.id}`" class=" text-danger fw-bold text-xs d-none">
                Please fill in this field
              </small>
            </div>
          </div>

          <div v-if="field.has_selection">
            <SearchSelect
              v-if="field.options.length > 5"
              v-model="field.value"
              :id="`${field_index}`"
              :options="field.options.map(option => ({ value: option.option, text: option.option }))"
              :placeholder="field.question"
            />
            <select
              v-else
              class="form-select form-select-sm mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
              v-model="field.value" :id="`${field_index}`">
              <option value="" disabled hidden>{{ field.question }}</option>
              <option v-for="option in field.options" :key="option.option" :value="option.option">
                {{ option.option }}
              </option>
            </select>
            <div>
              <small :id="`#error-duplicate-${groupIndex}-${field.id}`" class=" text-danger fw-bold text-xs d-none">
                Please fill in this field
              </small>
            </div>
          </div>

          <div v-if="field.has_file">
            <input type="file"
              class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
              @change="on_file_change($event, field_index)" ref="file_input" />
            <div>
              <small :id="`#error-duplicate-${groupIndex}-${field.id}`" class=" text-danger fw-bold text-xs d-none">
                Please fill in this field
              </small>
            </div>
          </div>

          <div v-if="field.has_date">
            <input type="date"
              class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
              v-model="field.value" :required="field.is_required" />
            <div>
              <small :id="`#error-duplicate-${groupIndex}-${field.id}`" class=" text-danger fw-bold text-xs d-none">
                Please fill in this field
              </small>
            </div>
          </div>
        </div>
      </div>

      <!-- Follow-up Questions Section -->
      <div v-if="follow_up_questions.length > 0" class="mt-4">
        <div v-for="follow_up_question in follow_up_questions" :key="follow_up_question.id"
          v-show="follow_up_question.parent_id === field.id" class="mb-4">
          <label class="fw-bold text-xs text-start d-block mb-2">
            {{ follow_up_question.question }}
            <span v-if="follow_up_question.is_required" class="text-danger">*</span>
          </label>

          <div class="form-group mt-2">
            <!-- Text or Number input -->
            <div v-if="follow_up_question.has_text">
              <input type="text"
                class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                v-model="follow_up_question.value" :placeholder="follow_up_question.question"
                :required="follow_up_question.is_required"
                @input="update_follow_up_response(follow_up_question.id, follow_up_question.value)" />
              <div>
                <small :id="`error-followup-${follow_up_question.id}`" class=" text-danger fw-bold text-xs d-none">
                  Please fill in this field
                </small>
              </div>
            </div>

            <div v-if="follow_up_question.has_number">
              <input type="number"
                class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                v-model="follow_up_question.value" :placeholder="follow_up_question.question"
                :required="follow_up_question.is_required"
                @input="update_follow_up_response(follow_up_question.id, follow_up_question.value)" />
              <div>
                <small :id="`error-followup-${follow_up_question.id}`" class=" text-danger fw-bold text-xs d-none">
                  Please fill in this field
                </small>
              </div>
            </div>

            <!-- Multi-selection checkboxes -->
            <div v-if="follow_up_question.has_multi_selection">
              <div v-for="option in follow_up_question.options" :key="`${follow_up_question.id}_${option.option}`"
                class="form-check">
                <input 
                  type="checkbox"
                  class="form-check-input mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                  :checked="follow_up_responses.find(r => r.id === follow_up_question.id)?.value?.includes(option.option)"
                  :value="option.option"
                  :id="`follow_up_checkbox_${follow_up_question.id}_${option.option}`"
                  @change="handle_followup_multiple_selection($event, follow_up_question.id, option.option)"/>
                <label :for="`follow_up_checkbox_${follow_up_question.id}_${option.option}`"
                  class="form-check-label text-xs ms-1">
                  {{ option.option }}
                </label>
              </div>
              <div>
                <small :id="`error-followup-${follow_up_question.id}`" class=" text-danger fw-bold text-xs d-none">
                  Please fill in this field
                </small>
              </div>
            </div>

            <!-- Single-selection radio buttons -->
            <div v-if="follow_up_question.has_checkbox">
              <div v-for="option in follow_up_question.options" :key="`${follow_up_question.id}_${option.option}`"
                class="form-check">
                <input type="radio"
                  class="form-check-input mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                  v-model="follow_up_question.value" :value="option.option"
                  :id="`follow_up_radio_${follow_up_question.id}_${option.option}`"
                  @input="update_follow_up_response(follow_up_question.id, follow_up_question.value)" />
                <label :for="`follow_up_radio_${follow_up_question.id}_${option.option}`"
                  class="form-check-label text-xs ms-1">
                  {{ option.option }}
                </label>
              </div>
              <div>
                <small :id="`error-followup-${follow_up_question.id}`" class=" text-danger fw-bold text-xs d-none">
                  Please fill in this field
                </small>
              </div>
            </div>

            <!-- Dropdown selection -->
            <div v-if="follow_up_question.has_selection">
              <SearchSelect
                v-if="follow_up_question.options.length > 5"
                v-model="follow_up_question.value"
                @input="update_follow_up_response(follow_up_question.id, $event.target.value)"
                :options="follow_up_question.options.map(option => ({ value: option.option, text: option.option }))"
                :placeholder="follow_up_question.question"
              />
              <select
                v-else
                class="form-select form-select-sm mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                v-model="follow_up_question.value"
                @input="update_follow_up_response(follow_up_question.id, $event.target.value)">
                <option value="" disabled hidden>{{ follow_up_question.question }}</option>
                <option v-for="option in follow_up_question.options" :key="`${follow_up_question.id}_${option.option}`"
                  :value="option.option">
                  {{ option.option }}
                </option>
              </select>
              <div>
                <small :id="`error-followup-${follow_up_question.id}`" class=" text-danger fw-bold text-xs d-none">
                  Please fill in this field
                </small>
              </div>
            </div>

            <!-- File upload -->
            <div v-if="follow_up_question.has_file">
              <input
                type="file"
                class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                @change="($event) => on_file_change($event, follow_up_question.id, true)"
                :name="`followup_file_${follow_up_question.id}`"
                accept=".pdf"
                :ref="`follow_up_file_input_${follow_up_question.id}`" />
              <div>
                <small :id="`error-followup-${follow_up_question.id}`" class=" text-danger fw-bold text-xs d-none">
                  Please fill in this field
                </small>
              </div>
            </div>

            <!-- Date input -->
            <div v-if="follow_up_question.has_date">
              <input type="date"
                class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                v-model="follow_up_question.value" :required="follow_up_question.is_required"
                @input="update_follow_up_response(follow_up_question.id, follow_up_question.value)" />
              <div>
                <small :id="`error-followup-${follow_up_question.id}`" class=" text-danger fw-bold text-xs d-none">
                  Please fill in this field
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Rest of your existing template (duplicate fields, buttons, etc.) -->
    <div v-for="(fields, group_index) in duplicate_fields" :key="group_index"
      class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
      <!-- Your existing duplicate fields code -->
    </div>

    <div v-if="service_type === 'multi flex form'" class="d-flex justify-content-end mb-3 ms-5r">
      <button class="btn btn-sm bg-green text-white rounded-circle shadow-sm fw-bold me-1"
        v-if="duplicate_fields.length > 0" @click="remove_fields(group_index)" title="Remove">
        <i class="fa fa-trash"></i>
      </button>

      <button class="btn btn-sm bg-green text-white rounded-circle shadow-sm fw-bold" @click.prevent="add_more_fields"
        title="Add More">
        <i class="fa fa-plus"></i>
      </button>
    </div>

    <div class="text-center mb-3 mt-5">
      <button class="btn btn-sm bg-green secondary-text-dark rounded-small fw-bold text-xs shadow-sm"
        @click.prevent="submit_form">
        Continue
      </button>
    </div>
  </div>
</div>
