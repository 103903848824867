import IconCard from '@/components/IconCard.vue';
import UsageComponent from './elements/UsageComponent.vue';
import OverviewComponent from './elements/OverviewComponent.vue';

export default {
    name: 'ClientService',
    components: {
        IconCard,
        UsageComponent,
        OverviewComponent
    },
    computed: {
        client_id() {
            return this.$route.params.client_id;
        },
        service_id() {
            return this.$route.params.service_id;
        }
    },
    data() {
        return {
            client_name: '',
            revenue_this_month: 0,
            cost_this_month: 0,
            total_service_ran: 0,
            client_count: 0,
            workflow_count: 0,
            asset_count: 0
        };
    },
    mounted() {
        this.fetch_general_information();
    },
    methods: {
        assign_suffix_to_amount(amount){
            if (amount >= 1000000000) {
                return `${Number(amount / 1000000000).toFixed(2)}B`;
            } else if (amount >= 1000000) {
                return `${Number(amount / 1000000).toFixed(2)}M`;
            } else if (amount >= 1000) {
                return `${Number(amount / 1000).toFixed(2)}k`;
            } else {
                return Number(amount).toFixed(2);
            }
        },
        fetch_general_information() {
            const TOKEN = localStorage.getItem('token');
            const GET_GENERAL_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/service_management/general_information/?client_id=${this.client_id}&service_id=${this.service_id}`;

            $.ajax({
                url: GET_GENERAL_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    var general_data = response.data
                    
                    this.workflow_count = general_data.total_workflows || 0;
                    this.asset_count = general_data.total_assets || 0;
                    this.revenue_this_month = `R ${this.assign_suffix_to_amount(general_data.revenue_this_month || 0)}`;
                    this.cost_this_month = `R ${this.assign_suffix_to_amount(general_data.costs_this_month || 0)}`;
                    this.total_service_ran = general_data.total_services_ran || 0;
                    this.client_count = general_data.total_clients || 0;

                },
                error: () => {
                    Swal.close();
                    let error_message = 'Failed to get service information';

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
    }
};