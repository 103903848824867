
<h5 class="text-s text-center"><strong>Anti Money Laundering</strong></h5>
<div class="row mt-2 mb-2">
    <div class="col-md-6 text-start fw-bold">
    <label class="form-label text-xs">First Name <span class="text-danger fw-bold">*</span></label>
    <input v-model="first_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg. Joe">
    </div>
    <div class="col-md-6 text-start fw-bold">
    <label class="form-label text-xs">Last Name <span class="text-danger fw-bold">*</span></label>
    <input v-model="last_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="eg. Doe">
    </div>
</div>
<div class="row mt-2 mb-2">
    <div class="col-md-6 text-start">
        <label class="form-label text-xs fw-bold">Date of Birth <span class="text-danger fw-bold">*</span></label>
        <input v-model="date_of_birth" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="YYyymmdd">
    </div>
    <div class="col-md-6 text-start">
        <label class="form-label text-xs fw-bold">Gender <span class="text-danger fw-bold">*</span></label>
        <select 
          v-model="gender" 
          class="form-select form-select-sm text-xxs rounded-small shadow-sm w-100" 
          id="gender" 
          required
        >
          <option value="" disabled hidden>Choose Gender</option>
          <option value="M">Male</option>
          <option value="F">Female</option>
        </select>
    </div>
</div>
<div class="form-group mb-4 w-100">
    <div class="text-start">
        <label class="form-label text-xs fw-bold">Country Code <span class="text-danger fw-bold">*</span></label>
        <input v-model="country_code" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg. ZAF">
    </div>
</div>
<div class="col text-end">
    <button type="submit" class="btn bg-green btn-sm text-xs shadow rounded-small mt-2 fw-bold" @click.prevent="anti_money_laundering_application_api">Submit</button>
</div>

<div class="modal" tabindex="-1" id="verification_results">
<div class="modal-dialog modal-fullscreen">
<div class="modal-content">
<div class="modal-header">
  <h5 class="modal-title">Verification Result</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="reload_page"></button>
</div>
<div class="modal-body">
    <div v-if="Object.keys(json_data).length > 0" class="card card-border-decoration border-3">

        <!-- NmResults Table -->
        <div v-if="nm_results.NmResults && nm_results.NmResults.length">
            <h6 class="text-start mt-3"><strong>NM Results</strong></h6>
            <table class="table table-striped table-bordered table-hover w-100 mt-3 mb-5 data-table" id="nm_result">
                <thead class="text-dark">
                    <tr>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Product ID</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Score</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Source ID</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Category</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Country</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Sentiment Confidence Level</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Matched Subject</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Highlights & URL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in nm_results.NmResults" :key="result.resultId">
                        <td class="text-xxs text-start">{{ result.productId }}</td>
                        <td class="text-xxs text-start">{{ result.score }}</td>
                        <td class="text-xxs text-start">{{ result.sourceId }}</td>
                        <td class="text-xxs text-start">{{ result.category }}</td>
                        <td class="text-xxs text-start">{{ result.sourceCountry }}</td>
                        <td class="text-xxs text-start">{{ result.sentimentConfidenceLevel }}</td>
                        <td class="text-xxs text-start">{{ result.subjectMatched }}</td>
                        <td class="text-xxs text-start">
                            {{ result.highlights?.[0] }}
                            <p><a :href="result.url" target="_blank">{{ result.url }}</a></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <p class="text-center text-muted fw-bold mt-3">No Negative media results found</p>
        </div>

        <!-- PepResults Table -->
        <div v-if="nm_results.PepResults && nm_results.PepResults.length">
            <h6 class="text-start mt-3"><strong>PEP Results</strong></h6>
            <table class="table table-striped table-bordered table-hover w-100 mt-3 mb-5 data-table" id="pep_result">
                <thead class="text-dark">
                    <tr>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Product ID</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Score</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Profile ID</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Name</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Gender</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Country</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Professional History</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Source URL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in nm_results.PepResults" :key="result.profileId">
                        <td class="text-xxs text-start">{{ result.productId }}</td>
                        <td class="text-xxs text-start">{{ result.score }}</td>
                        <td class="text-xxs text-start">{{ result.profileId }}</td>
                        <td class="text-xxs text-start">{{ result.name }}</td>
                        <td class="text-xxs text-start">{{ result.gender }}</td>
                        <td class="text-xxs text-start">{{ result.country }}</td>
                        <td class="text-xxs text-start">{{ result.professionalHistory?.join(', ') }}</td>
                        <td class="text-xxs text-start">
                            <a v-if="result.sourceInfoURL?.length" :href="result.sourceInfoURL[0]" target="_blank">
                                {{ result.sourceInfoURL[0] }}
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <p class="text-center text-muted fw-bold mt-3">No PEP/PIP results found</p>
        </div>

        <!-- WlsResults Table -->
        <!-- WlsResults Table -->
        <div v-if="nm_results.WlsResults && nm_results.WlsResults.length">
            <h6 class="text-start mt-3"><strong>Watchlist Results</strong></h6>
            <table class="table table-striped table-bordered table-hover w-100 mt-3 mb-5 data-table" id="wls_result">
                <thead class="text-dark">
                    <tr>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Product ID</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Score</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Source ID</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Category</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Country</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Subject Matched</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Highlights & URL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in nm_results.WlsResults" :key="result.resultId">
                        <td class="text-xxs text-start">{{ result.productId }}</td>
                        <td class="text-xxs text-start">{{ result.score }}</td>
                        <td class="text-xxs text-start">{{ result.sourceId }}</td>
                        <td class="text-xxs text-start">{{ result.category }}</td>
                        <td class="text-xxs text-start">{{ result.sourceCountry }}</td>
                        <td class="text-xxs text-start">{{ result.subjectMatched }}</td>
                        <td class="text-xxs text-start">
                            {{ result.highlights?.[0] }}
                            <p><a :href="result.url" target="_blank">{{ result.url }}</a></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else>
            <p class="text-center text-muted fw-bold mt-3">No watchlist results found</p>
        </div>
    </div>

    <div v-else>
        <div class="tab-pane fade show active fw-bold text-xs mb-4">
            <pre>{{ formatted_data }}</pre>
        </div>
    </div>
</div>

<div class="modal-footer">
    <router-link :to="{ name: 'services' }">
        <button class="btn bg-green btn-sm text-xs text-end  rounded-small mt-3" data-bs-dismiss="modal" @click="reload_page">Back</button>
    </router-link>
</div>
</div>
</div>
</div>