
<transition name="modal">
  <div class="terms-modal" v-if="showModal">
    <div class="terms-modal-content">
      <!-- Header -->
      <div class="terms-header">
        <div class="terms-title">
          <div class="title-text">
            <h2>Marketing Consent</h2>
          </div>
        </div>
      </div>

      <!-- Content -->
      <div class="terms-body">
        <div class="terms-text" @scroll="handleScroll" ref="consentContent">
          <div class="term-item">
            <i class="fas fa-bell"></i>
            <p v-html="marketingContent[client]"></p>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="terms-footer">
        <button type="button" class="btn-terms-decline" @click.prevent="decline">Decline</button>
        <button 
            type="button"
            class="btn-terms-accept"
            @click.prevent="accept"
        > Accept
        </button>
    </div>
    </div>
  </div>
</transition>