export const PREPOPULATE_INPUT = {
    data: () => ({
      form_data: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        id_number: '',
        company_name: '',
        company_registration: '',
      },
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      id_number: '',
      company_name: '',
      company_registration: '',
      is_field_disabled: false
    }),
  
    methods: {
      sync_form_data() {
        this.first_name = this.form_data.first_name || '';
        this.last_name = this.form_data.last_name || '';
        this.email = this.form_data.email || '';
        this.phone_number = this.form_data.phone_number || '';
        this.id_number = this.form_data.id_number || '';
        this.company_name = this.form_data.company_name || '';
        this.company_registration = this.form_data.company_registration || '';
        if (this.company_registration) {
          const [reg1, reg2, reg3] = this.company_registration.split('/');
          this.reg1 = reg1 || '';
          this.reg2 = reg2 || '';
          this.reg3 = reg3 || '';
        }

        this.is_field_disabled = {
          first_name: !!this.form_data.first_name,
          last_name: !!this.form_data.last_name,
          email: !!this.form_data.email,
          phone_number: !!this.form_data.phone_number,
          id_number: !!this.form_data.id_number,
          company_name: !!this.form_data.company_name,
          company_registration: !!this.form_data.company_registration
        };
      },
  
      async get_customer_data() {
        const TOKEN = localStorage.getItem('token');
        const CUSTOMER_DATA_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_customer_data/`;
  
        await $.ajax({
          url: CUSTOMER_DATA_URL,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`,
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => Swal.showLoading(),
            });
          },
          success: (response) => {
            Swal.close();
            this.form_data = { ...this.form_data, ...response };
            this.sync_form_data(); // Synchronize top-level variables
          },
        });
      },
    },
  
    watch: {
      // Watch top-level fields to update form_data automatically
      first_name(val) {
        this.form_data.first_name = val;
      },
      last_name(val) {
        this.form_data.last_name = val;
      },
      email(val) {
        this.form_data.email = val;
      },
      phone_number(val) {
        this.form_data.phone_number = val;
      },
      id_number(val) {
        this.form_data.id_number = val;
      },
      company_name(val) {
        this.form_data.company_name = val;
      },
      company_registration(val) {
        this.form_data.company_registration=val;
      },
    },
  };
  