import { ref } from "vue";
import { validate_id } from "@/utils/validations";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import { validateToken } from '@/utils/auth';
import { FaceMesh } from '@mediapipe/face_mesh';
import { Camera } from '@mediapipe/camera_utils';

export default {
  name: "FacialBiometricsVerification",
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
  components: {
    BreadCrumb,
    ClientLogo,
  },

  setup() {
    const id_number = ref("");

    return {
      id_number,
      validate_id
    };
  },

  data: () => ({
    id_number: "",
    photo_taken: false,
    photo_src: "",
    video_stream: null,
    is_mobile_view: false,
    showCamera: false,
    showInstructions: false,
    current_step: 0,
    is_captured: false,
    progress_percentage: 0,
    oval_size: 0.18,
    step_start_time: 0,
    has_initial_instruction: false,
    selected_voice: null,
    voice_initialized: false,
    timer_count: 3,
    show_timer: false,
    loading: true,
    status_message: "",
    last_spoken_message: '',
    last_spoken_time: 0,
    spoken_message: '',
    showCaptureAnimation: false,
    errors: {
      empty: false,
      invalid: false,
      exists: false
    },
    idVerified: false,

    // Constants
    STEPS: {
      LOOK_LEFT: 0,
      LOOK_RIGHT: 1,
      MOVE_CLOSER: 2,
      LOOK_CENTER: 3,
      CAPTURE: 4
    },
    STEP_HOLD_TIME: 1200,
    SPEECH_COOLDOWN: 1500,
    LOOKING_CENTER_HOLD_TIME: 2000, // Time to hold looking at the camera
    center_gaze_start_time: 0, // Track when user started looking at center

    instruction_message: "",
    status_messages: {
      LOOK_LEFT: "Please turn your head slightly to the left",
      LOOK_RIGHT: "Great! Please turn your head slightly to the right",
      GET_CLOSER: "Please position your face within the oval",
      LOOK_CENTER: "Now look directly into the camera",
      CAPTURE: "Hold still for photo capture",
      MULTIPLE_FACES: "Only one person should be in frame",
      NO_FACE: "No face detected - please center your face",
      PERFECT_POSITION: "Perfect position! Hold still",
      BLINK_REQUIRED: "Please blink naturally",
      BLINK_DETECTED: "Blink detected!",
      LOOKING_CENTER: "Great! Keep looking at the camera",

      MOVE_SLIGHTLY_LEFT: "Please move slightly to the left",
      MOVE_SLIGHTLY_RIGHT: "Please move slightly to the right",
      MOVE_SLIGHTLY_UP: "Please move slightly up",
      MOVE_SLIGHTLY_DOWN: "Please move slightly down",
    },
    is_field_disabled: {},
    blink_detected: false,
    last_blink_time: 0,
    blink_count: 0,
    eye_closed_frames: 0,
    eye_open_frames: 0,
    MIN_EYE_CLOSED_FRAMES: 1,
    MIN_EYE_OPEN_FRAMES: 5,
    EYE_CLOSED_THRESHOLD: 0.019,
    EYE_OPEN_THRESHOLD: 0.02,
    awaiting_blink: false,
  }),

  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
    clientLogo() {
      const domain = window.location.hostname;
      let logoPath;


      if (domain.includes('wizzit')) {
        logoPath = require('@/assets/images/Wizzit-Logo-Red.svg');
      } else if (domain.includes('vodacom')) {
        logoPath = require('@/assets/images/new_voda.svg');

      }else if (domain.includes('suzuki')) {
          logoPath = require('@/assets/images/Suzuki_logo.svg')
      } else {
        logoPath = require('@/assets/images/new_black_logo2.svg');
      }

      return logoPath;
    },

    progressStyle() {
      const circumference = 2 * Math.PI * 45;
      const offset = circumference - (this.progress_percentage / 100) * circumference;
      return {
        strokeDasharray: `${circumference} ${circumference}`,
        strokeDashoffset: offset
      };
    }
  },

  methods: {

    submit_id() {
      // Hide all error messages first
      $('.id-message, .invalid-message, .exists-message').addClass('d-none');
      
      // Validate empty
      if (!this.id_number.trim()) {
          $('.id-message').removeClass('d-none');
          return;
      }
  
      // Validate ID format
      if (!this.validate_id(this.id_number)) {
          $('.invalid-message').removeClass('d-none');
          return;
      }
  
      const TOKEN = localStorage.getItem('token');
      const CONSENT_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/save_id_verification_api/`;
      const FORM_DATA = {
          id_number: this.id_number
      };
  
      $.ajax({
          url: CONSENT_URL,
          method: 'POST',
          contentType: 'application/json',
          headers: {
              Authorization: `Token ${TOKEN}` 
          },
          data: JSON.stringify(FORM_DATA),
          beforeSend: () => {
              Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                      Swal.showLoading();
                  }
              });
          },
          success: (response) => {
              Swal.close();
              if (response.status === 'success') {
                  localStorage.setItem('id_number', response.id_number);
                  localStorage.setItem('user_state', 'id_verified');
                  this.showInstructions = true;
              }
          },
          error: (error) => {
              Swal.close();
              
              if (error.responseJSON) {
                  if (error.responseJSON.message === 'ID number already exists for another user') {
                      $('.exists-message').removeClass('d-none');
                      this.isInputDisabled = true;
                  } else {
                      Swal.fire({
                          icon: 'error',
                          text: error.responseJSON.message || 'Something went wrong!'
                      });
                  }
              } else {
                  Swal.fire({
                      icon: 'error',
                      text: 'Something went wrong!'
                  });
              }
          }
      });
  },

    startFaceRecognition() {
      this.showInstructions = false;
      this.showCamera = true;
      this.initialize_camera();
    },
    speak(message) {
      const current_time = Date.now();
      if (message !== this.last_spoken_message &&
        current_time - this.last_spoken_time > this.SPEECH_COOLDOWN) {
        
        // Cancel any ongoing speech
        window.speechSynthesis.cancel();
        
        // Create a new utterance
        const utterance = new SpeechSynthesisUtterance(message);
        
        // Firefox compatibility fix - don't set voice until we know they're loaded
        if (this.voice_initialized && this.selected_voice) {
          utterance.voice = this.selected_voice;
        }
    
        utterance.rate = 1.0;
        utterance.pitch = 1.1;
        utterance.volume = 1.0;
        
        // Firefox often needs a slight delay
        setTimeout(() => {
          window.speechSynthesis.speak(utterance);
        }, 50);
        this.last_spoken_message = message;
        this.last_spoken_time = current_time;
        this.spoken_message = message;
      }
    },
    show_instructions() {
      this.showInstructions = true;
      this.showCamera = false;
    },

    async setupFaceMesh() {
      try {
        const faceMesh = new FaceMesh({
          locateFile: (file) =>
            `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
        });

        faceMesh.setOptions({
          maxNumFaces: 2,
          refineLandmarks: true,
          minDetectionConfidence: 0.7,
          minTrackingConfidence: 0.7,
        });

        faceMesh.onResults(this.onResults);

        this.camera = new Camera(this.$refs.video, {
          onFrame: async () => {
            await faceMesh.send({ image: this.$refs.video });
          },
          width: 640,
          height: 480,
        });

        this.camera.start();
        this.loading = false;
        this.speak(
          "Face detection system initialized... Please look slightly to the left"
        );
        this.updateStatusMessage(this.status_messages.LOOK_LEFT);
      } catch (error) {
        console.error("Face detection setup failed:", error);
      }
    },
    checkGazeDirection(landmarks) {
      const left_eye = landmarks[33];
      const right_eye = landmarks[263];
      const nose = landmarks[1];
    
      const eye_distance = right_eye.x - left_eye.x;
      const nose_position = (nose.x - left_eye.x) / eye_distance;
    
      // Get overlay elements
      const left_overlay = document.querySelector('.face-overlay-left');
      const right_overlay = document.querySelector('.face-overlay-right');
      const merged_oval = document.querySelector('.merged-oval');
    
      let is_correct_position = false;
      let progress = 0;
    
      switch (this.current_step) {
        case this.STEPS.LOOK_LEFT:
          progress = Math.min(Math.max((nose_position - 0.5) / 0.2, 0), 1) * 100;
    
          // Reset merged oval
          if (merged_oval) {
            merged_oval.style.opacity = '0';
            merged_oval.classList.remove('show');
          }
    
          if (progress >= 100) {
            left_overlay.classList.add('completed');
            is_correct_position = true;
            if (!this.step_start_time) {
              this.step_start_time = Date.now();
            }
          } else {
            left_overlay.classList.remove('completed');
            this.step_start_time = 0;
          }
    
          left_overlay.style.setProperty('--progress-percent', `${progress}%`);
          break;
    
        case this.STEPS.LOOK_RIGHT:
          progress = Math.min(Math.max((0.5 - nose_position) / 0.2, 0), 1) * 100;
    
          if (progress >= 100) {
            right_overlay.classList.add('completed');
            is_correct_position = true;
            if (!this.step_start_time) {
              this.step_start_time = Date.now();
            }
    
            // If both overlays are completed, trigger merge
            if (left_overlay.classList.contains('completed')) {
              left_overlay.classList.add('merge');
              right_overlay.classList.add('merge');
              if (merged_oval) {
                merged_oval.classList.add('show');
                merged_oval.style.opacity = '1';
              }
            }
          } else {
            right_overlay.classList.remove('completed');
            this.step_start_time = 0;
          }

          right_overlay.style.setProperty('--progress-percent', `${progress}%`);
          break;
        case this.STEPS.LOOK_CENTER:
          // Check if user is looking directly at the camera
          is_correct_position = nose_position >= 0.45 && nose_position <= 0.55;
          break;
      }

      if (is_correct_position &&
        this.step_start_time &&
        Date.now() - this.step_start_time >= this.STEP_HOLD_TIME) {
        this.progressToNextStep();
      }

      return is_correct_position;
    },

    checkFaceFit(landmarks) {
      const centerX = this.$refs.canvas.width / 2;
      const centerY = this.$refs.canvas.height / 2;
      const radiusX = this.$refs.canvas.width * this.oval_size;
      const radiusY = this.$refs.canvas.height * (this.oval_size * 1.67);
    
      const facePoints = [
        landmarks[10],   // Forehead
        landmarks[152],  // Chin
        landmarks[234],  // Left cheek
        landmarks[454],  // Right cheek
        landmarks[1],    // Nose
        landmarks[33],   // Left eye
        landmarks[263],  // Right eye
        landmarks[61],   // Mouth left
        landmarks[291]   // Mouth right
      ];
    
      const merged_oval = document.querySelector('.merged-oval');
      const face_width = Math.abs(landmarks[234].x - landmarks[454].x) * this.$refs.canvas.width;
      const min_required_width = this.$refs.canvas.width * 0.25;
    
      // Check if looking at camera
      const is_looking_center = this.checkLookingCenter(landmarks);
    
      if (face_width < min_required_width) {
        if (!this.awaiting_blink) {
          this.speak('Please move closer to the camera');
          this.updateStatusMessage(this.status_messages.GET_CLOSER);
        }
        this.progress_percentage = Math.max(0, this.progress_percentage - 2);
        
        if (merged_oval) {
          merged_oval.style.setProperty('--progress-percent', `${this.progress_percentage}%`);
          merged_oval.classList.remove('completed');
        }
        return false;
      }
    
      const isInOval = facePoints.every(point => {
        const x = (point.x * this.$refs.canvas.width - centerX) / radiusX;
        const y = (point.y * this.$refs.canvas.height - centerY) / radiusY;
        return (x * x + y * y) <= 1;
      });
    
      const noseX = landmarks[1].x * this.$refs.canvas.width;
      const noseY = landmarks[1].y * this.$refs.canvas.height;
      const is_centered = Math.abs(noseX - centerX) < radiusX * 0.2 &&
        Math.abs(noseY - centerY) < radiusY * 0.2;
    
      // Consider all conditions including looking at camera
      const all_conditions_met = isInOval && is_centered && is_looking_center;
    
      if (!all_conditions_met) {
        // Decrease progress if any condition is not met
        this.progress_percentage = Math.max(0, this.progress_percentage - 2);
        
        if (!this.awaiting_blink && this.current_step !== this.STEPS.LOOK_CENTER) {
          if (!is_looking_center) {
            this.speak('Please look directly at the camera');
            this.updateStatusMessage('Please look directly at the camera');
          } else if (noseX < centerX - radiusX * 0.2) {
            this.speak('Please move slightly to the right');
            this.updateStatusMessage(this.status_messages.MOVE_SLIGHTLY_RIGHT);
          } else if (noseX > centerX + radiusX * 0.2) {
            this.speak('Please move slightly to the left');
            this.updateStatusMessage(this.status_messages.MOVE_SLIGHTLY_LEFT);
          } else if (noseY < centerY - radiusY * 0.2) {
            this.speak('Please move slightly down');
            this.updateStatusMessage(this.status_messages.MOVE_SLIGHTLY_DOWN);
          } else if (noseY > centerY + radiusY * 0.2) {
            this.speak('Please move slightly up');
            this.updateStatusMessage(this.status_messages.MOVE_SLIGHTLY_UP);
          }
        }
      } else {
        // Only increase progress if all conditions are met
        this.progress_percentage = Math.min(100, this.progress_percentage + 2);
        
        if (this.progress_percentage >= 90 &&
          (this.current_step !== this.STEPS.CAPTURE && this.current_step !== this.STEPS.LOOK_CENTER)) {
          this.updateStatusMessage(this.status_messages.PERFECT_POSITION);
        }
      }
    
      // Update merged oval progress
      if (merged_oval && this.current_step >= this.STEPS.MOVE_CLOSER) {
        merged_oval.style.setProperty('--progress-percent', `${this.progress_percentage}%`);
        
        if (this.progress_percentage >= 100) {
          merged_oval.classList.add('completed');
        } else {
          merged_oval.classList.remove('completed');
        }
      }
    
      // Return true only if all conditions are met and progress is 100%
      return all_conditions_met && this.progress_percentage === 100;
    },

    updateStatusMessage(message) {
      this.status_message = message;
      this.instruction_message = this.status_messages[this.current_step] || "";
    },
    
    progressToNextStep() {
      // Only proceed if progress is 100% for MOVE_CLOSER step
      if (this.current_step === this.STEPS.MOVE_CLOSER && this.progress_percentage < 100) {
        return; 
      }
    
      this.step_start_time = 0;
      this.current_step++;
      this.center_gaze_start_time = 0;
    
      const left_overlay = document.querySelector('.face-overlay-left');
      const right_overlay = document.querySelector('.face-overlay-right');
      const merged_oval = document.querySelector('.merged-oval');
    
      switch(this.current_step) {
        case this.STEPS.LOOK_RIGHT:
          if (right_overlay) {
            right_overlay.style.setProperty('--progress-percent', '0%');
            right_overlay.classList.remove('completed');
          }
          this.speak('Great! Please turn your head slightly to the right');
          this.updateStatusMessage(this.status_messages.LOOK_RIGHT);
          break;
    
        case this.STEPS.MOVE_CLOSER:
          if (left_overlay && right_overlay) {
            left_overlay.classList.add('completed');
            right_overlay.classList.add('completed');
    
            setTimeout(() => {
              left_overlay.classList.add('fade-out');
              right_overlay.classList.add('fade-out');
    
              setTimeout(() => {
                if (merged_oval) {
                  merged_oval.classList.remove('hidden');
                  merged_oval.classList.add('show');
  
                }
              }, 600);
            }, 100);
          }
          
          this.speak('Excellent! Please look into the camera, and move closer');
          this.updateStatusMessage(this.status_messages.GET_CLOSER);
          this.oval_size = 0.25;
          break;
    
        case this.STEPS.LOOK_CENTER:
          if (merged_oval) {
     
            merged_oval.classList.remove('completed');
            merged_oval.classList.remove('hidden');
            merged_oval.classList.add('show');
          }
          this.speak('Perfect! Now look directly into the camera');
          this.updateStatusMessage(this.status_messages.LOOK_CENTER);
          break;
    
        case this.STEPS.CAPTURE:
          if (this.blink_detected) {
            this.updateStatusMessage(this.status_messages.CAPTURE);
          } else {
            this.awaiting_blink = true;
            this.speak('Please blink naturally');
            this.updateStatusMessage(this.status_messages.BLINK_REQUIRED);
          }
          break;
      }
    
      if (this.current_step === this.STEPS.CAPTURE) {
        this.eye_closed_frames = 0;
        this.eye_open_frames = 0;
        this.blink_count = 0;
        if (!this.blink_detected) {
          this.awaiting_blink = true;
        }
      }
    },
    

    detectBlink(landmarks) {
      // Get eye landmarks
      const left_eye_upper = landmarks[159];
      const left_eye_lower = landmarks[145];
      const right_eye_upper = landmarks[386];
      const right_eye_lower = landmarks[374];

      // Calculate eye aspect ratio (EAR)
      const left_eye_distance = Math.abs(left_eye_upper.y - left_eye_lower.y);
      const right_eye_distance = Math.abs(right_eye_upper.y - right_eye_lower.y);
      const average_ear = (left_eye_distance + right_eye_distance) / 2;

      // Detect eye closed
      if (average_ear < this.EYE_CLOSED_THRESHOLD) {
        this.eye_closed_frames++;
        this.eye_open_frames = 0;
      }
      // Detect eye open
      else if (average_ear > this.EYE_OPEN_THRESHOLD) {
        this.eye_open_frames++;

        // If we have enough frames of closed eyes followed by enough frames of open eyes
        if (this.eye_closed_frames >= this.MIN_EYE_CLOSED_FRAMES &&
          this.eye_open_frames >= this.MIN_EYE_OPEN_FRAMES) {

          // Blink detected
          if (!this.blink_detected) {
            this.blink_detected = true;
            this.blink_count++;

            this.last_blink_time = Date.now();

            // If we're awaiting a blink to continue to capture, proceed now
            if (this.awaiting_blink) {
              this.awaiting_blink = false;
              this.speak('Blink detected!');
              this.updateStatusMessage(this.status_messages.BLINK_DETECTED);

              // Skip the countdown and proceed to look center/capture
              if (this.current_step === this.STEPS.CAPTURE) {
                this.updateStatusMessage(this.status_messages.LOOK_CENTER);
              }
            }

            return true;
          }
        }
      }

      // Reset counters if neither condition is met
      if (average_ear >= this.EYE_CLOSED_THRESHOLD && average_ear <= this.EYE_OPEN_THRESHOLD) {
        this.eye_closed_frames = 0;
        this.eye_open_frames = 0;
      }

      return false;
    },

    checkLookingCenter(landmarks) {
      // Get eye landmarks
      const left_eye_left_corner = landmarks[33];
      const left_eye_right_corner = landmarks[133];
      const right_eye_left_corner = landmarks[362];
      const right_eye_right_corner = landmarks[263];

      // Get iris landmarks (added in refineLandmarks mode)
      const left_iris_center = landmarks[468];
      const right_iris_center = landmarks[473];

      // Calculate relative positions of irises within eye sockets
      const left_eye_width = left_eye_right_corner.x - left_eye_left_corner.x;
      const right_eye_width = right_eye_right_corner.x - right_eye_left_corner.x;

      const left_iris_relative_x = (left_iris_center.x - left_eye_left_corner.x) / left_eye_width;
      const right_iris_relative_x = (right_iris_center.x - right_eye_left_corner.x) / right_eye_width;

      // Get vertical position
      const left_eye_top = landmarks[159];
      const left_eye_bottom = landmarks[145];
      const right_eye_top = landmarks[386];
      const right_eye_bottom = landmarks[374];

      const left_eye_height = left_eye_bottom.y - left_eye_top.y;
      const right_eye_height = right_eye_bottom.y - right_eye_top.y;

      const left_iris_relative_y = (left_iris_center.y - left_eye_top.y) / left_eye_height;
      const right_iris_relative_y = (right_iris_center.y - right_eye_top.y) / right_eye_height;

      // Check if irises are centered horizontally (looking straight)
      const is_looking_straight_h =
        left_iris_relative_x >= 0.35 && left_iris_relative_x <= 0.65 &&
        right_iris_relative_x >= 0.35 && right_iris_relative_x <= 0.65;

      // Check if irises are centered vertically (not looking up/down)
      const is_looking_straight_v =
        left_iris_relative_y >= 0.35 && left_iris_relative_y <= 0.7 &&
        right_iris_relative_y >= 0.35 && right_iris_relative_y <= 0.7;

      return is_looking_straight_h && is_looking_straight_v;
    },

    onResults(results) {
      if (this.is_captured) return;
    
      const merged_oval = document.querySelector('.merged-oval');
      
      // Remove capture-ready at the start of each check
      if (merged_oval && merged_oval.classList.contains('capture-ready')) {
        merged_oval.classList.remove('capture-ready');
      }
    
      if (results.multiFaceLandmarks?.length > 0) {
        const numFaces = results.multiFaceLandmarks.length;
        if (numFaces > 1) {
          this.speak('Please ensure only one person is in frame');
          this.updateStatusMessage(this.status_messages.MULTIPLE_FACES);
          return;
        } else if (numFaces === 1) {
          // Add this check to detect when a face reappears
          if (this.status_message.includes('No face') || 
              this.status_message.includes('Multiple faces')) {
            // Re-speak and update the current instruction
            switch(this.current_step) {
              case this.STEPS.LOOK_LEFT:
                this.speak(this.status_messages.LOOK_LEFT);
                this.updateStatusMessage(this.status_messages.LOOK_LEFT);
                break;
              case this.STEPS.LOOK_RIGHT:
                this.speak(this.status_messages.LOOK_RIGHT);
                this.updateStatusMessage(this.status_messages.LOOK_RIGHT);
                break;
              case this.STEPS.MOVE_CLOSER:
                this.speak(this.status_messages.GET_CLOSER);
                this.updateStatusMessage(this.status_messages.GET_CLOSER);
                break;
              case this.STEPS.LOOK_CENTER:
                this.speak(this.status_messages.LOOK_CENTER);
                this.updateStatusMessage(this.status_messages.LOOK_CENTER);
                break;
              case this.STEPS.CAPTURE:
                if (this.awaiting_blink) {
                  this.speak(this.status_messages.BLINK_REQUIRED);
                  this.updateStatusMessage(this.status_messages.BLINK_REQUIRED);
                } else {
                  this.speak(this.status_messages.CAPTURE);
                  this.updateStatusMessage(this.status_messages.CAPTURE);
                }
                break;
            }
          }
          
          const landmarks = results.multiFaceLandmarks[0];
          const blink_detected = this.detectBlink(landmarks);
    
          if (this.current_step === this.STEPS.CAPTURE && this.awaiting_blink) {
            if (blink_detected) {
              this.checkFaceFit(landmarks);
            }
            return;
          }
    
          if (this.current_step <= this.STEPS.LOOK_RIGHT) {
            const gaze_correct = this.checkGazeDirection(landmarks);
            if (gaze_correct && !this.step_start_time) {
              this.step_start_time = Date.now();
            } else if (gaze_correct && Date.now() - this.step_start_time >= this.STEP_HOLD_TIME) {
              this.progressToNextStep();
            }
          } else if (this.current_step === this.STEPS.MOVE_CLOSER) {
            if (this.checkFaceFit(landmarks)) {
              this.progressToNextStep();
            }
          } else if (this.current_step === this.STEPS.LOOK_CENTER) {
            const is_looking_center = this.checkGazeDirection(landmarks);
            const is_face_fit = this.checkFaceFit(landmarks);
            
            if (is_looking_center && is_face_fit) {
              if (!this.center_gaze_start_time) {
                this.center_gaze_start_time = Date.now();
                this.speak('Great! look at the camera');
                this.updateStatusMessage(this.status_messages.LOOKING_CENTER);
              } else if (Date.now() - this.center_gaze_start_time >= this.LOOKING_CENTER_HOLD_TIME) {
                this.progressToNextStep();
              }
            } else {
              this.center_gaze_start_time = 0;
            }
          } else if (this.current_step === this.STEPS.CAPTURE) {
            const is_looking_center = this.checkLookingCenter(landmarks);
            const is_face_fit = this.checkFaceFit(landmarks);
            
            // Only add capture-ready class when ALL conditions are met
            if (is_looking_center && is_face_fit && this.blink_detected) {
              if (merged_oval) {
                merged_oval.classList.add('capture-ready');
              }
              this.take_photo();
            }
          }
        }
      } else {
        this.speak('No face detected');
        this.updateStatusMessage(this.status_messages.NO_FACE);
      }
    },

    take_photo() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.photo_taken = true;
      this.photo_src = canvas.toDataURL();
      this.is_captured = true;

      // Show capture animation
      this.showCaptureAnimation = true;

      // Wait 5 seconds before submitting
      setTimeout(() => {
        this.safps_verification();
      }, 2000);

      if (this.is_mobile_view) {
        $("#take_photo").hide();
        $("#submit_photo").show();
        $(".retake-photo").removeClass("d-none");
      }
    },

    initialize_camera() {
      if (this.video_stream) return;
    
      // Try to get the ideal camera resolution for the device
      const is_mobile = window.innerWidth <= 768;
      
      const constraints = {
        video: {
          width: { ideal: is_mobile ? 640 : 1280 },
          height: { ideal: is_mobile ? 480 : 720 },
          facingMode: "user"
        }
      };
      
      navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
          const video = this.$refs.video;
          video.srcObject = stream;
          this.video_stream = stream;
    
          // Set canvas size once video metadata is loaded
          video.onloadedmetadata = () => {
            video.play();
            
            // Make sure canvas matches video dimensions
            if (this.$refs.canvas) {
              this.$refs.canvas.width = video.videoWidth;
              this.$refs.canvas.height = video.videoHeight;
            }
            
            this.setupFaceMesh();
          };
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
          // Show a user-friendly error
          Swal.fire({
            icon: "error",
            title: "Camera Access Error",
            text: "We couldn't access your camera. Please ensure you've given permission and try again."
          });
        });
    },

    handle_input() {
      // Hide all error messages when user types
      $('.id-message, .invalid-message, .exists-message').addClass('d-none');
    },

    handle_camera_resize() {
      if (this.$refs.video && this.$refs.canvas) {
        // Adjust canvas to match video
        this.$refs.canvas.width = this.$refs.video.videoWidth;
        this.$refs.canvas.height = this.$refs.video.videoHeight;
      }
    },
    
    // 5. Update your handle_window_resize method to include the camera resize
    handle_window_resize() {
      this.toggle_photo_sections();
      this.handle_camera_resize();
      
      // Determine if we're in mobile view
      this.is_mobile_view = window.innerWidth <= 768;
    },

    closeInstructions() {
      this.showInstructions = false;
    },

    safps_verification() {
      const TOKEN = localStorage.getItem("token");
      const SAFPS_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/facial_biometrics_liveness_verification/`;
      const DATA = {
        id_number: this.id_number,
        biometric: this.photo_src,
        workflow_id: this.workflow_id,
      };
      $.ajax({
        url: SAFPS_URL,
        method: "POST",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        data: JSON.stringify(DATA),
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          const status_message = response.status;
          var liveness_value = response.liveness;
          const message = response.message;
          const user_role = response["role"];

          this.stop_camera();
          this.showCamera = false;

          if (status_message === "success") {
            if (user_role === "Director") {
              this.$router.push("/upload_proof_of_address");
            } else if (user_role === "ClientAdmin") {
              this.$router.push('/company_verification');
            }else {
              this.fetch_current_service();
            }
          } else if (
            status_message ===
            "Results not found please contact your service provider"
          ) {
            Swal.fire({
              icon: "error",
              text: "Our service is currently unavailable. Please contact your service provider and try again later",
            });
          }
          if (liveness_value === 'User is wearing a hat') {
            liveness_value = "Please remove your hat and try again"
          }
          if (liveness_value === 'User is wearing glasses') {
            liveness_value = "Please remove your glasses and try again"
          }

          if (liveness_value) {
            Swal.fire({
              icon: "error",
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          }

          if (message) {
            Swal.fire({
              icon: "error",
              title: message,
            }).then(() => {
              location.reload();
            });
          }
        },
        error: (error) => {
          Swal.close();
          let error_message = "Error retrieving data";

          if (error.responseJSON) {
            error_message = error.responseJSON.id_number[0];
            $(".error-message")
              .text(error_message)
              .removeClass("d-none");
            setTimeout(() => {
              $(".error-message").text("").addClass("d-none");
            }, 3000);
          }
        },
      });
    },

    async stop_camera() {
      try {
        if (this.camera) {
          this.camera.stop();
          this.camera = null;
        }
        if (this.video_stream) {
          this.video_stream.getTracks().forEach(track => track.stop());
          this.video_stream = null;
        }
        if (this.$refs.video) {
          this.$refs.video.srcObject = null;
        }
        this.showCamera = false;
        this.showInstructions = false;
        this.is_captured = false;
        this.loading = false;
      } catch (error) {
        console.error("Error stopping camera:", error);
      }
    },

    toggle_photo_sections() {
      this.is_mobile_view = window.innerWidth <= 768;
      if (this.is_mobile_view) {
        $("#take_photo").show();
        $("#submit_photo").hide();
      } else {
        $("#take_photo").show();
        $("#submit_photo").show();
      }
    },
  },

  mounted() {
    if (!validateToken(this.$router)) return;

    const initVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      if (voices.length > 0) {
        // Try to find appropriate voice in this order:
        this.selected_voice = 
          // First try Microsoft Zira or any Zira voice
          voices.find(voice => voice.name.includes('Zira') || voice.name.includes('Microsoft Zira')) ||
          // Then try any female English voice
          voices.find(voice => 
            voice.lang.startsWith('en') && 
            (voice.name.toLowerCase().includes('female') || 
             voice.name.includes('woman') || 
             voice.name.includes('Victoria') || 
             voice.name.includes('Karen'))
          ) ||
          // Then any English voice that isn't explicitly male
          voices.find(voice =>
            voice.lang.startsWith('en') &&
            !voice.name.includes('David') &&
            !voice.name.toLowerCase().includes('male')
          ) ||
          // Finally, just use the first voice
          voices[0];
        
        this.voice_initialized = true;
      } else {
        // If no voices yet, try again in 100ms (Firefox often needs this)
        setTimeout(initVoices, 100);
      }
    };

    initVoices();

    window.speechSynthesis.onvoiceschanged = initVoices;

    const shown_instructions = localStorage.getItem("shown_instructions");

    if (shown_instructions) {
      this.initialize_camera();
    } else {
      $("#show_instruction").on("hidden.bs.modal", () => {
        this.initialize_camera();
        window.addEventListener("resize", this.handle_window_resize);
      });
    }
  },

  beforeDestroy() {

    const elements = document.querySelectorAll('.face-overlay-left, .face-overlay-right, .merged-oval');
    elements.forEach(element => {
    element.classList.remove('fade-out', 'fade-in', 'hidden');
  });

    this.stop_camera();
    window.removeEventListener("resize", this.handle_window_resize);
  },

  beforeRouteLeave(to, from, next) {
    this.stop_camera();
    next();
  },
};