<div class="container-fluid bg-white vh-100 p-0">
    <div class="text-center mt-5 mb-2">
        <img :src="$clientLogo" width="250px">
    </div>

    <div class="text-center mt-4">
        <nav aria-label="breadcrumb" class="w-50 mx-auto">
            <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2 mb-4">
                <li class="breadcrumb-item active  text-white" aria-current="page">
                    Thank You!
                </li>
            </ol>
        </nav>

        <div class="mb-5 mt-5">
            <img :src="$clientImage.ThankYou" alt="workflow completed" width="10%" height="auto">
        </div>

        <small class="text-secondary fw-bold d-block mb-2 mt-4">
            You have successfully completed the workflow.
        </small>
        <small class="text-secondary fw-bold d-block mb-5">
            We will be in contact with you soon.
        </small>

        <div class="row col-sm-12 d-flex justify-content-center align-items-center mx-auto">
            <div class="col-sm-12 text-center mt-3">
                <button class="btn btn-bg-dark rounded-3 fw-bold text-xs w-100 w-md-50 w-xl-15" @click="redirect_to_dashboard">
                    I'm Done
                </button>
            </div>
    
            <div 
                class="col-sm-12 text-center mt-3" 
                v-for="(workflow, index) in workflows" 
                :key="index"
                >
                <button
                    class="btn btn-bg-green rounded-3 fw-bold text-xs mx-1 w-100 w-md-50 w-xl-15"
                    @click="client_application_sub_workflow(workflow)">
                    {{ workflow.service_name }}
                </button>
            </div>
        </div>
    </div>
</div>
