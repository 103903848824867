<div class="search-select position-relative">
    <!-- Trigger/Input Container -->
    <div
        class="form-control d-flex align-items-center justify-content-between"
        @click="toggle_dropdown"
        ref="input_container"
    >
        <!-- Display area: chips for multi-select, single label for single-select -->
        <div class="d-flex flex-wrap align-items-center">
            
            <!-- Multi-Select Display -->
            <template v-if="multi_select">
                <!-- Placeholder if nothing selected and no search -->
                <span
                    v-if="selected_options.length === 0 && !search_query"
                    class="text-muted"
                >
                    {{ placeholder || 'Select an Option' }}
                </span>

                <!-- Display each selected option as a 'chip' -->
                <span
                    v-for="(option, index) in selected_option_objects"
                    :key="index"
                    class="badge me-1 d-flex align-items-center"
                >
                    {{ option.text }}
                    <!-- Remove icon (×) -->
                    <span
                        class="ms-1 cursor-pointer fw-bold"
                        @click.stop="remove_option(option.value)"
                    >
                        &times;
                    </span>
                </span>
            </template>

            <!-- Single-Select Display -->
            <template v-else>
                <!-- Placeholder if nothing selected and no search -->
                <span
                    v-if="selected_options.length === 0 && !search_query"
                    class="text-muted"
                >
                    {{ placeholder || 'Select an Option' }}
                </span>

                <!-- Show the currently selected item -->
                <span
                    v-else-if="selected_options.length > 0"
                    class="badge me-1"
                >
                    {{ get_option_text(selected_options[0]) }}
                    <!-- Remove icon (×) -->
                    <span
                        class="ms-1 cursor-pointer fw-bold"
                        @click.stop="remove_option(selected_options[0])"
                    >
                        &times;
                    </span>
                </span>
            </template>
        </div>

        <!-- Dropdown Arrow -->
        <span class="input-arrow"></span>
    </div>

    <!-- Dropdown Menu -->
    <div
        class="dropdown-menu show w-100"
        v-show="is_open"
        ref="dropdown_menu"
    >
        <ul
            class="list-unstyled mb-0 py-2 ul-select"
        >
            <!-- Inline Search Input -->
            <li class="dropdown-item">
                <input
                    v-model="search_query"
                    class="form-control flex-grow-1 w-100"
                    @input="filter_options"
                    @click.stop
                    :placeholder="is_open ? 'Search...' : ''"
                />
            </li>

            <!-- List of Filtered Options -->
            <li
                v-for="(option) in filtered_options"
                :key="option.value"
                class="dropdown-item d-flex align-items-center"
            >
                <!-- Always use checkbox for selection -->
                <input
                    type="checkbox"
                    class="me-2"
                    :id="option.value"
                    :value="option.value"
                    :checked="selected_options.includes(option.value)"
                    @change="select_single(option.value)"
                />
                <label :for="option.value" class="mb-0">
                    {{ option.text }}
                </label>
            </li>

            <!-- No Results Found -->
            <li
                v-if="filtered_options.length === 0"
                class="dropdown-item disabled"
            >
                No results found
            </li>
        </ul>
    </div>
</div>