<div class="container-fluid">
    <div class="col-sm-12 p-2 mt-3">
        <div class="row g-4">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <icon-card title="Revenue Generation This Month" :count="revenue_this_month" icon_class="fas fa-chart-line" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <icon-card title="Services Cost This Month" :count="cost_this_month" icon_class="fas fa-money-bill-wave" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Services Ran" :count="total_services_ran" icon_class="fas fa-running" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Services" :count="service_count" icon_class="fas fa-cogs" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Clients" :count="client_count" icon_class="fas fa-users" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Workflows" :count="workflow_count" icon_class="fas fa-project-diagram" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Applications Completed" :count="applications_completed" icon_class="fas fa-check-circle" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Applications Incompleted" :count="applications_incompleted" icon_class="fas fa-exclamation-circle" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Customers" :count="customer_count" icon_class="fas fa-user-friends" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <icon-card title="Total Assets" :count="asset_count" icon_class="fas fa-boxes" />
            </div>
        </div>
        <div>
            <!-- Services Table -->
            <div class="card rounded-3 mt-4 p-0 shadow-md">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <div class="title-info p-2">
                            <label class="d-flex align-items-center mb-0">
                                <i class="fas fa-cogs text-primary fa-xl me-2"></i>
                                <span class="fw-bold mr-1">Services</span>
                            </label>
                        </div>
                        <div class="search-container">
                            <div class="input-group">
                                <span class="input-group-text bg-transparent border-0">
                                    <i class="fas fa-search"></i>
                                </span>
                                <input type="text" class="form-control form-control-sm" v-model="services_search_query"
                                    placeholder="Search Services...">
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped table-hover table-sm text-xs">
                            <thead>
                                <tr>
                                    <th class="text-xs fw-bold">ID</th>
                                    <th class="text-xs fw-bold">Service Name</th>
                                    <th class="text-xs fw-bold">Service Description</th>
                                    <th class="text-xs fw-bold">Service Cost</th>
                                    <th class="text-xs fw-bold text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(service, index) in paginated_services" :key="index">
                                    <td class="py-2">{{ service.id }}</td>
                                    <td class="py-2">{{ service.name }}</td>
                                    <td class="py-2">
                                        <span class="d-inline-block text-truncate" style="max-width: 200px;">
                                            {{ service.description }}
                                        </span>
                                    </td>
                                    <td class="py-2">{{ service.cost }}</td>
                                    <td class="py-2 text-center">
                                        <button class="btn btn-bg-green btn-sm rounded-small"  @click="view_service(service.id)">
                                            <i class="fa fa-eye text-xs p-0 m-0 me-1"></i>
                                            <span class="mb-2 text-s-xs">View</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Services Empty State -->
                    <div v-if="services.length === 0" class="text-center py-5 my-4 text-muted">
                        <i class="fas fa-cogs fa-3x mb-3 text-secondary"></i>
                        <h6>No services found</h6>
                        <p class="text-xs text-muted">There are no services available at this time.</p>
                    </div>
                    <!-- Services Pagination -->
                    <div class="d-flex justify-content-between align-items-center mt-3" v-if="services.length > 0">
                        <div class="text-xs text-muted">
                            Showing {{ (services_current_page - 1) * items_per_page + 1 }} to
                            {{ Math.min(services_current_page * items_per_page, services.length) }}
                            of {{ services.length }} entries
                        </div>
                        <div class="d-flex align-items-center">
                            <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_services_page"
                                :disabled="services_current_page === 1">
                                <i class="fas fa-arrow-left"></i>
                            </button>
                            <span class="text-xs mx-2">Page {{ services_current_page }} of {{ services_total_pages }}</span>
                            <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_services_page"
                                :disabled="services_current_page === services_total_pages">
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Clients Table -->
            <div class="card rounded-3 mt-4 p-0 shadow-md">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <div class="title-info p-2">
                            <label class="d-flex align-items-center mb-0">
                                <i class="fas fa-users text-primary fa-xl me-2"></i>
                                <span class="fw-bold mr-1">Clients</span>
                            </label>
                        </div>
                        <div class="search-container">
                            <div class="input-group">
                                <span class="input-group-text bg-transparent border-0">
                                    <i class="fas fa-search"></i>
                                </span>
                                <input type="text" class="form-control form-control-sm" v-model="clients_search_query"
                                    placeholder="Search Clients...">
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped table-hover table-sm text-xs">
                            <thead>
                                <tr>
                                    <th class="text-xs fw-bold">ID</th>
                                    <th class="text-xs fw-bold">Client Name</th>
                                    <th class="text-xs fw-bold">Client Email</th>
                                    <th class="text-xs fw-bold">Company Name</th>
                                    <th class="text-xs fw-bold text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(client, index) in paginated_clients" :key="index">
                                    <td class="py-2">{{ client.id }}</td>
                                    <td class="py-2">{{ client.name }}</td>
                                    <td class="py-2">{{ client.email }}</td>
                                    <td class="py-2">{{ client.company_name }}</td>
                                    <td class="py-2 text-center">
                                        <button class="btn btn-bg-green btn-sm rounded-small" @click="view_client(client.id)">
                                            <i class="fa fa-eye text-xs p-0 m-0 me-1"></i>
                                            <span class="mb-2 text-s-xs">View</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Clients Empty State -->
                    <div v-if="clients.length === 0" class="text-center py-5 my-4 text-muted">
                        <i class="fas fa-users fa-3x mb-3 text-secondary"></i>
                        <h6>No clients found</h6>
                        <p class="text-xs text-muted">There are no clients available at this time.</p>
                    </div>
                    <!-- Clients Pagination -->
                    <div class="d-flex justify-content-between align-items-center mt-3" v-if="clients.length > 0">
                        <div class="text-xs text-muted">
                            Showing {{ (clients_current_page - 1) * items_per_page + 1 }} to
                            {{ Math.min(clients_current_page * items_per_page, clients.length) }}
                            of {{ clients.length }} entries
                        </div>
                        <div class="d-flex align-items-center">
                            <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_clients_page"
                                :disabled="clients_current_page === 1">
                                <i class="fas fa-arrow-left"></i>
                            </button>
                            <span class="text-xs mx-2">Page {{ clients_current_page }} of {{ clients_total_pages }}</span>
                            <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_clients_page"
                                :disabled="clients_current_page === clients_total_pages">
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>