<div class="refugee-verification">
    <div class="form-group">
      <label for="refugee_number" class="fw-bold text-xs">Refugee Number</label>
      <input type="text" v-model="form.refugee_number" id="refugee_number" class="form-control text-xs rounded-small p-3 shadow-sm" required />
    </div>
    <div class="d-flex justify-content-end my-2">
        <button @click="handle_submit" class="btn btn-primary-color rounded-pill text-xs">
            Run Service
        </button>
    </div>
</div>