<div class="afis-criminal-check">
    <div class="form-group mb-3">
        <label for="first_names" class="form-label fw-bold text-xs">First Names</label>
        <input type="text" v-model="form.first_names" id="first_names" class="form-control form-control-lg"
            placeholder="Enter your first names" required />
    </div>
    <div class="form-group mb-3">
        <label for="surname" class="form-label fw-bold text-xs">Surname</label>
        <input type="text" v-model="form.surname" id="surname" class="form-control form-control-lg"
            placeholder="Enter your surname" required />
    </div>
    <div class="form-group mb-3">
        <label for="id_number" class="form-label fw-bold text-xs">ID Number</label>
        <input type="text" v-model="form.id_number" id="id_number" class="form-control form-control-lg"
            placeholder="Enter your ID number" required />
    </div>
    <div class="form-group mb-3">
        <label for="phone_number" class="form-label fw-bold text-xs">Phone Number</label>
        <input type="text" v-model="form.phone_number" id="phone_number" class="form-control form-control-lg"
            placeholder="Enter your phone number" required />
    </div>
    <div class="form-group mb-3">
        <label for="email_address" class="form-label fw-bold text-xs">Email Address</label>
        <input type="email" v-model="form.email_address" id="email_address" class="form-control form-control-lg"
            placeholder="Enter your email address" required />
    </div>
    <div class="d-flex justify-content-end my-2">
        <button @click="handle_submit" class="btn btn-primary-color rounded-pill text-xs">
            Run Service
        </button>
    </div>
</div>