<div class="container-fluid bg-white vh-100 p-0">
    <!-- Client Logo Component -->
    <ClientLogo />

    <!-- Breadcrumb Component -->
    <BreadCrumb label="Online Bank Statement" />

    <!-- Form Container -->
    <div>
        <div class="d-flex justify-content-center align-items-center min-vh-50">
            <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
                <!-- Error Message Display -->
                <div v-if="error" class="alert alert-danger text-center">
                    {{ error }}
                </div>

                <!-- Loading Spinner -->
                <div v-if="is_loading" class="bank-spinner-container my-5">
                    <div class="bank-spinner"></div>
                    <div class="bank-loader">
                        <p>loading</p>
                        <div class="bank-words">
                            <span class="bank-word">information</span>
                            <span class="bank-word">banks</span>
                            <span class="bank-word">accounts</span>
                            <span class="bank-word">statements</span>
                        </div>
                    </div>
                </div>

                <!-- Bank Statement Form -->
                <div v-if="!is_loading && current_step === 'personal_info'">
                    <h4 class="mb-4 text-center">Enter Required Information</h4>
                    <!-- Only show fields that are not prepopulated -->
                    <div v-if="!is_field_disabled.first_name" class="form-group mt-3">
                        <label for="firstName" class="fw-bold text-xs text-start d-block">
                            First Name <span class="text-danger">*</span>
                        </label>
                        <input
                            v-model="first_name"
                            type="text"
                            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                            id="firstName"
                            placeholder="e.g., Joe"
                            @input="validate_first_name"
                            required
                        />
                        <small v-if="error_messages.first_name" class="form-text text-danger">
                            {{ error_messages.first_name }}
                        </small>
                    </div>

                    <div v-if="!is_field_disabled.last_name" class="form-group mt-3">
                        <label for="lastName" class="fw-bold text-xs text-start d-block">
                            Last Name <span class="text-danger">*</span>
                        </label>
                        <input
                            v-model="last_name"
                            type="text"
                            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                            id="lastName"
                            placeholder="e.g., Doe"
                            @input="validate_last_name"
                            required
                        />
                        <small v-if="error_messages.last_name" class="form-text text-danger">
                            {{ error_messages.last_name }}
                        </small>
                    </div>

                    <div v-if="!is_field_disabled.id_number" class="form-group mt-3">
                        <label for="idNumber" class="fw-bold text-xs text-start d-block">
                            ID Number <span class="text-danger">*</span>
                        </label>
                        <input
                            v-model="id_number"
                            type="text"
                            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                            id="idNumber"
                            placeholder="Enter your ID number"
                            @input="validate_id_number"
                            required
                        />
                        <small v-if="error_messages.id_number" class="form-text text-danger">
                            {{ error_messages.id_number }}
                        </small>
                    </div>

                    <div v-if="!is_field_disabled.email" class="form-group mt-3">
                        <label for="email" class="fw-bold text-xs text-start d-block">
                            Email <span class="text-danger">*</span>
                        </label>
                        <input
                            v-model="email"
                            type="email"
                            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                            id="email"
                            placeholder="Enter your email"
                            @input="validate_email"
                            required
                        />
                        <small v-if="error_messages.email" class="form-text text-danger">
                            {{ error_messages.email }}
                        </small>
                    </div>

                    <!-- Submit Button -->
                    <div class="text-center mb-3 mt-4">
                        <button
                            type="button"
                            class="btn btn-bg-green btn-sm px-4 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
                            @click="submit_bank_statement_request"
                        >
                            Continue
                        </button>
                    </div>
                </div>

                <!-- Bank Selection Step -->
                <div v-if="!is_loading && current_step === 'bank_selection'" class="mt-3">
                    <h4 class="mb-4 text-center">Select Your Bank</h4>
                    <div class="bank-selection-container">
                        <div class="row justify-content-center">
                            <!-- Banks displayed in a more balanced grid -->
                            <div v-for="(bank, index) in banks" :key="index" class="col-6 col-sm-4 col-lg-3 mb-3">
                                <div 
                                    class="card bank-card p-3 text-center h-100" 
                                    :class="{ 'selected': selected_bank === bank.value }"
                                    @click="select_bank(bank.value)"
                                >
                                    <div class="bank-logo-container mb-2">
                                        <img :src="bank.logo" :alt="bank.name" class="img-fluid bank-logo" v-if="bank.logo">
                                        <div class="bank-placeholder" v-else>{{ bank.name.charAt(0) }}</div>
                                    </div>
                                    <h6 class="bank-name mb-0">{{ bank.name }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Bank Selection Submit Button -->
                    <div class="text-center mb-3 mt-4">
                        <button
                            type="button"
                            class="btn btn-bg-green btn-sm px-4 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
                            @click="submit_bank_selection"
                            :disabled="!selected_bank"
                        >
                            Continue with Selected Bank
                        </button>
                    </div>
                </div>

                <!-- External Portal through iframe -->
                <div v-if="!is_loading && current_step === 'external_portal'">
                    <h4 class="mb-4 text-center">Bank Statement Portal</h4>
                    <iframe
                        v-if="bank_account_id"
                        :src="external_link"
                        width="100%"
                        height="600px"
                        frameborder="0"
                    ></iframe>
                    <p v-else class="text-center">Loading your bank statement portal...</p>
                </div>
            </div>
        </div>
    </div>
</div>