<div class="aml-form">
    <div class="form-group">
        <label for="first_name" class="fw-bold text-xs">First Name</label>
        <input type="text" v-model="form.first_name" id="first_name" class="form-control text-xs rounded-small p-3 shadow-sm" required />
    </div>
    <div class="form-group">
        <label for="last_name" class="fw-bold text-xs">Last Name</label>
        <input type="text" v-model="form.last_name" id="last_name" class="form-control text-xs rounded-small p-3 shadow-sm" required />
    </div>
    <div class="form-group">
        <label for="date_of_birth" class="fw-bold text-xs">Date of Birth</label>
        <input type="date" v-model="form.date_of_birth" id="date_of_birth" class="form-control text-xs rounded-small p-3 shadow-sm" required />
    </div>
    <div class="form-group">
        <label for="gender" class="fw-bold text-xs">Gender</label>
        <select v-model="form.gender" id="gender" class="form-control text-xs rounded-small p-3 shadow-sm" required>
            <option value="" hidden>Select gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
        </select>
    </div>
    <div class="form-group">
        <label for="country_code" class="fw-bold text-xs">Country Code</label>
        <input type="text" v-model="form.country_code" id="country_code" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="e.g., ZAF" required />
    </div>
    <div class="d-flex justify-content-end my-2">
        <button @click="handle_submit" class="btn btn-primary-color rounded-pill text-xs">
            Run Service
        </button>
    </div>
</div>