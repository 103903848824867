export default {
    name: 'OverviewComponent',
    emits: ['update:service_name'],
    props: {
        service_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            service_name: '',
            service_description: '',
            service_cost: 0,
            volume_thresholds: [
                {
                    id: '',
                    threshold: 1,
                    discount: 0,
                    type: 'absolute'
                }
            ],
            threshold_current_page: 1,
            threshold_items_per_page: 3,
            preview_current_page: 1,
            preview_items_per_page: 3
        }
    },
    computed: {
        paginated_volume_thresholds() {
            const start = (this.threshold_current_page - 1) * this.threshold_items_per_page;
            const end = start + this.threshold_items_per_page;
            return this.volume_thresholds.slice(start, end);
        },
        threshold_total_pages() {
            return Math.ceil(this.volume_thresholds.length / this.threshold_items_per_page);
        },
        paginated_sorted_thresholds() {
            const sorted_thresholds = this.get_sorted_thresholds();
            const start = (this.preview_current_page - 1) * this.preview_items_per_page;
            const end = start + this.preview_items_per_page;
            return sorted_thresholds.slice(start, end);
        },
        preview_total_pages() {
            return Math.ceil(this.get_sorted_thresholds().length / this.preview_items_per_page);
        }
    },
    mounted() {
        this.get_service_information();
    },
    methods: {
        get_service_information() {
            const TOKEN = localStorage.getItem('token');
            const SERVICE_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/service_management/service_information/?service_id=${this.service_id}`;
            
            $.ajax({
                url: SERVICE_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: (response)=>{
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    if (response.data) {
                        var response_data = response.data;
                        var service = response_data.service;
                        var volumes = response_data.volumes;
                        this.service_name = service.service_name || '';

                        this.$emit('update:service_name', service.service_name);

                        this.service_description = service.service_detail.service_description || '';
                        this.service_cost = service.service_detail.service_cost || '0.00';
                        if (Array.isArray(volumes) & volumes.length === 0) {
                            this.volume_thresholds = [
                                {
                                    id: '',
                                    threshold: 1,
                                    discount: 0,
                                    type: 'absolute'
                                }
                            ];
                        }
                        else{
                            this.volume_thresholds = Array.isArray(volumes) ?
                            volumes.map(function(threshold) {
                                return {
                                    id: threshold.id,
                                    threshold: threshold.volume_threshold,
                                    discount: threshold.discount_value,
                                    type: threshold.pricing_type
                                };
                            }) : [];
                        }
                    }
                },
                error: () => {
                    Swal.close();
                }
            });
        },
        add_threshold() {
            this.volume_thresholds.push({
                id: '',
                threshold: 1,
                discount: 0,
                type: 'absolute'
            });
        },
        remove_threshold(index) {
            this.volume_thresholds.splice(index, 1);
            if (this.paginated_volume_thresholds.length === 0 && this.threshold_current_page > 1) {
                this.threshold_current_page--;
            }
        },
        save_thresholds() {
            const TOKEN = localStorage.getItem('token');
            const API_ENDPOINT = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/volume_prices/recreate_list/`;
            $.ajax({
                url: API_ENDPOINT,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    service_id: this.service_id,
                    volumes: this.volume_thresholds.map((threshold) => ({
                        id: threshold.id,
                        volume_threshold: threshold.threshold,
                        discount_value: threshold.discount,
                        pricing_type: threshold.type
                    }))
                }),
                success: () => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Thresholds Saved',
                        text: 'Volume pricing thresholds have been saved successfully!',
                        timer: 2000,
                        showConfirmButton: false
                    });
                },
                error: () => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'There was a problem saving the thresholds, please try again.'
                    });
                }
            });
        },
        update_service_pricing() {
            const TOKEN = localStorage.getItem('token');
            const API_ENDPOINT = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/service_costs/create/`;
            $.ajax({
                url: API_ENDPOINT,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    service_id: this.service_id,
                    cost: this.service_cost
                }),
                success: () => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Service Pricing Updated',
                        text: 'Service pricing has been updated successfully!',
                        timer: 2000,
                        showConfirmButton: false
                    });
                },
                error: () => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'There was a problem updating the service pricing, please try again.'
                    });
                }
            });
        },
        previous_threshold_page() {
            if (this.threshold_current_page > 1) {
                this.threshold_current_page--;
            }
        },
        next_threshold_page() {
            if (this.threshold_current_page < this.threshold_total_pages) {
                this.threshold_current_page++;
            }
        },
        previous_preview_page() {
            if (this.preview_current_page > 1) {
                this.preview_current_page--;
            }
        },
        next_preview_page() {
            if (this.preview_current_page < this.preview_total_pages) {
                this.preview_current_page++;
            }
        },
        get_first_threshold_volume() {
            const sorted = this.get_sorted_thresholds();
            return sorted.length > 0 ? sorted[0].threshold : null;
        },
        get_next_threshold_volume(index) {
            const sorted = this.get_sorted_thresholds();
            return index < sorted.length - 1 ? sorted[index + 1].threshold : null;
        },
        get_sorted_thresholds() {
            return [...this.volume_thresholds].sort((a, b) => a.threshold - b.threshold);
        },
        get_actual_threshold_index(displayed_index) {
            const start_index = (this.threshold_current_page - 1) * this.threshold_items_per_page;
            return start_index + displayed_index;
        },
        get_actual_preview_index(displayed_index) {
            const start_index = (this.preview_current_page - 1) * this.preview_items_per_page;
            return start_index + displayed_index;
        },
        calculate_discount(threshold) {
            if (threshold.type === 'percentage') {
                return Number(threshold.discount).toFixed(2);
            } else {
                if (this.service_cost === 0) return 0;
                return Number((this.service_cost - threshold.discount) / this.service_cost * 100).toFixed(2);
            }
        },
        calculate_percentage_price(threshold) {
            return Number(this.service_cost * (1 - threshold.discount / 100)).toFixed(2);
        }
    }
}