export default {
    name: 'RefugeeForm',
    data() {
        return {
            form: {
              refugee_number: ''
            },
            error: '',
            json_data: {}
        };
    },
    emits: ['service_status_complete'],
    methods: {
        handle_submit() {
          if (!this.form.refugee_number) {
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Refugee number is required',
                  confirmButtonText: 'OK'
              });
              return;
          }

          const refugee_number_pattern = /^[A-Za-z0-9]+$/;
          if (!refugee_number_pattern.test(this.form.refugee_number)) {
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Refugee number format is invalid',
                  confirmButtonText: 'OK'
              });
              return;
          }

            const TOKEN = localStorage.getItem('token');
            const REFUGEE_VERIFICATION_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/verify_refugee/`;

            this.error = '';

            $.ajax({
                url: REFUGEE_VERIFICATION_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    refugee_number: this.form.refugee_number
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading(),
                    });
                },
                success: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        text: 'Refugee verification ran successfully',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    }).then(() => {
                        this.$emit('service_status_complete');
                    });
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';

                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        }
    }
};