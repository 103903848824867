<transition name="modal">
    <div class="terms-modal" v-if="showModal">
      <div class="terms-modal-content">
        <!-- Header -->
        <div class="terms-header">
          <div class="terms-title">
            <!-- <i class="fas fa-file-alt"></i> -->
            <div class="title-text">
              <h2>Indemnity</h2>
              <p class="last-updated">Last updated February 11, 2018</p>
            </div>
          </div>
        </div>

        <!-- Content -->
        <div class="terms-body">
          <div class="terms-text" @scroll="handleScroll" ref="termsContent">
            <div v-for="(paragraph, index) in formattedTerms" :key="index" class="term-item">
              <i v-if="getIcon(paragraph)" :class="getIcon(paragraph)"></i>
              <p v-html="paragraph"></p>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="terms-footer">
            <button type="button" class="btn-terms-decline" @click="decline">Decline</button>
            <button 
                type="button"
                class="btn-terms-accept" 
                :disabled="!hasReadTerms"
                @click="accept"
            >
                {{ hasReadTerms ? 'Accept' : 'Please read the terms to accept' }}
            </button>
        </div>
      </div>
    </div>
</transition>