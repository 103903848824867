import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";
import IconCard from '@/components/IconCard.vue';

export default {
    name: 'ServiceManagement',
    components: {
        IconCard
    },
    mixins: [LOGOUT],
    data() {
        return {
            service_count: 0,
            client_count: 0,
            workflow_count: 0,
            asset_count: 0,
            applications_completed: 0,
            applications_incompleted: 0,
            customer_count: 0,
            revenue_this_month: 'R 0.00',
            cost_this_month: 'R 0.00',
            total_services_ran: 0,
            services: [],
            clients: [],
            services_search_query: "",
            clients_search_query: "",
            services_current_page: 1,
            clients_current_page: 1,
            items_per_page: 10
        }
    },
    computed: {
        filtered_services() {
            if (!this.services_search_query) {
                return this.services;
            }
            const query = this.services_search_query.toLowerCase();
            return this.services.filter(service => {
                return service.name.toLowerCase().includes(query) ||
                    service.description.toLowerCase().includes(query) ||
                    service.cost.toString().includes(query);
            });
        },
        filtered_clients() {
            if (!this.clients_search_query) {
                return this.clients;
            }
            const query = this.clients_search_query.toLowerCase();
            return this.clients.filter(client => {
                return client.name.toLowerCase().includes(query) ||
                    client.email.toLowerCase().includes(query) ||
                    client.company_name.toLowerCase().includes(query);
            });
        },
        paginated_services() {
            const start = (this.services_current_page - 1) * this.items_per_page;
            const end = start + this.items_per_page;
            return this.filtered_services.slice(start, end);
        },
        paginated_clients() {
            const start = (this.clients_current_page - 1) * this.items_per_page;
            const end = start + this.items_per_page;
            return this.filtered_clients.slice(start, end);
        },
        services_total_pages() {
            return Math.ceil(this.filtered_services.length / this.items_per_page);
        },
        clients_total_pages() {
            return Math.ceil(this.filtered_clients.length / this.items_per_page);
        }
    },
    methods: {
        view_client(client_id) {
            this.$router.push(`client_services_management/${client_id}/`);
        },
        view_service(service_id) {
            this.$router.push(`service_util_management/${service_id}/`);
        },
        next_services_page() {
            if (this.services_current_page < this.services_total_pages) {
                this.services_current_page++;
            }
        },
        previous_services_page() {
            if (this.services_current_page > 1) {
                this.services_current_page--;
            }
        },
        next_clients_page() {
            if (this.clients_current_page < this.clients_total_pages) {
                this.clients_current_page++;
            }
        },
        previous_clients_page() {
            if (this.clients_current_page > 1) {
                this.clients_current_page--;
            }
        },
        // Add methods to fetch services and clients data from your API
        fetch_services() {
            const TOKEN = localStorage.getItem('token');
            const SERVICE_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/service_management/service_list_information/`;
            $.ajax({
                url: SERVICE_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    this.services = Array.isArray(response.data)
                        ? response.data.map(service => ({
                              id: service.id,
                              name: service.service_name,
                              description: service.service_detail.service_description,
                              cost: service.service_detail.service_cost
                          }))
                        : [];
                },
                error: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to fetch service information',
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
        fetch_clients() {
            const TOKEN = localStorage.getItem('token');
            const CLIENT_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/service_management/client_information/`;

            $.ajax({
                url: CLIENT_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    this.clients = response.data || [];
                },
                error: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to fetch client information',
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
        assign_suffix_to_amount(amount){
            if (amount >= 1000000000) {
                return `${Number(amount / 1000000000).toFixed(2)}B`;
            } else if (amount >= 1000000) {
                return `${Number(amount / 1000000).toFixed(2)}M`;
            } else if (amount >= 1000) {
                return `${Number(amount / 1000).toFixed(2)}k`;
            } else {
                return Number(amount).toFixed(2);
            }
        },
        fetch_general_information() {
            const TOKEN = localStorage.getItem('token');
            const GET_GENERAL_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/service_management/general_information/`;

            $.ajax({
                url: GET_GENERAL_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    var general_data = response.data
                    
                    this.service_count = general_data.total_services || 0;
                    this.client_count = general_data.total_clients || 0;
                    this.workflow_count = general_data.total_workflows || 0;
                    this.asset_count = general_data.total_assets || 0;
                    this.applications_completed = general_data.applications_completed || 0;
                    this.applications_incompleted = general_data.applications_in_progress || 0;
                    this.customer_count = general_data.total_customers || 0;
                    this.revenue_this_month = `R ${this.assign_suffix_to_amount(general_data.revenue_this_month || 0)}`;
                    this.cost_this_month = `R ${this.assign_suffix_to_amount(general_data.costs_this_month || 0)}`;
                    this.total_services_ran = general_data.total_services_ran || 0;

                },
                error: () => {
                    Swal.close();
                    let error_message = 'Failed to get service management information';

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        }
    },
    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }

        this.fetch_general_information();
        this.fetch_services();
        this.fetch_clients();
    }
}