import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";
import Swal from 'sweetalert2';


export default {
    name: 'SubWorkflow',
    mixins: [LOGOUT],
    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_workflow_sub_workflows();
        this.get_available_workflows();
    },
    data() {
        return {
            workflow_name: 'Workflow Name',
            workflows: [],
            current_page: 1,
            items_per_page: 10,
            selected_sub_workflow: null,
            edited_sub_workflow: null,
            available_workflows: [],
            new_sub_workflow: {
                service_name: '',
                sub_workflow_type: 'internal',
                external_url: '',
                workflow_id: ''
            }
        }
    },
    computed: {
        workflow_id() {
            return this.$store.getters.get_workflow_id;
        },
        total_pages() {
            return Math.ceil(this.workflows.length / this.items_per_page);
        },
        paginator_sub_workflows() {
            const start = (this.current_page - 1) * this.items_per_page;
            const end = start + this.items_per_page;
            return this.workflows.slice(start, end);
        },
        project_id() {
            return this.$store.getters.get_project_id;
        }
    },
    methods: {
        next() {
            if (this.workflows.length === 0) {
                Swal.fire({
                    title: 'No Sub-Workflows',
                    text: 'You are about to continue without adding any sub-workflows. Do you want to proceed?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, continue',
                    cancelButtonText: 'No, go back'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push({ name: 'confirm_workflow' });
                    }
                });
            } else {
                this.$router.push({ name: 'confirm_workflow' });
            }
        },
        get_workflow_sub_workflows(){
            const token = localStorage.getItem('token');
            const base_url = process.env.API_BASE_URL;

            const get_customer_application_url = `${base_url}/verify_api/service_management_api/get_sub_workflows/`;
            $.ajax({
                method: 'GET',
                url: get_customer_application_url,
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                data: {
                    workflow_id: this.workflow_id
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    })
                },
                success: (response) => {
                    Swal.close();
                    
                    if (Array.isArray(response.data.workflow_name)) {
                        this.workflow_name = response.data.workflow_name[0] || 'Workflow';
                    } else {
                        this.workflow_name = response.data.workflow_name || 'Workflow';
                    }
                    
                    if (response.status === 'success') {
                        var sub_workflows = response.data.sub_workflows;
                        var sub_external_workflows = response.data.sub_external_workflows;
                        
                        // Combine internal and external workflows
                        var combined_workflows = [];

                        // Process internal sub-workflows
                        if (Array.isArray(sub_workflows)) {
                            combined_workflows = combined_workflows.concat(
                                sub_workflows.map(workflow => ({
                                    id: workflow.id,
                                    workflow_id: workflow.workflow_id,
                                    workflow_name: workflow.workflow.workflow_name,
                                    service_name: workflow.service.service_name,
                                    external_link: '',
                                    date_created: workflow.created_at,
                                    is_active: workflow.is_active,
                                    sub_workflow_type: 'internal' // Explicitly mark as internal
                                }))
                            );
                        }

                        // Process external sub-workflows
                        if (Array.isArray(sub_external_workflows)) {
                            combined_workflows = combined_workflows.concat(
                                sub_external_workflows.map(workflow => ({
                                    id: workflow.id,
                                    workflow_id: '',
                                    workflow_name: '',
                                    service_name: workflow.service.service_name,
                                    external_url: workflow.external_link,
                                    date_created: workflow.created_at,
                                    is_active: workflow.is_active,
                                    sub_workflow_type: 'external' // Explicitly mark as external
                                }))
                            );
                        }

                        this.workflows = combined_workflows;
                    } else {
                        this.workflows = [];
                    }
                },
                error: (xhr) => {
                    Swal.close();
                    
                    const error_msg = xhr.responseJSON?.message || 'Failed to retrieve workflow information';
                    Swal.fire({
                        icon: 'error',
                        title: 'Retrieval Failed',
                        text: error_msg,
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        get_available_workflows() {
            const token = localStorage.getItem('token');
            const base_url = process.env.API_BASE_URL;

            const get_workflows_url = `${base_url}/verify_api/workflow_management_api/get_project_workflows/${this.project_id}`;
            
            $.ajax({
                method: 'GET',
                url: get_workflows_url,
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                success: (response) => {
                    if (response.data && Array.isArray(response.data)) {
                        this.available_workflows = response.data.filter(workflow => 
                            workflow.workflow_id !== this.workflow_id
                        );
                    } else {
                        this.available_workflows = [];
                    }
                },
                error: () => {
                    this.available_workflows = [];
                }
            });
        },
        show_add_sub_workflow_modal() {
            // Reset the form data
            this.new_sub_workflow = {
                service_name: '',
                sub_workflow_type: 'internal',
                external_url: '',
                workflow_id: ''
            };
            // Show the modal
            $('#add_sub_workflow_modal').modal('show');
        },
        
        prepend_https() {
            if (this.new_sub_workflow.external_url && !this.new_sub_workflow.external_url.startsWith('http')) {
                this.new_sub_workflow.external_url = 'https://' + this.new_sub_workflow.external_url;
            }
        },
        add_sub_workflow() {
            const token = localStorage.getItem('token');
            const base_url = process.env.API_BASE_URL;

            // Validation
            if (this.new_sub_workflow.sub_workflow_type === 'internal' && !this.new_sub_workflow.workflow_id) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Missing Selection',
                    text: 'Please select a workflow to assign.',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                });
                return;
            }

            if (this.new_sub_workflow.sub_workflow_type === 'external' && !this.new_sub_workflow.external_url) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Missing URL',
                    text: 'Please provide an external URL for the service.',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                });
                return;
            }

            let add_sub_workflow_url;

            if (this.new_sub_workflow.sub_workflow_type === 'external'){
                add_sub_workflow_url = `${base_url}/verify_api/service_management_api/create_service_external_api/`;
            }
            else {
                add_sub_workflow_url = `${base_url}/verify_api/service_management_api/create_service_workflow_api/`;
            }
            
            const request_data = {
                original_workflow_id: this.workflow_id,
                project_id: this.project_id,
                service_name: this.new_sub_workflow.service_name,
                external_link: this.new_sub_workflow.external_url,
                workflow_id: this.new_sub_workflow.workflow_id
            };

            $.ajax({
                method: 'POST',
                url: add_sub_workflow_url,
                contentType: 'application/json',
                data: JSON.stringify(request_data),
                headers: {
                    Authorization: `Token ${token}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Adding workflow router...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    $('#add_sub_workflow_modal').modal('hide');
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Workflow router added successfully',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    });
                    this.get_workflow_sub_workflows();
                },
                error: (xhr) => {
                    const error_msg = xhr.responseJSON?.message || 'Failed to add workflow router';
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_msg,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
        previous_page() {
            if (this.current_page > 1) {
                this.current_page--;
            }
        },
        next_page() {
            if (this.current_page < this.total_pages) {
                this.current_page++;
            }
        },
        format_date(date_string) {
            if (!date_string) return 'N/A';

            // Handle different date formats
            let date;
            if (date_string instanceof Date) {
                date = date_string;
            } else {
                date = new Date(date_string);
            }

            // Check if date is valid
            if (isNaN(date.getTime())) {
                return 'N/A';
            }

            // Format: DD MMM YYYY, HH:MM
            return date.toLocaleString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        },
        view_sub_workflow(sub_workflow) {
            this.selected_sub_workflow = sub_workflow;
            this.edited_sub_workflow = JSON.parse(JSON.stringify(sub_workflow)); // Create a deep copy
        },
        close_sub_workflow_detail() {
            this.selected_sub_workflow = null;
            this.edited_sub_workflow = null;
        },
        save_sub_workflow_changes() {
            if (!this.edited_sub_workflow) {
                return;
            }

            const token = localStorage.getItem('token');
            const base_url = process.env.API_BASE_URL;

            let validation_passed = true;
            let validation_message = '';
            
            // Validation
            if (this.edited_sub_workflow.sub_workflow_type === 'internal' && !this.edited_sub_workflow.workflow_id) {
                validation_passed = false;
                validation_message = 'Please select a workflow to assign.';
            }

            if (this.edited_sub_workflow.sub_workflow_type === 'external' && !this.edited_sub_workflow.external_url) {
                validation_passed = false;
                validation_message = 'Please provide an external URL for the service.';
            }

            if (!validation_passed) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Validation Failed',
                    text: validation_message,
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                });
                return;
            }

            let update_sub_workflow_url;
            if(this.edited_sub_workflow.sub_workflow_type === 'external'){
                update_sub_workflow_url = `${base_url}/verify_api/service_management_api/update_service_external_api/`;
            }
            else {
                update_sub_workflow_url = `${base_url}/verify_api/service_management_api/update_service_workflow_api/`;
            }
            
            const request_data = {
                service_name: this.edited_sub_workflow.service_name,
                external_link: this.edited_sub_workflow.external_url,
                workflow_id: this.edited_sub_workflow.workflow_id,
                is_active: this.edited_sub_workflow.is_active
            };

            if(this.edited_sub_workflow.sub_workflow_type === 'external'){
                request_data.service_external_id =  this.edited_sub_workflow.id
            }
            else{
                request_data.service_workflow_id =  this.edited_sub_workflow.id
            }

            $.ajax({
                method: 'POST',
                url: update_sub_workflow_url,
                contentType: 'application/json',
                data: JSON.stringify(request_data),
                headers: {
                    Authorization: `Token ${token}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Updating workflow router...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Workflow router updated successfully',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    });
                    this.get_workflow_sub_workflows();
                    this.close_sub_workflow_detail();
                },
                error: (xhr) => {
                    const error_msg = xhr.responseJSON?.message || 'Failed to update workflow router';
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_msg,
                        confirmButtonText: 'OK'
                    });
                }
            });
        }
    }
}