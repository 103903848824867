<div class="container-fluid">
    <div class="card rounded-3 mt-4 p-0 shadow-md">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3 ">
                <div class="customer-info p-2">
                    <label class="d-flex align-items-center mb-0">
                        <i class="fas fa-file-alt text-primary fa-xl me-2"></i>
                        <span class="fw-bold mr-1">{{ customer_name }}</span>
                        <span class="mx-1">-</span>
                        <span class="text-muted fst-italic text-xs">Applications</span>
                    </label>
                </div>
                <div class="search-container">
                    <div class="input-group">
                        <span class="input-group-text bg-transparent border-0">
                            <i class="fas fa-search"></i>
                        </span>
                        <input type="text" class="form-control form-control-sm" v-model="search_query"
                            placeholder="Search Applications...">
                    </div>
                </div>
            </div>
            <!-- Table Container -->
            <div class="table-responsive">
                <table class="table table-striped table-hover table-sm text-xs">
                    <thead>
                        <tr>
                            <th class="text-xs fw-bold">No.</th>
                            <th class="text-xs fw-bold">Application</th>
                            <th class="text-xs fw-bold d-none">Asset Onboarded To</th>
                            <th class="text-xs fw-bold">Workflow Onboarded To</th>
                            <th class="text-xs fw-bold">Date Created</th>
                            <th class="text-xs fw-bold">Application Status</th>
                            <th class="text-xs fw-bold text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(application, index) in paginated_applications" :key="index">
                            <td class="py-2 text-center">{{ (current_page - 1) * items_per_page + index + 1 }}</td>
                            <td class="py-2">
                                <span class="d-inline-block text-truncate" style="max-width: 150px;">
                                    {{ application.name || 'Application ' + ((current_page - 1) * items_per_page + index
                                    + 1) }}
                                </span>
                            </td>
                            
                            <td class="py-2 d-none">{{ application.asset_name || 'N/A' }}</td>
                            <td class="py-2">{{ application.workflow_name || 'N/A' }}</td>
                            <td class="py-2">{{ application.date_created || 'N/A' }}</td>
                            <td>
                                <span :class="'badge p-2 text-white' + (application.completed == true ? ' bg-success' : ' bg-dark')">
                                    {{ application.completed == true ? 'Complete' : 'Incomplete' }}
                                </span>
                            </td>
                            <td class="py-2 text-center">
                                <div class="col-sm-12">
                                    <button class="btn btn-bg-green btn-sm rounded-small"
                                        @click="view_customer_data(customer_id, application.application_id)">
                                        <i class="fa fa-eye text-xs p-0 m-0 me-1"></i>
                                        <span class="mb-2 text-s-xs">
                                            View
                                        </span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Empty State -->
            <div v-if="workflow_customer_applications.length === 0" class="text-center py-5 my-4 text-muted">
                <i class="fas fa-folder-open fa-3x mb-3 text-secondary"></i>
                <h6>No applications found</h6>
                <p class="text-xs text-muted">There are no applications available for this customer at this time.</p>
            </div>

            <!-- Pagination -->
            <div class="d-flex justify-content-between align-items-center mt-3"
                v-if="workflow_customer_applications.length > 0">
                <div class="text-xs text-muted">
                    Showing {{ (current_page - 1) * items_per_page + 1 }} to
                    {{ Math.min(current_page * items_per_page, workflow_customer_applications.length) }}
                    of {{ workflow_customer_applications.length }} entries
                </div>

                <div class="d-flex align-items-center">
                    <button class="btn btn-sm btn-outline-secondary me-2" @click="previous_page"
                        :disabled="current_page === 1">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                    <span class="text-xs mx-2">Page {{ current_page }} of {{ total_pages }}</span>
                    <button class="btn btn-sm btn-outline-secondary ms-2" @click="next_page"
                        :disabled="current_page === total_pages">
                        <i class="fas fa-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>