import { validateToken } from '@/utils/auth';

export default {
  name: 'RefugeeVerification',
  data: () => ({
    refugee_number: '',
    error: '',
    json_data: {}
  }),
  methods: {
    format_label(key) {
      if (typeof key !== 'string' || !key.trim()) return '';

      return key
        .replace(/[_-]/g, ' ')
        .replace(/[^a-zA-Z0-9]/g, ' ') // Replace non-alphanumeric characters with a space
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters following a lowercase letter
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Add space between consecutive uppercase letters followed by a lowercase
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .trim() // Trim leading/trailing spaces
        .split(' ') // Split the string into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
        .join(' '); // Join the words back into a single string
    },
    preprocess_data(data, parent_key = '') {
      let result = [];

      // Helper function to format keys for uniqueness and clarity
      let format_key = (key, parent) => (parent ? `${parent}.${key}` : key);

      // Process each key/value pair
      Object.entries(data).forEach(([key, value]) => {
        let current_key = format_key(key, parent_key);

        if (Array.isArray(value)) {
          // For arrays
          result.push({
            type: 'label',
            key: current_key,
            label: key,
            value: null, // No direct value, just a label for the array
          });

          value.forEach((item, index) => {
            if (typeof item === 'object' && item !== null) {
              // Recursively process nested objects or arrays within arrays
              result.push(...this.preprocess_data(item, `${current_key}[${index}]`));
            } else {
              result.push({
                type: 'input',
                key: `${current_key}[${index}]`,
                label: `${key} [${index}]`,
                value: item,
              });
            }
          });
        } else if (typeof value === 'object' && value !== null) {
          result.push({
            type: 'heading',
            key: current_key,
            label: key,
          });
          // Recursively process nested objects
          result.push(...this.preprocess_data(value, current_key));
        } else {
          result.push({
            type: 'input',
            key: current_key,
            label: key,
            value: value,
          });
        }
      });
      return result;
    },
    refugee_verification_api() {
      const TOKEN = localStorage.getItem('token');
      const REFUGEE_VERIFICATION_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/verify_refugee/`;

      this.error = '';

      $.ajax({
        url: REFUGEE_VERIFICATION_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          refugee_number: this.refugee_number
        }),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: (response) => {
          Swal.close();
          Swal.fire({
            icon: 'success',
            title: 'Successful',
            confirmButtonText: 'OK'
          })
          this.json_data = response.result;
          $('#verification_results').modal('show');
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Something went wrong!';

          if (error.responseJSON && error.responseJSON.message) {
            error_message = error.responseJSON.message;
            $('.error-message').text(error_message).removeClass('d-none');
            setTimeout(() => {
              $('.error-message').text('').addClass('d-none');
            }, 3000);
          } else {
            $('.error-message').text(error_message).removeClass('d-none');
            setTimeout(() => {
              $('.error-message').text('').addClass('d-none');
            }, 3000);
          }
        }
      });
    },
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
  },
};