<div class="company-verification-pdf">
    <div class="form-group">
      <label for="reg_number" class="fw-bold text-xs">Registration Number</label>
      <input type="text" v-model="reg_number" id="reg_number" class="form-control text-xs rounded-small p-3 shadow-sm" required @input="validate_reg_number" />
    </div>
    <div class="d-flex justify-content-end my-2">
        <button @click="handle_submit" class="btn btn-primary-color rounded-pill text-xs">
            Run Service
        </button>
    </div>
</div>