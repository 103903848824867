export default {
    name: 'CompanyForm',
    data() {
        return {
            form: {
                reg_number: ''
            }
        };
    },
    emits: ['service_status_complete'],
    methods: {
        validate_reg_number(event) {
            let input_value = event.target.value;
            let cursor_position = event.target.selectionStart;

            if (event.key === 'Backspace' && cursor_position === 0) {
                return;
            }

            let clean_input = input_value.replace(/\D/g, '');
            let unformatted_length = clean_input.length;

            let formatted_input;
            if (clean_input.length > 4 && clean_input.length <= 8) {
                formatted_input = clean_input.replace(/(\d{4})(\d{0,6})/, '$1/$2');
            } else if (clean_input.length > 8) {
                formatted_input = clean_input.replace(/(\d{4})(\d{0,6})(\d{0,2})/, '$1/$2/$3');
            } else {
                formatted_input = clean_input;
            }

            formatted_input = formatted_input.slice(0, 14);
            this.form.reg_number = formatted_input;

            this.$nextTick(() => {
                let new_cursor_position = cursor_position + (formatted_input.length - unformatted_length);
                event.target.selectionStart = new_cursor_position;
                event.target.selectionEnd = new_cursor_position;
            });
        },
        handle_submit() {
            if (this.form.reg_number.length < 14) {
                $('.reg-message').text('Invalid registration number').removeClass('d-none');
                return;
            } else {
                $('.reg-message').addClass('d-none');
            }

            const TOKEN = localStorage.getItem('token');
            const CIPC_API = `${process.env.API_BASE_URL}/verify_api/instant_veify_api/company_information//`;

            this.error = '';

            $.ajax({
                url: CIPC_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    company_registration_number: this.form.reg_number
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading()
                    });
                },
                success: (response) => {
                    Swal.close();
                    let status = response.status;
                    let message = response.error_message;

                    if (status === 'success') {
                        Swal.fire({
                            icon: 'success',
                            title: 'Successful',
                            text: 'Company information retrieved successfully',
                            timer: 3000,
                            timerProgressBar: true,
                            showConfirmButton: false
                        }).then(() => {
                            this.$emit('service_status_complete');
                        });
                        
                    } else if (status === 'error') {
                        try {
                            let parsed_error = JSON.parse(message);
                            message = parsed_error.NoResult?.NotFound || 'An error occurred';
                        } catch (e) {
                            message = 'An error occurred';
                        }
                        Swal.fire({
                            icon: 'error',
                            title: message,
                            confirmButtonText: 'OK'
                        });
                    }
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';

                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        }
    }
};