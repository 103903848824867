import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import SearchSelect from "@/layouts/client/services/service_utilize/components/search_select/SearchSelect.vue";
import { validateToken } from '@/utils/auth';

export default {
  name: 'CustomService',
  mixins: [MAIN_CONTROLLER],
  components: {
    BreadCrumb,
    ClientLogo,
    SearchSelect
  },
  data() {
    return {
      is_form_data_ready: false,
      form_data: [],
      responses: [],
      file_input: null,
      follow_up_file_input: null,
      uploaded_file: null,
      follow_up_responses: [],
      follow_up_questions: [],
      duplicate_fields: [], // Stores dynamic sets of duplicated fields
      validation_errors: {}, // To store validation error messages dynamically
      service_type: "",
      service_name: ""
    };
  },
  computed: {
    service_id() {
      return this.$store.getters.get_service_id;
    },
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },

  watch: {
    service_id: {
      handler() {
        this.fetch_custom_service_question();
      },
      immediate: true, // Trigger immediately on mount
    },
  },
  methods: {
    show_error(question_id, is_followup = false) {
      const error_id = is_followup ? `#error-followup-${question_id}` : `#error-${question_id}`;
      $(error_id).removeClass('d-none');
      setTimeout(() => {
        $(error_id).addClass('d-none');
      }, 3000);
    },
    validate_form() {
      let is_valid = true;
      // Reset all error messages first
      [...this.responses, ...this.follow_up_responses].forEach(field => {
        $(`#error-${field.id}`).addClass('d-none');
        $(`#error-followup-${field.id}`).addClass('d-none');
      });

      // Validate main form responses
      this.responses.forEach((field) => {
        if (field.is_required) {
          if (field.has_multi_selection) {
            // For checkboxes/multi-select
            if (!field.value || !field.value.length) {
              this.show_error(field.id)
              is_valid = false;
            }
          } else if (field.has_file) {
            // For file inputs
            if (!field.value || field.value.type !== 'application/pdf') {
              this.show_error(field.id)
              is_valid = false;
            }
          } else {
            // For text, number, date, radio, select inputs
            if (!field.value || (typeof field.value === 'string' && field.value.trim() === '')) {
              this.show_error(field.id)
              is_valid = false;
            }
          }
        }
      });

      // Validate follow-up responses if they exist
      this.follow_up_responses.forEach(field => {
        if (field.is_required) {
          if (field.has_multi_selection) {
            if (!field.value || (Array.isArray(field.value) && field.value.length === 0)) {
              this.show_error(field.id, true);
              is_valid = false;
            }
          } else if (field.has_checkbox || field.has_selection) {
            if (!field.value || field.value.trim() === '') {
              this.show_error(field.id, true);
              is_valid = false;
            }
          } else if (field.has_file) {
            if (!field.value || field.value.type !== 'application/pdf') {
              this.show_error(field.id, true);
              is_valid = false;
            }
          } else if (!field.value || (typeof field.value === 'string' && field.value.trim() === '')) {
            this.show_error(field.id, true)
            is_valid = false;
          }
        }
      });

      // Validate duplicate fields if any
      if (this.duplicate_fields.length > 0) {
        this.duplicate_fields.forEach((fields, groupIndex) => {
          fields.forEach(field => {
            if (field.is_required) {
              if (!field.value || (typeof field.value === 'string' && field.value.trim() === '')) {
                $(`#error-duplicate-${groupIndex}-${field.id}`).removeClass('d-none');
                setTimeout(() => {
                  $(`#error-duplicate-${groupIndex}-${field.id}`).addClass('d-none');
                }, 3000);
                is_valid = false;
              }
            }
          });
        });
      }

      // If validation fails, scroll to first error
      if (!is_valid) {
        this.$nextTick(() => {
          const firstError = document.querySelector('.text-danger:not(.d-none)');
          if (firstError) {
            firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
      }
      return is_valid;
    },

    handle_multiple_selection(event, question_id, option_value) {
      const is_selected = event.target.checked;
      this.get_follow_up_question(question_id, option_value, is_selected);
    },

    handle_followup_multiple_selection(event, question_id, option_value) {
      const is_checked = event.target.checked;
      const follow_up_response = this.follow_up_responses.find(resp => resp.id === question_id);

      if (follow_up_response) {
        if (!Array.isArray(follow_up_response.value)) {
          follow_up_response.value = [];
        }

        if (is_checked) {
          if (!follow_up_response.value.includes(option_value)) {
            follow_up_response.value.push(option_value);
          }
        } else {
          follow_up_response.value = follow_up_response.value.filter(val => val !== option_value);
        }
        this.update_follow_up_response(question_id, follow_up_response.value);
      }
    },

    get_follow_up_question(question_id, option_value, is_selected = true) {
      // Find the question by ID
      const question = this.responses.find(q => q.id === question_id);
      if (!question || !Array.isArray(question.options)) {
        return null;
      }

      // Find the option by ID
      const option = question.options.find(opt => opt.id === option_value || opt.option === option_value);
      if (!option) {
        return null;
      }

      if (question.has_multi_selection) {
        // For multi-selection questions, we only remove follow-up questions 
        // for options that are deselected
        if (!is_selected) {
          // If option is deselected, remove only the follow-up questions related to this specific option
          this.follow_up_questions = this.follow_up_questions.filter(
            q => !(q.parent_id === question_id && q.parent_option === option.id)
          );
          this.follow_up_responses = this.follow_up_responses.filter(
            resp => !(resp.parent_id === question_id && resp.parent_option === option.id)
          );
          return null;
        }
      } else {
        // Remove only the follow-up questions related to this specific question
        this.follow_up_questions = this.follow_up_questions.filter(
          q => q.parent_id !== question_id
        );
        this.follow_up_responses = this.follow_up_responses.filter(
          resp => resp.parent_id !== question_id
        );
      }

      // Check for follow-up questions in the selected option
      if (option.follow_up_question && Array.isArray(option.follow_up_question)) {
        // Process all follow-up questions
        const new_follow_ups = option.follow_up_question.map(follow_up => ({
          parent_id: question_id,
          parent_option: option.id,
          ...follow_up,
        }));

        if (new_follow_ups.length > 0) {
          // Add new follow-up questions while preserving existing ones for other questions
          this.follow_up_questions = [...this.follow_up_questions, ...new_follow_ups];
          // Add all follow-up questions to responses
          this.follow_up_responses.push(...new_follow_ups.map(follow_up => ({
            ...follow_up,
            value: follow_up.has_multi_selection ? [] : '',
            error_message: '',
          })));
        }
      }
    },

    update_follow_up_response(question_id, value) {
      const follow_up = this.follow_up_responses.find(resp => resp.id === question_id);
      if (follow_up) {
        follow_up.value = value;
      }
    },
    add_more_fields() {
      const new_fields = this.responses.map(field => ({
        ...field,
        value: "", // Reset value for the duplicate fields
      }));
      this.duplicate_fields.push(new_fields);
    },
    remove_fields(index) {
      this.duplicate_fields.splice(index, 1);
    },
    fetch_custom_service_question() {
      const TOKEN = localStorage.getItem('token');
      const GET_QUESTIONS_API = `${process.env.API_BASE_URL}/verify_api/form_management_api/get_questions_for_service_api/${this.service_id}`;

      $.ajax({
        url: GET_QUESTIONS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Getting selected service...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          // Assign questions and service type from the response
          this.service_name = response.service_name;
          this.form_data = response.questions;
          this.service_type = response.service_type; // Add a new data property for service type
          // Map responses for questions
          this.responses = this.form_data.map((field) => ({
            ...field,
            value: field.has_multi_selection ? [] : '', // Initialize with empty value
            error_message: '',
          }));

          // Clear duplicate fields upon new fetch
          this.duplicate_fields = [];
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error',
          });
          Swal.close();
        },
      });
    },
    emit_responses() {
      this.$emit('update-responses', this.form_data);
    },
    handle_file_upload(event, question_id, is_follow_up) {
      const file = event.target.files[0];
      if (!file) return;

      if (is_follow_up) {
        const follow_up = this.follow_up_responses.find(resp => resp.id === question_id);
        if (follow_up) {
          follow_up.value = file;
        }
      } else {
        const field = this.responses.find(resp => resp.id === question_id);
        if (field) {
          field.value = file;
        }
        else {
          this.dynamic_fields.value = file;
        }
      }

      this.emit_responses();
    },
    on_file_change(event, question_id, is_follow_up) {
      const FILE = event.target.files[0]; // Get the selected file
      const MAX_SIZE = 5 * 1024 * 1024; // 5 MB

      if (!FILE) {
        return;
      }

      // Check for file size
      if (FILE.type !== 'application/pdf') {
        event.target.value = null; // Clear the file input
        Swal.fire({
          text: 'Please upload a PDF file.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }

      // Check for file size
      if (FILE.size > MAX_SIZE) {
        event.target.value = null; // Clear the file input
        Swal.fire({
          text: 'File is too large.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
      this.uploaded_file = FILE;
      this.handle_file_upload(event, question_id, is_follow_up);
    },
    submit_form() {
      if (!this.validate_form()) {
        return;
      }
      const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
      const TOKEN = localStorage.getItem('token');
      const SAVE_RESPONSE_API = `${process.env.API_BASE_URL}/verify_api/system_management_api/save_response_api/`;

      // Helper function to process responses including follow-ups
      const process_responses = () => {
        let processed_responses = [];

        // Process main responses first
        this.responses.forEach(field => {
          if (field.has_file && field.value instanceof File) {
            processed_responses.push({
              service_id: this.service_id,
              question_id: field.id,
              value: field.value.name,
              uploaded_file: field.value
            });
          }
          else {
            // Add main question response
            processed_responses.push({
              service_id: this.service_id,
              question_id: field.id,
              value: field.value,
            });
          }

          // Find and add any follow-up responses for this question
          const follow_ups = this.follow_up_responses.filter(
            follow_up => follow_up.parent_id === field.id
          );

          if (follow_ups.length > 0) {
            follow_ups.forEach(follow_up => {

              if (follow_up.has_file && follow_up.value instanceof File) {
                processed_responses.push({
                  service_id: this.service_id,
                  question_id: follow_up.id,
                  value: follow_up.value.name,
                  uploaded_file: follow_up.value
                });
              } else {
                processed_responses.push({
                  service_id: this.service_id,
                  question_id: follow_up.id,
                  value: follow_up.value,
                });
              }
            });
          }
        });

        // Process duplicate fields if any
        this.duplicate_fields.forEach((fields, index) => {
          fields.forEach(field => {
            if (field.has_file && field.value instanceof File) {
              processed_responses.push({
                service_id: field.service_id,
                question_id: field.id,
                value: field.value.name,
                uploaded_file: field.value,
                is_duplicate: true,
                duplicate_index: index
              });
            } else {
              processed_responses.push({
                service_id: this.service_id,
                question_id: field.id,
                value: field.value,
                is_duplicate: true,
                duplicate_index: index
              });
            }
          });
        });
        return processed_responses;
      };

      const all_responses = process_responses();

      // Check if any response includes a file
      const has_file = all_responses.some(response => {
        const field = [...this.responses, ...this.follow_up_responses].find(
          f => f.id === response.question_id
        );
        return field && field.has_file;
      });

      let data_to_send;
      if (has_file) {
        // If there's a file, create a FormData instance
        const form_data = new FormData();
        form_data.append('client_admin_id', GET_CLIENT_ID);
        form_data.append('workflow_id', this.workflow_id);

        // Append responses, handling files appropriately
        all_responses.forEach((response, index) => {
          form_data.append(`responses[${index}][question_id]`, response.question_id);
          form_data.append(`responses[${index}][value]`, response.value || '');

          if (response.uploaded_file) {
            form_data.append(`responses[${index}][uploaded_file]`, response.uploaded_file);
          }
          if (response.is_duplicate) {
            form_data.append(`responses[${index}][is_duplicate]`, true);
            form_data.append(`responses[${index}][duplicate_index]`, response.duplicate_index);
          }
          if (response.service_id) {
            form_data.append(`responses[${index}][service_id]`, response.service_id);
          }
        });

        data_to_send = form_data;
      } else {
        // If no files, send as JSON
        data_to_send = JSON.stringify({
          client_id: GET_CLIENT_ID,
          workflow_id: this.workflow_id,
          responses: all_responses
        });
      }

      // Determine content type based on the presence of files
      const content_type = has_file ? false : 'application/json';

      $.ajax({
        url: SAVE_RESPONSE_API,
        method: 'POST',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: data_to_send,
        processData: !has_file,
        contentType: content_type,
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          this.fetch_current_service();
        },
        error: () => {
          Swal.close();
        }
      });
    },
  },

  mounted() {
    // Simulating API call to get form data
    if (!validateToken(this.$router)) {
      return;
    }
    setTimeout(() => {
      this.is_form_data_ready = true;
    }, 1000);
    this.fetch_custom_service_question();
    this.file_input = this.$refs.file_input;
    this.follow_up_file_input = this.$refs.follow_up_file_input;
  },
};
