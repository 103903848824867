<div class="container-fluid">
  <div class="card rounded-3 mt-4 p-0">
  <div class="card-header bg-white border-0">
    Users
    <button type="button" class="btn  btn-dark btn-sm text-xs fw-bold rounded-small shadow float-end" data-bs-toggle="modal" data-bs-target="#addUserModal">
      <i class="fas fa-plus"></i> Add Users
    </button>
  </div>
  <div class="container mt-3">
    <ul class="nav nav-tabs mb-4 bg-white d-flex justify-content-between" id="myTab" role="tablist">
      <li class="nav-item flex-fill me-2" role="presentation">
        <button class="nav-link w-100 fw-bold secondary-text-dark text-xs border-0" :class="{ active: currentTab === 'Admins' }"
                @click="switchTab('Admins')">
          Admins
        </button>
      </li>
      <li class="nav-item flex-fill me-2" role="presentation">
        <button class="nav-link w-100 fw-bold secondary-text-dark text-xs border-0" :class="{ active: currentTab === 'Clients' }"
                @click="switchTab('Clients')">
          Clients
        </button>
      </li>
      <li class="nav-item flex-fill me-2" role="presentation">
        <button class="nav-link w-100 fw-bold secondary-text-dark text-xs border-0" :class="{ active: currentTab === 'ClientAdmins' }"
                @click="switchTab('ClientAdmins')">
          Client Admins
        </button>
      </li>
      <li class="nav-item flex-fill" role="presentation">
        <button class="nav-link w-100 fw-bold secondary-text-dark text-xs border-0" :class="{ active: currentTab === 'Customers' }"
                @click="switchTab('Customers')">
          Customers
        </button>
      </li>
    </ul>
  
    <div class="mb-3">
     
    </div>
  
    <div class="tab-content">
      <div v-if="currentTab === 'Admins'" class="tab-pane fade show active">
        <table  id="usersTable" class="table table-clear">
          <thead class="bg-dark text-white">
            <tr>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
              <th class="small text-xxs">{{ currentTab.slice(0, -1) }}</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Phone Number</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Date Joined</th>
              <th class="small text-xxs">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredData" :key="item.id" class="text-xs">
              <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
              <td  class="text-xxs text-start">
                {{ item.full_name }}
                <p class="text-xxs fw-bold">{{ item.email }}</p>
              </td>
              <td class="text-xxs text-start">{{ item.phone_number }}</td>
              <td class="text-xxs text-start">{{ item.date_joined.split('T')[0] }}</td>
              <td>
                <button class="btn bg-green btn-sm text-white text-xxs fw-bold rounded-small shadow" @click="view_admin(item.id)">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div v-if="currentTab === 'Clients'" class="tab-pane fade show active">
        <table id="usersTable" class="table table-clear">
          <thead class="bg-dark text-white">
            <tr>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
              <th class="small text-xxs text-uppercase">{{ currentTab.slice(0, -1) }}</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Phone Number</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Date Joined</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredData" :key="item.id" class="text-xs">
              <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
              <td>
                {{ item.full_name }}
                <p class="text-xxs fw-bold text-start">{{ item.email }}</p>
              </td>
              <td class="text-xxs text-start">{{ item.phone_number }}</td>
              <td class="text-xxs text-start">{{ item.date_joined.split('T')[0] }}</td>
              <td>
                <button class="btn bg-green btn-sm text-white text-xxs fw-bold rounded-small shadow" @click="view_client(item.id)">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div v-if="currentTab === 'ClientAdmins'" class="tab-pane fade show active">
        <table id="usersTable" class="table table-clear">
          <thead class="bg-dark text-white">
            <tr>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
              <th class="small text-xxs text-uppercase">{{ currentTab.slice(0, -1) }}</th>
              <th class="text-uppercase text-xxs">Phone Number</th>
              <th class="text-uppercase text-xxs">Date Joined</th>
              <th class="small text-xxs text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredData" :key="item.id" class="text-xs">
              <td class="text-xxs fw-bold text-start">{{ index + 1 }}</td>
              <td>
                {{ item.full_name }}
                <p class="text-xxs fw-bold text-start">{{ item.email }}</p>
              </td>
              <td class="text-xxs text-start">{{ item.phone_number }}</td>
              <td class="text-xxs text-start">{{ item.date_joined.split('T')[0] }}</td>
              <td>
                <button class="btn bg-green btn-sm text-white text-xs fw-bold rounded-small shadow" @click="view_client_admin(item.id)">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div v-if="currentTab === 'Customers'" class="tab-pane fade show active">
        <table id="usersTable" class="table table-clear">
          <thead class="bg-dark text-white">
            <tr>
              <th class="text-xxs text-uppercase">ID</th>
              <th class="text-xxs text-uppercase">{{ currentTab.slice(0, -1) }}</th>
              <th class="text-xxs text-uppercase">Phone Number</th>
              <th class="text-xxs text-uppercase">Date Joined</th>
              <th class="text-xxs text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredData" :key="item.id" class="text-xs">
              <td class="text-xxs text-start">{{ index + 1 }}</td>
              <td>
                {{ item.full_name }}
                <p class="text-xxs fw-bold text-start">{{ item.email }}</p>
              </td>
              <td class="text-start">{{ item.phone_number }}</td>
              <td class="text-xxs text-start">{{ item.date_joined.split('T')[0] }}</td>
              <td>
                <button class="btn bg-green btn-sm text-white text-xxs fw-bold rounded-small" @click="view_customer(item.id)">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>
  
  <!-- Add User Modal -->
  <div class="modal fade" id="addUserModal" tabindex="-1" aria-labelledby="addUserModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addUserModalLabel"><strong>Add User</strong></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="add_user">
            <div class="row mb-3">
              <div class="col">
                <label class="form-label fw-bold"><small>First name</small></label>
                <input v-model="first_name" type="text" class="form-control text-xs" placeholder="First name" name="first_name"
                  @input="validate_name('first_name')" required>
                <div class="first-name-message d-none">First name is required</div>
              </div>
              <div class="col">
                <label class="form-label fw-bold"><small>Last name</small></label>
                <input v-model="last_name" type="text" class="form-control text-xs" placeholder="Last name" name="last_name"
                  @input="validate_name('last_name')" required>
                <div class="last-name-message d-none">Last name is required</div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label fw-bold"><small>Email</small></label>
              <input v-model="email" type="email" class="form-control text-xs" name="email" placeholder="Email" required>
              <div class="email-message d-none">Email is required</div>
            </div>
            <div class="mb-3">
              <label class="form-label fw-bold"><small>Phone number</small></label>
              <input v-model="phone_number" type="tel" class="form-control text-xs" placeholder="Enter phone number" name="phone_number" maxlength="10"
                @input="validate_phone_number" required>
            </div>
            <div class="mb-3">
              <label class="form-label fw-bold"><small>User Type</small></label>
              <select v-model="role" class="form-select text-xs" required>
                <option value="" disabled hidden>Select user Type</option>
                <option value="client">Client</option>
                <option value="systemadmin">Admin</option>
              </select>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn bg-green btn-sm text-xs fw-bold rounded-pill">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
