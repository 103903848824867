import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";
import AddressForm from './components/address_form/AddressForm.vue';
import AmlForm from './components/aml_form/AmlForm.vue';
import RefugeeForm from './components/refugee_form/RefugeeForm.vue';
import QualificationForm from './components/qualification_form/QualificationForm.vue';
import CompanyForm from './components/company_form/CompanyForm.vue';
import AFISForm from './components/afis_form/AFISForm.vue';
import IDForm from './components/id_form/IDForm.vue';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export default {
    name: 'ServiceUtilize',
    mixins: [LOGOUT],
    components: {
        AddressForm,
        AmlForm,
        RefugeeForm,
        QualificationForm,
        CompanyForm,
        AFISForm,
        IDForm
    },
    data() {
        return {
            active_tab: 'run_service',
            results: [],
            logs: [],
            aml_data: {},
            search_query: '',
            current_page: 1,
            items_per_page: 10,
            service_name: '',
            service_description: '',
            no_of_attempts: 0,
            no_successful: 0,
            success_rate: 0,
            cost_of_service: 0,
            service_map: {
                anti_money_laundering: AmlForm,
                refugee_verification: RefugeeForm,
                company_verification_pdf: CompanyForm,
                qualification_check: QualificationForm,
                afis_criminal_check: AFISForm,
                address_match: AddressForm,
                rsa_id_verification: IDForm,
            }
        };
    },
    computed: {
        filtered_logs() {
            let filtered = this.logs.filter(log => {
                return log.status.toLowerCase().includes(this.search_query.toLowerCase()) ||
                    log.date.includes(this.search_query) || log.no.toString().includes(this.search_query);
            });
            return filtered.slice((this.current_page - 1) * this.items_per_page, this.current_page * this.items_per_page);
        },
        total_pages() {
            return Math.ceil(this.logs.length / this.items_per_page);
        }
    },
    methods: {
        aml_results(result) {
            const aml_result = result?.response?.Fields?.Applicants_IO?.Applicant?.[0]?.Attributes?.DSWatchlistVerification;
            if (!aml_result) return null;

            return {
                NmResults: aml_result?.NmResults || [],
                PepResults: aml_result?.PepResults || [],
                WlsResults: aml_result?.WlsResults || []
            };
        },
        format_date(date) {
            return new Date(date).toLocaleString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            }).replace(',', '');
        },
        service_status_complete() {
            this.get_service_information();
        },
        get_result(results) {
            if (!this.service_name?.toLowerCase().includes('anti money laundering')) {
                let history = [];
                results.forEach(element => {
                    let response = element.response;
                    if (!response) {
                        return;
                    }
                    try {
                        response = this.preprocess_data(response);
                    } catch (error) {
                        return;
                    }

                    history.push({
                        date: element.date_created,
                        data: response
                    });
                });
                return history;
            }
        },
        preprocess_data(data, parent_key = '') {
            let result = [];

            let format_key = (key, parent) => (parent ? `${parent}.${key}` : key);

            Object.entries(data).forEach(([key, value]) => {
                let current_key = format_key(key, parent_key);

                // Special handling for DisplayText
                if (key === 'DisplayText' && typeof value === 'string' && value.trim() !== '') {
                    result.push({
                        type: 'heading',
                        key: current_key,
                        label: value
                    });
                    return; // Skip further processing for DisplayText
                }

                if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        if (typeof item === 'object' && item !== null) {
                            result.push(...this.preprocess_data(item, `${current_key}[${index}]`));
                        } else {
                            const processed_value = (item === null || item === undefined || item === '') ? 'N/A' : item;
                            result.push({
                                type: 'input',
                                key: `${current_key}[${index}]`,
                                label: `${key} [${index}]`,
                                value: processed_value,
                            });
                        }
                    });
                } else if (typeof value === 'object' && value !== null) {
                    result.push({
                        type: 'heading',
                        key: current_key,
                        label: key,
                    });
                    result.push(...this.preprocess_data(value, current_key));
                } else if (value !== null && value !== '') {
                    result.push({
                        type: 'input',
                        key: current_key,
                        label: key,
                        value: value,
                    });
                }
            });
            return result;
        },
        format_label(key) {
            if (typeof key !== 'string' || !key.trim()) return '';

            return key
                .replace(/[_-]/g, ' ')
                .replace(/[^a-zA-Z0-9]/g, ' ') // Replace non-alphanumeric characters with a space
                .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters following a lowercase letter
                .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Add space between consecutive uppercase letters followed by a lowercase
                .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                .trim() // Trim leading/trailing spaces
                .split(' ') // Split the string into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
                .join(' '); // Join the words back into a single string
        },
        set_active_tab(tab) {
            this.active_tab = tab;
        },
        toggle_accordion(id) {
            var element = $('#collapse-' + id);
            if (element.length) {
                var is_collapsed = element.hasClass('show');
                if (is_collapsed) {
                    element.removeClass('show');
                } else {
                    element.addClass('show');

                    // Reinitialize DataTable when accordion opens
                    this.$nextTick(() => {
                        const table = element.find('table.d-none')[0];
                        if (table && !$.fn.DataTable.isDataTable(table)) {
                            $(table).DataTable({
                                responsive: true,
                                dom: 'Bfrtip',
                                buttons: [
                                    {
                                        extend: 'copy',
                                        title: this.service_name
                                    },
                                    {
                                        extend: 'csv',
                                        title: this.service_name
                                    },
                                    {
                                        extend: 'pdf',
                                        title: this.service_name,
                                        customize: function (doc) {
                                            doc.content.splice(0, 1, {
                                                text: table.title,
                                                style: 'header',
                                                alignment: 'center',
                                                margin: [0, 0, 0, 20]
                                            });

                                            // Find facial images in the table
                                            const image_elements = element.find('img.facial-image-result');

                                            if (image_elements.length > 0) {
                                                // Add a section for images
                                                doc.content.push({
                                                    text: 'Image',
                                                    style: 'subheader',
                                                    margin: [0, 20, 0, 8]
                                                });

                                                image_elements.each(function () {
                                                    let img_src = $(this).attr('src');
                                                    let alt_text = $(this).attr('alt') || 'Image';

                                                    if (img_src && img_src.startsWith('data:image')) {
                                                        // Add the image as content
                                                        doc.content.push({
                                                            image: img_src,
                                                            width: 100,
                                                            alignment: 'center'
                                                        });

                                                        // Add caption
                                                        doc.content.push({
                                                            text: alt_text,
                                                            alignment: 'center',
                                                            margin: [0, 5, 0, 15]
                                                        });
                                                    }
                                                });
                                            }
                                        }
                                    }
                                ],
                                order: [[2, 'desc']],
                                searching: false,
                                paging: false,
                                info: false
                            });
                        }

                        // Handle all AML tables together
                        const aml_tables = element.find('.aml-data-table');

                        // Make sure all AML tables are visible for download
                        aml_tables.removeClass('d-none');

                        // Define tables and their section titles
                        const tables = [
                            { selector: '#nm_result', title: 'NM Results' },
                            { selector: '#pep_result', title: 'PEP/PIP Results' },
                            { selector: '#wls_result', title: 'Watchlist Results' }
                        ];

                        // Initialize each table separately with its own title and export buttons
                        tables.forEach(table => {
                            const table_element = element.find(table.selector)[0];
                            if (table_element && !$.fn.DataTable.isDataTable(table_element)) {
                                $(table_element).DataTable({
                                    responsive: true,
                                    dom: 'Bfrtip',
                                    buttons: [
                                        {
                                            extend: 'copy',
                                            title: table.title,
                                            messageTop: table.title
                                        },
                                        {
                                            extend: 'csv',
                                            title: table.title,
                                            messageTop: table.title
                                        },
                                        {
                                            extend: 'pdf',
                                            title: table.title,
                                            messageTop: table.title,
                                            customize: function (doc) {
                                                doc.content.splice(0, 1, {
                                                    text: table.title,
                                                    style: 'header',
                                                    alignment: 'center',
                                                    margin: [0, 0, 0, 12]
                                                });
                                            }
                                        }
                                    ],
                                    searching: false,
                                    paging: false,
                                    info: false
                                });
                            }
                        });

                        // Add a combined download buttons container for all AML data if it doesn't exist yet
                        if (!element.find('#amlExportButtonsContainer').length) {
                            const export_buttons_container = `
                                <div id="amlExportButtonsContainer" class="mt-3 mb-5">
                                    <h6 class="text-start"><strong>Export All AML Data</strong></h6>
                                    <div class="dt-buttons" role="group">
                                        <button class="dt-button buttons-copy buttons-html5" id="copyAllAmlData">Copy All</button>
                                        <button class="dt-button buttons-csv buttons-html5" id="csvAllAmlData">CSV</button>
                                        <button class="dt-button buttons-pdf buttons-html5" id="pdfAllAmlData">PDF</button>
                                    </div>
                                    <br>
                                </div>
                            `;

                            element.find('.aml-card-body').prepend(export_buttons_container);

                            // Function to get table data
                            const getTableData = (table_id, title) => {
                                const table = element.find(table_id);
                                if (table.length === 0) return null;

                                const headers = [];
                                table.find('thead th').each(function () {
                                    headers.push($(this).text().trim());
                                });

                                const rows = [];
                                table.find('tbody tr').each(function () {
                                    const row = [];
                                    $(this).find('td').each(function () {
                                        row.push($(this).text().trim());
                                    });
                                    rows.push(row);
                                });

                                return { title, headers, rows };
                            };

                            // Copy All button
                            $('#copyAllAmlData').on('click', () => {
                                let copyText = "";
                                tables.forEach(table => {
                                    const data = getTableData(table.selector, table.title);
                                    if (data) {
                                        copyText += data.title + "\n";
                                        copyText += data.headers.join("\t") + "\n";

                                        data.rows.forEach(row => {
                                            copyText += row.join("\t") + "\n";
                                        });

                                        copyText += "\n"; // Add space between tables
                                    }
                                });

                                // Copy to clipboard
                                const textarea = document.createElement('textarea');
                                textarea.value = copyText;
                                document.body.appendChild(textarea);
                                textarea.select();
                                document.execCommand('copy');
                                document.body.removeChild(textarea);

                                // Show copy confirmation
                                alert('All AML data copied to clipboard!');
                            });

                            // CSV All button
                            $('#csvAllAmlData').on('click', () => {
                                let csvContent = "data:text/csv;charset=utf-8,";

                                tables.forEach(table => {
                                    const data = getTableData(table.selector, table.title);
                                    if (data) {
                                        csvContent += data.title + "\n";
                                        csvContent += data.headers.join(",") + "\n";

                                        data.rows.forEach(row => {
                                            // Escape commas in the data
                                            const escaped_row = row.map(cell => {
                                                if (cell.includes(',')) {
                                                    return `"${cell}"`;
                                                }
                                                return cell;
                                            });
                                            csvContent += escaped_row.join(",") + "\n";
                                        });

                                        csvContent += "\n"; // Add space between tables
                                    }
                                });

                                // Create download link
                                const encoded_uri = encodeURI(csvContent);
                                const link = document.createElement("a");
                                link.setAttribute("href", encoded_uri);
                                link.setAttribute("download", "AML_Data_Report.csv");
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            });

                            $('#pdfAllAmlData').on('click', () => {
                                const doc = new jsPDF('l', 'mm', 'a4'); // Set to landscape mode

                                // Add Title
                                doc.setFontSize(18);
                                doc.text('AML Data Report', 148, 15, null, null, 'center'); // Adjusted for landscape

                                let yPos = 25; // Initial vertical position

                                tables.forEach(table => {
                                    const table_element = element.find(table.selector);
                                    if (table_element.length > 0 && table_element.find('tbody tr').length > 0) {

                                        // Add Section Title
                                        doc.setFontSize(14);
                                        doc.text(table.title, 10, yPos);
                                        yPos += 5;

                                        // Get Table Headers and Data
                                        const headers = [];
                                        table_element.find('thead th').each(function () {
                                            headers.push($(this).text().trim());
                                        });

                                        const data = [];
                                        table_element.find('tbody tr').each(function () {
                                            const row = [];
                                            $(this).find('td').each(function () {
                                                row.push($(this).text().trim());
                                            });
                                            data.push(row);
                                        });

                                        // Use autoTable with adjusted column widths
                                        autoTable(doc, {
                                            startY: yPos + 5,
                                            head: [headers],
                                            body: data,
                                            theme: 'grid',
                                            styles: { fontSize: 9, cellPadding: 2 },
                                            headStyles: { fillColor: [22, 160, 133], fontSize: 9 }, // Teal header background
                                            columnStyles: {
                                                0: { cellWidth: 'wrap' },
                                                1: { cellWidth: 'wrap' },
                                                2: { cellWidth: 'wrap' },
                                                3: { cellWidth: 'wrap' }
                                            },
                                            margin: { top: 30, left: 10, right: 10 }
                                        });

                                        yPos = doc.lastAutoTable.finalY + 10; // Move to next table position
                                    }
                                });

                                // Save the PDF
                                doc.save('AML_Data_Report.pdf');
                            });
                        }
                    });
                }
            }
        },
        is_accordion_open(id) {
            var element = $('#collapse-' + id);
            return element.hasClass('show');
        },
        previous_page() {
            if (this.current_page > 1) {
                this.current_page--;
            }
        },
        next_page() {
            if (this.current_page < this.total_pages) {
                this.current_page++;
            }
        },
        get_log(results) {
            var logs = [];
            this.logs = results.forEach((element, indexOf) => {
                logs.push({
                    no: indexOf + 1,
                    status: element.api_result_status,
                    date: element.date_created
                });
            });
            logs.sort((a, b) => new Date(b.date) - new Date(a.date));
            return logs;
        },
        get_service_information() {
            const TOKEN = localStorage.getItem('token');
            const service_id = this.$route.params.service_id;
            const GET_SERVICE_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/instant_verify_api/user_service_details_api/`;

            $.ajax({
                url: GET_SERVICE_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: { service_id: service_id },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.redirect) {
                        this.logout();
                        return;
                    }
                    const data = response.data;
                    this.service_name = data.service.service_name;
                    this.service_description = data.service_details.service_description;
                    this.no_of_attempts = data.number_of_requests;
                    this.no_successful = data.number_success;
                    this.success_rate = data.number_of_requests !== 0
                        ? ((data.number_success / data.number_of_requests) * 100).toFixed(2)
                        : "0.00";
                    this.cost_of_service = data.cost.total_cost;
                    this.logs = data.service_log ? this.get_log(data.service_log) : [];
                    if (this.service_name?.toLowerCase().includes('anti money laundering')) {
                        this.aml_data = data.service_history || [];
                    } else {
                        this.results = data.service_history ? this.get_result(data.service_history) : [];
                    }
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Failed to get service information';
                    if (error.responseJSON) {
                        const ERROR_RESPONSE = error.responseJSON;
                        if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                            error_message = ERROR_RESPONSE.error;
                        }
                    }
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error_message,
                        confirmButtonText: 'OK'
                    });
                }
            });
        },
    },
    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }

        this.get_service_information();
    }
};