<template>
<div class="card shadow-md rounded-3 icon-cards"> <!-- added improved-card -->
    <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center p-3 icon-container"> <!-- added icon-container -->
                <i :class="'fa-2x text-primary ' + icon_class"></i>
            </div>
            <div class="text-end text-content"> <!-- added text-content -->
                <h4 class="mb-0">{{ count }}</h4>
                <span class="text-xs subtext text-muted">{{ title }}</span> <!-- updated title styling -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "IconCard",
    props: {
        title: {
            type: String,
            required: true,
        },
        count: {
            type: String,
            required: true,
        },
        icon_class: {
            type: String,
            required: true,
        },
    },
    methods: {
        
    },
};
</script>

<style scoped>
.icon-container {
    margin-right: 15px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.text-content h4 {
    font-weight: bold;
}

.text-content .subtext {
    font-size: 0.85rem;
    margin-top: 5px;
}
</style>
