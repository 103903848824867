export default {
    name: 'search-select',
    emits: ['update:modelValue'],
    props: {
        /**
         * Vue 3 expects update:modelValue for v-model bindings. Thus we need to emit this event.
         */
        modelValue: {
            type: [String, Number, Array],
            default: null
        },
        /**
         * Options to display in the dropdown. Each option should be an object with a `value` and `text` property.
         */
        options: {
            type: Array,
            default: () => []
        },
        /**
         * Toggles between multi-select (true) or single-select (false)
         */
        multi_select: {
            type: Boolean,
            default: false
        },
        /**
         * Placeholder text to display when no option is selected.
         */
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            is_open: false,
            search_query: '',
            selected_options: [], // Used for both multi-select and single-select
            filtered_options: [...this.options]
        };
    },
    computed: {
        /**
         * Returns an array of the full option objects
         * for the currently selected values (multi-select).
         */
        selected_option_objects() {
            return this.options.filter(option =>
                this.selected_options.includes(option.value)
            );
        }
    },
    methods: {
        /**
         * Toggles the dropdown open/closed.
         */
        toggle_dropdown() {
            this.is_open = !this.is_open;
            // Reset filter when opening
            if (this.is_open) {
                this.search_query = '';
                this.filtered_options = [...this.options];
            }
        },
        /**
         * Filters the options based on the search query.
         */
        filter_options() {
            const query = this.search_query.toLowerCase();
            this.filtered_options = this.options.filter(option =>
                String(option.text).toLowerCase().includes(query)
            );
        },
        /**
         * Remove an item from selected lists.
         * Works for both multi and single select.
         */
        remove_option(value) {
            const idx = this.selected_options.indexOf(value);
            if (idx !== -1) {
                this.selected_options.splice(idx, 1);
            }
        },
        /**
         * Handles single-select checkbox selection.
         */
        select_single(value) {
            if (this.multi_select) {
                const idx = this.selected_options.indexOf(value);
                if (idx === -1) {
                    this.selected_options.push(value);
                } else {
                    this.selected_options.splice(idx, 1);
                }
            } else {
                if (this.selected_options.includes(value)) {
                    this.selected_options = [];
                } else {
                    this.selected_options = [value];
                }
            }
            this.$emit('update:modelValue', this.multi_select ? this.selected_options : this.selected_options[0]);
        },
        /**
         * Close the dropdown if clicking outside of the component.
         */
        handle_click_outside(event) {
            if (
                this.$refs.input_container &&
                !this.$refs.input_container.contains(event.target) &&
                this.$refs.dropdown_menu &&
                !this.$refs.dropdown_menu.contains(event.target)
            ) {
                this.is_open = false;
            }
        },
        /**
         * Helper to get display text from an option value (single-select).
         */
        get_option_text(value) {
            const found = this.options.find(option => option.value === value);
            return found ? found.text : '';
        }
    },
    /**
     * Add event listener when component is mounted.
     */
    mounted() {
        document.addEventListener('click', this.handle_click_outside);
    },
    /**
     * Remove event listener when component is destroyed.
     */
    beforeUnmount() {
        document.removeEventListener('click', this.handle_click_outside);
    }
};